import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { ProductType } from "./productSearchSlice";
import { SyncStatus } from "components/atoms/SyncStatus/SyncStatus";

type CollectionProductsData = {
  data: ProductType[];
  totalCount: number;
  shopify_products_sync_status: SyncStatus | null,
  last_shopify_products_synced_at: string | null,
  shopify_products_sync_error: string | null
}

type CollectionProductsApiState = {
  productsData?: CollectionProductsData | null;
  fetchProductsApiState: "idle" | "loading" | "failed";
  fetchProductsApiError: string | null;
};

const initialState: CollectionProductsApiState = {
  productsData: null,
  fetchProductsApiState: "loading",
  fetchProductsApiError: null
};

type ErrorResponse = {
  errors: string;
};

export const fetchCollectionProductsApi = createAsyncThunk(
  "fetchCollectionProductsApi",
  async ({collectionId, rows, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const query = `collection_id=${collectionId}&rows=${rows}`;
    try {
      const response = await axiosInstance.get(`/solomon/collections/collection_products?${query}`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)


export const syncCollectionProductsApi = createAsyncThunk(
  "syncCollectionProductsApi",
  async ({collectionId, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.post(`/solomon/collections/${collectionId}/sync_products`, {}, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const updateCollectionProductsApi = createAsyncThunk(
  "updateCollectionProductsApi",
  async ({operation, collectionId, productIds, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const payload = {
      id: collectionId,
      operation: operation,
      product_ids: productIds
    };
    try {
      const response = await axiosInstance.post(`solomon/collections/add_remove_collection_products`, payload, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

const collectionProductsSlice = createSlice({
  name: 'collectionProducts',
  initialState,
  reducers: {
    updateCollectionProducts: (state, action: PayloadAction<ProductType[]>) => {
      const products = action.payload;
      state.productsData = {
        data: products,
        totalCount: products.length,
        shopify_products_sync_status: null,
        last_shopify_products_synced_at: null,
        shopify_products_sync_error: null
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionProductsApi.pending, (state) => {
        state.fetchProductsApiState = "loading";
        state.fetchProductsApiError = null;
      })
      .addCase(
        fetchCollectionProductsApi.fulfilled,
        (state, action: PayloadAction<CollectionProductsData>) => {
          state.fetchProductsApiState = "idle";
          state.productsData = action.payload;
        }
      )
      .addCase(fetchCollectionProductsApi.rejected, (state, action) => {
        state.fetchProductsApiState = "failed";
        if (action.payload) {
          state.fetchProductsApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.fetchProductsApiError = action.error.message || "Error occured";
        }
      })
  }
});


export const { updateCollectionProducts } = collectionProductsSlice.actions;
export default collectionProductsSlice.reducer;