import React from "react";
import styles from "./CheckboxFilter.module.scss";
import { Box, Checkbox } from "@mui/material";
import { csx } from "../../../helpers/utils";

type PropType = {
  label: JSX.Element | React.ReactNode;
  checked: boolean;
  onChange: (val: boolean) => void;
}

const CheckboxFilter:React.FC<PropType> = ({label, checked, onChange}) => {
  return (
    <Box className={csx(styles.checkboxWrapper, checked ? styles.selected : undefined)}>
      <Checkbox checked={checked} className={styles.checkbox} size="small" onChange={(e) => onChange(e.target.checked)} />
      {label}
    </Box>
  );
}

export default CheckboxFilter;