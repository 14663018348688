import { Box, Button, Modal, Typography } from "@mui/material";
import "./LogoutModal.scss";

type LogoutModalPropType = {
  open: boolean,
  onClose: () => void,
  onLogout: () => void,
}

const LogoutModal = (props: LogoutModalPropType) => {

  const {open, onClose, onLogout} = props;

  return (
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
  >
    <Box className="logout-popup">
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography id="modal-modal-title" sx={{fontWeight: 600, fontSize: 14}}>
          Are you sure you want to logout?
        </Typography>
      </Box>
      <Box sx={{display: 'flex', gap: 2.5, justifyContent: 'flex-end'}} mt={3}>
        <Button 
          variant='outlined' 
          sx={{textTransform: 'none', fontWeight: 600, color: "#000", borderColor: '#7A7A7A', ':hover' : {backgroundColor: "#fff", color: "#000", borderColor: '#7A7A7A'}}}
          onClick={onClose} 
        >
          Close
        </Button>
        <Button 
          variant='contained' 
          sx={{backgroundColor: '#3361FF', textTransform: 'none', fontWeight: 600, ':hover' : {backgroundColor: "#3361FF"}}}
          onClick={onLogout} 
        >
          Logout
        </Button>
      </Box>
    </Box>
  </Modal>
  )
 
}

export default LogoutModal;