import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { csx } from "helpers/utils";
import { ProductVariantFormData, variantInventory } from "slices/productFormSlice";
import styles from "./EditProductVariant.module.scss";
import InputX from "components/atoms/InputX/InputX";

type VariantInventoryEditProps = {
  inventoryProps: variantInventory,
  onInventoryChange: (field: keyof ProductVariantFormData, value: any) => void;
}

const VariantInventoryEdit: React.FC<VariantInventoryEditProps> = ({
  inventoryProps,
  onInventoryChange
}) => {

  return (
    <Box className={csx(styles.formSection, styles.inventoryForm)}>
      <p className={styles.formTitle}>Inventory</p>
      <Box className={styles.formSectionA}>
        <InputX label="SKU ID" value={inventoryProps.skuID}
          onChange={(e) => {
            onInventoryChange("variantInventory", { ...inventoryProps, "skuID": e.target.value })
          }}
          disabled
        />
        <InputX label="Stock on Hand" value={inventoryProps.stockOnHand} type="number" disabled />
        <InputX label="HSN Code" value={inventoryProps.hsnCode} type="number" onChange={(e) => {
          onInventoryChange("variantInventory", { ...inventoryProps, "hsnCode": e.target.value })
        }} />
        <InputX label="VIN" value={inventoryProps.vin} disabled />
      </Box>
      <Box className={styles.formSectionB}>
        <FormGroup>
          <FormControlLabel label="FBV" control={<Checkbox checked={inventoryProps.isFBV}
            onChange={(e) => {
              onInventoryChange("variantInventory", { ...inventoryProps, "isFBV": e.target.checked })
            }} />}
          />
          <FormControlLabel label="Track Quantity" control={<Checkbox checked={inventoryProps.trackQuantity}
            onChange={(e) => onInventoryChange("variantInventory", { ...inventoryProps, "trackQuantity": e.target.checked })} />} />
          <FormControlLabel label="Continue selling when out of stock" control={<Checkbox checked={inventoryProps.sellOnOOS}
            onChange={(e) => onInventoryChange("variantInventory", { ...inventoryProps, "sellOnOOS": e.target.checked })} />} />
        </FormGroup>
      </Box>
    </Box>
  );
}

export default VariantInventoryEdit;