import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { sortRecords } from "../helpers/utils";
import { Api } from "api/axiosInstance";

export type SetPieceMasterDataHash = {
  id: number | string;
  unique_key: string | number;
  name: string | null;
  created_at: string;
}

type SetPieceMasterCatalogueApiResponse = {
  set_pieces: SetPieceMasterDataHash[];
  total_count: number;
}

type SetPieceMasterCatalogueApiState = {
  setPiecesMasterCatalogueData?: SetPieceMasterCatalogueApiResponse | null;
  setPiecesMasterCatalogueApiStatus: "idle" | "loading" | "failed";
  setPiecesMasterCatalogueApiError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof SetPieceMasterDataHash | null;
};

const initialState: SetPieceMasterCatalogueApiState = {
  setPiecesMasterCatalogueData: null,
  setPiecesMasterCatalogueApiStatus: "loading",
  setPiecesMasterCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, sub_url: string, download:boolean = false): string => {
  let url = `${sub_url}${download ? '.csv' : '.json'}?`;

  url += `q[set_and_piece_i_cont]=${requestData.setPiecesMasterCatalogueNameFilter.trim()}&`
  if(requestData.setPiecesMasterCatalogueCreatedAtStartDateFilter){
    url += `q[created_at_gteq]=${requestData.setPiecesMasterCatalogueCreatedAtStartDateFilter}&`;
    url += `q[created_at_lteq]=${requestData.setPiecesMasterCatalogueCreatedAtEndDateFilter}&`
  }
  return url;
}


export const SetPieceMasterCatalogueApi = createAsyncThunk("setPiecesMasterCatalogueApi", async ({...payload}: any, { rejectWithValue }) => {
  const url = formatRequest(payload, '/solomon/masters/get_set_pieces');
  return await Api({url, method: "GET", rejectWithValue});
});


export const createSetPiece = createAsyncThunk("createSetPiece", async ({formData}: any, { rejectWithValue }) => {
  const url = '/solomon/masters/create_set_piece';
  return await Api({url, method: "POST", rejectWithValue, data: formData});
});

const setPiecesMasterCatalogueSlice = createSlice({
  name: 'setPiecesMasterCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof SetPieceMasterDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.setPiecesMasterCatalogueData?.set_pieces && column){
        const sorted = sortRecords(state.setPiecesMasterCatalogueData?.set_pieces, column, direction);
        state.setPiecesMasterCatalogueData.set_pieces = sorted as SetPieceMasterDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SetPieceMasterCatalogueApi.pending, (state) => {
        state.setPiecesMasterCatalogueApiStatus = "loading";
        state.setPiecesMasterCatalogueApiError = null;
      })
      .addCase(
        SetPieceMasterCatalogueApi.fulfilled,
        (state, action: PayloadAction<SetPieceMasterCatalogueApiResponse>) => {
          state.setPiecesMasterCatalogueApiStatus = "idle";
          state.setPiecesMasterCatalogueData = {
            ...action.payload,
            set_pieces: action.payload.set_pieces.map((sp) => ({...sp, unique_key: sp.id}))
          }
        }
      )
      .addCase(SetPieceMasterCatalogueApi.rejected, (state, action) => {
        state.setPiecesMasterCatalogueApiStatus = "failed";
        if (action.payload) {
          state.setPiecesMasterCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.setPiecesMasterCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = setPiecesMasterCatalogueSlice.actions;
export default setPiecesMasterCatalogueSlice.reducer;
