import { Box, Button } from "@mui/material";
import styles from "./CollectionThumbnail.module.scss";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import InputX from "components/atoms/InputX/InputX";
import default_thumbnail from "images/default_thumbnail.png";
import { useRef } from "react";

type CollectionThumbnailPropType = {
  thumbnail?: File|string;
  thumbnailAltTxt?: string;
  onChange: (thumbnail: File | string| undefined, thumbnailAltTxt: string | undefined) => void;
}

const CollectionThumbnail:React.FC<CollectionThumbnailPropType> = (props) => {
  const {thumbnail, thumbnailAltTxt, onChange} = props;
  const imgSrc = thumbnail ? (typeof thumbnail === 'string' ? thumbnail : URL.createObjectURL(thumbnail)) : undefined;
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files?.[0]){
      onChange( e.target.files?.[0], thumbnailAltTxt);
    }
    e.target.value = "";
  }

  const handleUpload = () => {
    uploadRef?.current?.click();
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.heading}>
        <p className={styles.title}>Thumbnail</p>
        {thumbnail && (
          <ButtonX
            size="small"
            variant="text"
            onClick={handleUpload}>Change
          </ButtonX>
        )}
      </Box>
      <Box className={styles.thumbnail}>
        {thumbnail ? (
          <img src={imgSrc} alt="Selected Thumbnail" className={styles.selectedImg} />
        ): (
          <img src={default_thumbnail} alt="Default Thumbnail" className={styles.defaultImg} onClick={handleUpload} />
        )}
        {thumbnail && (
          <Box
            className={styles.removeBtn} 
            onClick={(e) => {
              e.stopPropagation()
              onChange(undefined, thumbnailAltTxt)
            }}
          >
            Remove
          </Box>
        )}
      </Box>
      {!thumbnail && (
        <p className={styles.noImgTxt}>If thumbnail is not added, First product's image will be fetchecd from this collection</p>
      )}
      <InputX
        value={thumbnailAltTxt}
        label={'Image alt text'} 
        onChange={e => onChange(thumbnail, e.target.value)} 
        placeholder="Type"
      />
      <input
        ref={uploadRef}
        type="file" 
        onChange={handleFileSelection} 
        style={{display: 'none'}} 
        accept={"image/png, image/jpeg, image/jpg"}
      />
    </Box>
  );
}

export default CollectionThumbnail;