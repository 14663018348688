import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './MasterCatalogue.module.scss';
import { ProductTypeMasterCatalogueApi, setSort } from "../../slices/productTypeMasterCatalogueSlice";
import Loader from "../../components/atoms/Loader/Loader";
import { useNavigate} from "react-router-dom";

import { 
  setProductTypeMasterCatalogueCategoryFilter,
  setProductTypeMasterCatalogueSubCategoryFilter,
  setProductTypeMasterCatalogueNameFilter,
  setProductTypeMasterCatalogueCreatedAtStartDateFilter,
  setProductTypeMasterCatalogueCreatedAtEndDateFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import { productTypeMasterCatalogueColumns } from "./ProductTypeMasterCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "../../components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "../../components/molecules/SearchByFilter/SearchByFilter";

import { DateRangeType, ROUTES } from "../../constants";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { ProductTypeMasterDataHash } from "slices/productTypeMasterCatalogueSlice";

const searchByOptions = [
  {id: 'value', label: 'Value'},
]

const ProductTypeCatalogue = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // Creating state to store loader status, filter applied and filter open
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  
  // Get the collection data from store.
  const productTypeMasterCatalogue = useAppSelector((state: RootState) => state.productTypeMasterCatalogue);
  const productTypeMasterCatalogueNameFilter = useAppSelector((state: RootState) => state.filters.productTypeMasterCatalogueNameFilter);
  const productTypeMasterCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.productTypeMasterCatalogueCreatedAtStartDateFilter);
  const productTypeMasterCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.productTypeMasterCatalogueCreatedAtEndDateFilter);
  const productTypeMasterCatalogueCategoryFilter = useAppSelector((state: RootState) => state.filters.productTypeMasterCatalogueCategoryFilter);
  const productTypeMasterCatalogueSubCategoryFilter = useAppSelector((state: RootState) => state.filters.productTypeMasterCatalogueSubCategoryFilter);

  const available_categories = (productTypeMasterCatalogue.productTypeMasterCatalogueData?.categories || []).map((c) => ({label: c, value: c}));
  const available_sub_categories = (productTypeMasterCatalogue.productTypeMasterCatalogueData?.sub_categories || []).map((c) => ({label: c, value: c}));;

  const handleSort = (column: keyof ProductTypeMasterDataHash) => {
    const direction = column === productTypeMasterCatalogue.sortedColumn && productTypeMasterCatalogue.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };
  const fetchData = async () => {
    try {
      await dispatch(ProductTypeMasterCatalogueApi({
        page,
        rowsPerPage,
        productTypeMasterCatalogueNameFilter,
        productTypeMasterCatalogueCreatedAtStartDateFilter,
        productTypeMasterCatalogueCreatedAtEndDateFilter,
        productTypeMasterCatalogueCategoryFilter,
        productTypeMasterCatalogueSubCategoryFilter,
      })).unwrap();
    }catch(e){
      console.error(e);
    }finally{
      setFilterApplied(false);
      setLoaderActive(false);
    }
  };

  useEffect(() => {
    if(filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setFilterApplied(true);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!productTypeMasterCatalogueNameFilter
      ? [
        {
          id: "value",
          label: "Value",
          value: productTypeMasterCatalogueNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productTypeMasterCatalogueCategoryFilter
      ? [
        {
          id: "category",
          label: "Category",
          value: productTypeMasterCatalogueCategoryFilter,
          type: "select",
          options: available_categories,
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!productTypeMasterCatalogueSubCategoryFilter
      ? [
        {
          id: "sub_category",
          label: "Sub Category",
          value: productTypeMasterCatalogueSubCategoryFilter,
          type: "select",
          options: available_sub_categories,
        },
      ]
      : []) as AppliedFilterType[]),
  ];

  console.log(appliedSearchFilters);


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'value': {
        dispatch(setProductTypeMasterCatalogueNameFilter(value as string)); break;
      }
      case 'category': {
        dispatch(setProductTypeMasterCatalogueCategoryFilter(value as string)); break;
      }
      case 'sub_category': {
        dispatch(setProductTypeMasterCatalogueSubCategoryFilter(value as string)); break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setProductTypeMasterCatalogueNameFilter(''));
    dispatch(setProductTypeMasterCatalogueCategoryFilter(''));
    dispatch(setProductTypeMasterCatalogueSubCategoryFilter(''));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setProductTypeMasterCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setProductTypeMasterCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Product Type Master">
        <ButtonX
          onClick={() => {
            window.location.href = '/masters/product_types/new';
          }}
        >
          Create Product Type
        </ButtonX>
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SelectFilter
            label={'Category'}
            value={productTypeMasterCatalogueCategoryFilter}
            options={available_categories}
            onChange={(val) => {
              dispatch(setProductTypeMasterCatalogueCategoryFilter(val));
              setFilterApplied(true);
            }}
          />
          <SelectFilter
            label={'Sub Category'}
            value={productTypeMasterCatalogueSubCategoryFilter}
            options={available_sub_categories}
            onChange={(val) => {
              dispatch(setProductTypeMasterCatalogueSubCategoryFilter(val));
              setFilterApplied(true);
            }}
          />
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <DateRangeLabel
            noDateLabel={'Created At'}
            startDate={productTypeMasterCatalogueCreatedAtStartDateFilter}
            endDate={productTypeMasterCatalogueCreatedAtEndDateFilter}
            onClick={() => {setShowDateRangeFilter(true)}}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />


      {!loaderActive && (
        <TableManager<ProductTypeMasterDataHash>
          data={productTypeMasterCatalogue?.productTypeMasterCatalogueData?.product_types || []}
          columns={productTypeMasterCatalogueColumns()}
          sortedColumn={productTypeMasterCatalogue.sortedColumn}
          handleSort={handleSort}
          sortDirection={productTypeMasterCatalogue.sortDirection}
          showPagination
          totalCount={productTypeMasterCatalogue?.productTypeMasterCatalogueData?.results_count || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={productTypeMasterCatalogueCreatedAtStartDateFilter}
        endDate={productTypeMasterCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default ProductTypeCatalogue;
