import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import InputX from "components/atoms/InputX/InputX";
import styles from './CategoryForm.module.scss';
import { RootState, AppDispatch } from '../../store';
import {
  createCategory,
  CategoryFormData,
} from 'slices/categoryFormSlice';
import { resetAuth } from 'slices/authSlice';

const PAGE_TITLE_NEW = "Add New Category";
const PAGE_TITLE_EDIT = "Edit Category"; // TODO: Edit functionality to be handled later

const CategoryForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [fetchError, setFetchError] = useState<string | null>(null);
  const isEditMode = !!id;
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const initialFormData: CategoryFormData = {
    value: ""
  };

  const [formData, setFormData] = useState<CategoryFormData>(initialFormData);

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        await handler(headers);
      }catch(e) {
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
    }
  }

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.reload();
  };

  if (fetchError) {
    return <p>Error: {fetchError}</p>;
  }

  const validateForm = () => {
    const errors: Record<string, string> = {};
    if (!formData.value) {
      errors.value = 'Value is required';
    }
    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const token = JSON.parse(userInfo).token;
        const headers = token
          ? { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
          : undefined;

        if (isEditMode) {
        } else {
          await dispatch(createCategory({ formData: formData, headers }));
          window.location.replace('/masters/categories');
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(resetAuth());
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <>
          <MainHeader label={isEditMode ? PAGE_TITLE_EDIT : PAGE_TITLE_NEW} allowBack={true}>
            <button className={styles.secondaryBtn} onClick={handleDiscard}>Discard</button>
            <button type="submit" className={styles.primaryBtn}>{isEditMode ? 'Update' : 'Save'}</button>
            {/* <ButtonX color='#000' variant='outlined' onClick={handleDiscard}>Discard</ButtonX>
            <ButtonX>{isEditMode ? 'Update' : 'Save'}</ButtonX> */}
          </MainHeader>
          <Box className={styles.formSection}>
            <Box className={styles.halfWidth}>
              <InputX
                wrapperClass={styles.textField}
                label={"Value"}
                placeholder="Add Value"
                required
                value={formData.value}
                error={validationErrors.value}
                onChange={(e) => {
                  validationErrors.value = "";
                  setFormData((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </Box>
          </Box>
        </>
      </form>
    </Box>
  );
};

export default CategoryForm;

