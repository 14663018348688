import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type CategoryMasterDataHash = {
  unique_key: string | number;
  name: string | null;
  created_at: string;
}

type CategoryMasterCatalogueApiResponse = {
  categories: CategoryMasterDataHash[];
}

type CategoryMasterCatalogueApiState = {
  categoryMasterCatalogueData?: CategoryMasterCatalogueApiResponse | null;
  categoryMasterCatalogueApiStatus: "idle" | "loading" | "failed";
  categoryMasterCatalogueApiError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof CategoryMasterDataHash | null;
};

const initialState: CategoryMasterCatalogueApiState = {
  categoryMasterCatalogueData: null,
  categoryMasterCatalogueApiStatus: "loading",
  categoryMasterCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, sub_url: string, download:boolean = false): string => {
  let url = `${sub_url}${download ? '.csv' : '.json'}?`;

  url += `q[category_cont]=${requestData.categoryMasterCatalogueNameFilter}&`
  if(requestData.categoryMasterCatalogueCreatedAtStartDateFilter){
    url += `q[created_at_gteq]=${requestData.categoryMasterCatalogueCreatedAtStartDateFilter}&`;
    url += `q[created_at_lteq]=${requestData.categoryMasterCatalogueCreatedAtEndDateFilter}&`
  }
  return url;
}

export const CategoryMasterCatalogueApi = createAsyncThunk(
  "categoryMasterCatalogueApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, '/solomon/masters/get_categories');
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

const categoryMasterCatalogueSlice = createSlice({
  name: 'categoryMasterCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof CategoryMasterDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.categoryMasterCatalogueData?.categories && column){
        const sorted = sortRecords(state.categoryMasterCatalogueData?.categories, column, direction);
        state.categoryMasterCatalogueData.categories = sorted as CategoryMasterDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CategoryMasterCatalogueApi.pending, (state) => {
        state.categoryMasterCatalogueApiStatus = "loading";
        state.categoryMasterCatalogueApiError = null;
      })
      .addCase(
        CategoryMasterCatalogueApi.fulfilled,
        (state, action: PayloadAction<CategoryMasterCatalogueApiResponse>) => {
          state.categoryMasterCatalogueApiStatus = "idle";
          state.categoryMasterCatalogueData = {
            categories: action.payload.categories
          }
        }
      )
      .addCase(CategoryMasterCatalogueApi.rejected, (state, action) => {
        state.categoryMasterCatalogueApiStatus = "failed";
        if (action.payload) {
          state.categoryMasterCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.categoryMasterCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = categoryMasterCatalogueSlice.actions;
export default categoryMasterCatalogueSlice.reducer;
