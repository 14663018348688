import { Box, ClickAwayListener, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "./SelectX.module.scss";
import { csx } from "helpers/utils";
import { useMemo, useState } from "react";

type SelectXPropType = {
  label?: JSX.Element | React.ReactNode;
  options: { label: string, value: string, renderedLabel?: JSX.Element | React.ReactNode }[];
  value: string | undefined;
  onChange: (value: string) => void;
  className?: string;
  wrapperClass?: string;
  labelClass?: string;
  inputWrapperClass?: string;
  required?: boolean;
  error?: string;
  allowSearch?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const SelectX: React.FC<SelectXPropType> = (props) => {
  const { required, label, options, value, onChange, className, wrapperClass, labelClass, inputWrapperClass, error, allowSearch, placeholder = 'Select', disabled = false} = props;

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const renderLabel = useMemo(() => {
    return options.find((op) => op.value === value)?.label || '';
  }, [options, value]);

  return (
    <Box className={csx(wrapperClass, styles.selectXWrapper)}>
      {!!label && (
        <Box className={csx(labelClass, styles.textLabel)}>
          {label} {required ? <sup>*</sup> : null}
        </Box>
      )}
      <Box className={csx(inputWrapperClass, styles.inputWrapper)}>
        <FormControl fullWidth error={!!error}>
            <Select
              className={csx(className, styles.selectX)}
              fullWidth
              open={showOptions}
              onClick={() => !disabled && setShowOptions(true)}
              error={!!error}
              renderValue={() => !!value ? renderLabel : <p className={styles.placeholder}>{placeholder}</p>}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              native={false}
              disabled={disabled}
            >
              <ClickAwayListener onClickAway={() => setShowOptions(false)}>
                <Box className={styles.optionsWrapper}>
                  {allowSearch && (
                    <Box className={styles.searchBar}>
                      <input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </Box>
                  )}
                  <Box className={styles.options}>
                  {options.map((option) => {
                    if(!option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())) return null;
                    return <MenuItem 
                      key={option.value} 
                      value={option.value} 
                      dense 
                      className={csx(styles.selectOption, value === option.value ? styles.selected : undefined)}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(option.value)
                        setShowOptions(false);
                      }}
                    >
                      {option.renderedLabel || option.label}
                    </MenuItem>
                  })}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Select>
          {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Box>
    </Box>
  )
}

export default SelectX;