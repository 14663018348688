import { useCallback, useEffect, useRef } from "react";
import styles from "./MultiInputX.module.scss";
import { v4 as uuid } from 'uuid';
import { Box } from "@mui/material";
import { CurrentMultiInputItemType } from "./MultiInputX";
import update from "immutability-helper";
import InputX from "components/atoms/InputX/InputX";
import CloseIcon from '@mui/icons-material/Close';
import ButtonX from "components/atoms/ButtonX/ButtonX";
import DragCard from "components/organisms/DragCard/DragCard";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const getRandomKey = () => uuid();

export type InputDraggerPropType = {
  inputs: CurrentMultiInputItemType[];
  onChange: (values: CurrentMultiInputItemType[]) => void;
  type: 'text' | 'number';
  handleSubmit: () => void;
}

const InputDragger:React.FC<InputDraggerPropType> = (props) => {  
  const {inputs, onChange, type, handleSubmit} = props;

  const handleAddItem = () => {
    let updatedValues:CurrentMultiInputItemType[] = [];
    if(type === 'text'){
      updatedValues =  [...inputs, {value: '' , id: getRandomKey()}];
    }else{
      updatedValues =  [...inputs, {value: 0, id: getRandomKey()}]
    }
    onChange(updatedValues);
  }

  const removeItem = (ith: number) => {
    let updatdValues:CurrentMultiInputItemType[] = inputs.filter((_, idx) => idx !== ith);
    onChange(updatdValues);
  }

  const inputUpdate = (ith: number, value: string | number) => {
    let updatedValues:CurrentMultiInputItemType[] = inputs.map((input, idx) => {
      if(ith !== idx) return input;
      return {
        ...input,
        value: type === 'number' ? +value : value.toString(),
      }
    });
    onChange(updatedValues);
  }

  const moveCard = useCallback(
    (dragIndex:number, hoverIndex:number) => {
      const dragCard = inputs[dragIndex];
      onChange(
        update(inputs, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [inputs]
  );

  return (
    <Box className={styles.inputDraggerPopup}>
      {inputs.length > 0 && 
        <Box className={styles.dragCards}>
          {inputs.map((input, ith) => (
            <InputItem
              index={ith}
              moveCard={moveCard}
              input={input}
              onChange={(value) => inputUpdate(ith, value)}
              onDelete={() => removeItem(ith)}
              type={type}
            />
          ))}
        </Box>
      }
      <Box className={styles.actionsBtns}>
        <ButtonX color={"#211913"} variant='outlined' size="small" onClick={() => handleAddItem()}> Add more </ButtonX>
        <ButtonX size="small" onClick={() => handleSubmit()}> Done </ButtonX>
      </Box>
    </Box>
  );
}

export default InputDragger;

type InputItemPropType = {
  input: CurrentMultiInputItemType;
  onDelete: () => void;
  onChange: (value: string|number) => void;
  index: number;
  moveCard: (dragIndex:number, hoverIndex:number) => void;
  type: 'text' | 'number';
}

const InputItem: React.FC<InputItemPropType> = (props) => {
  const { input, onChange, onDelete, index, moveCard, type } = props;
  const draggerRef = useRef<HTMLDivElement>(null);

  return (
    <DragCard
      index={index}
      key={input.id}
      id={input.id}
      moveCard={moveCard}
      cardItemType="INPUT_ITEM_CARD"
      className={styles.dragCardWrapper}
      dragRef={draggerRef}
    >
      <Box className={styles.inputItem}>
        <Box ref={draggerRef}>
          <DragIndicatorIcon fontSize="small" className={styles.dragIcon}/>
        </Box>
        <InputX
          value={input.value}
          type={type}
          onChange={(e) => onChange(e.target.value)}
        />
        <CloseIcon fontSize="small" onClick={onDelete} className={styles.closeIcon} />
      </Box>
    </DragCard>
  );
};