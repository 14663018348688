import { ColumnPropType } from "../../components/organisms/TableManager/TableManager"
import { SubCategoryMasterDataHash } from "../../slices/subCategoryMasterCatalogueSlice";

export const subCategoryMasterCatalogueColumns = ():ColumnPropType<SubCategoryMasterDataHash>[] => [
  {
    label: 'Name',
    accessor: 'name',
    minWidth: 300
  },
  {
    label: 'Category',
    accessor: 'category',
    minWidth: 300
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 250,
    sortable: true,
  }
]
