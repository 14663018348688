import { Box } from "@mui/material";
import styles from "./LoaderX.module.scss";
import LoaderImg from 'images/loader.svg';
import { csx } from "helpers/utils";

type PropType = {
  className?: string;
  show: boolean | Boolean;
}

const LoaderX:React.FC<PropType> = ({className, show}) => {
  if(!show) return null;
  return (
    <Box className={csx(styles.wrapper, className)}>
      <img src={LoaderImg} alt="loader" />
    </Box>
  )
}

export default LoaderX;