import { Autocomplete, Box, TextField, TextFieldProps } from "@mui/material";
import styles from "./AutoCompleteX.module.scss";
import InputX, { InputXProps } from "../InputX/InputX";
import { csx } from "helpers/utils";

type AutoCompleteXProps = InputXProps & {
  inputOptions?: string[];
  autoCompleteClass?: string;
}

const AutoCompleteX:React.FC<AutoCompleteXProps> = (props) => {
  const {inputOptions = [], autoCompleteClass, ...rest } = props;

  return (
    <Autocomplete
      className={csx(styles.autoCompleteX, autoCompleteClass)}
      freeSolo
      fullWidth
      disableClearable
      options={inputOptions}
      renderInput={(params) => (
        <InputX
          {...params}
          {...rest}
        />
      )}
    />
  );
}

export default AutoCompleteX;