import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";
import { AttachmentFile } from "../constants";

export type ImageUploadRequestDataHash = {
  id: string | number;
  unique_key: string | number;
  filenames: string[];
  request_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  updated_by: string;
  total_count: number | null;
  success_count: number | null;
  error_count: number | null;
  processed_response_present: boolean;
}

type ImageUploadRequestData = {
  data: ImageUploadRequestDataHash[];
  total_count: number;
};

type ImageUploadRequestApiState = {
  imageUploadRequestData?: ImageUploadRequestData | null;
  imageUploadRequestStatus: "idle" | "loading" | "failed";
  imageUploadRequestError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ImageUploadRequestDataHash | null;
};

const initialState: ImageUploadRequestApiState = {
  imageUploadRequestData: null,
  imageUploadRequestStatus: "loading",
  imageUploadRequestError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/solomon/image_uploads?`; // need to update
    url += `q[primary_attachment_attachment_blob_filename_cont]=${requestData.imageUploadRequestReportFileNameFilter}&`
    url += `q[secondary_attachment_attachment_blob_filename_cont]=${requestData.imageUploadRequestReportFileNameFilter}&`
    url += `q[status_eq]=${requestData.imageUploadRequestReportStatusFilter}&`
    url += `q[m]=or&`
    url += `page=${requestData.page + 1 }&`;
    url += `per_page=${requestData.rowsPerPage}`;
  return url;
}

export const imageUploadRequestsApi = createAsyncThunk(
  "imageUploadRequestsApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = formatRequest(requestData);
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)


export const uploadBulkProductImages = createAsyncThunk(
  "uploadBulkProductImages",
  async ({headers, payload} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = '/solomon/image_uploads';
      const response = await axiosInstance.post(formattedRequestUrl, payload, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const downloadImageUploadRequestProcessedResponseApi = createAsyncThunk(
  "downloadImageUploadRequestProcessedResponseApi",
  async ({id, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/solomon/image_uploads/${id}/download_processed_response`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const downloadImageUploadRequestAttachedFilesApi = createAsyncThunk(
  "downloadImageUploadRequestAttachedFilesApi",
  async ({id, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/solomon/image_uploads/${id}/attachment_urls`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);


const imageUploadRequestTrackingSlice = createSlice({
  name: 'imageUploadRequestTrackingSlice',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ImageUploadRequestDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if(state.imageUploadRequestData?.data && column){
        const sorted = sortRecords(state.imageUploadRequestData.data, column, direction);
        state.imageUploadRequestData.data = sorted as ImageUploadRequestDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(imageUploadRequestsApi.pending, (state) => {
        state.imageUploadRequestStatus = "loading";
        state.imageUploadRequestError = null;
      })
      .addCase(
        imageUploadRequestsApi.fulfilled,
        (state, action: PayloadAction<ImageUploadRequestData>) => {
          state.imageUploadRequestStatus = "idle";
          state.imageUploadRequestData = action.payload;
        }
      )
      .addCase(imageUploadRequestsApi.rejected, (state, action) => {
        state.imageUploadRequestStatus = "failed";
        if (action.payload) {
          state.imageUploadRequestError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.imageUploadRequestError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = imageUploadRequestTrackingSlice.actions;
export default imageUploadRequestTrackingSlice.reducer;
