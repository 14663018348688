import { ProductTypeMasterDataHash } from "slices/productTypeMasterCatalogueSlice";
import { ColumnPropType } from "../../components/organisms/TableManager/TableManager"

export const productTypeMasterCatalogueColumns = ():ColumnPropType<ProductTypeMasterDataHash>[] => [
  {
    label: 'Name',
    accessor: 'name',
    minWidth: 300
  },
  {
    label: 'Category',
    accessor: 'category',
    minWidth: 300
  },
  {
    label: 'Sub Category',
    accessor: 'sub_category',
    minWidth: 300
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 250,
  }
]
