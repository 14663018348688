import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import styles from "./TableManager.module.scss";
import SortableColumnHeadCell from "../../atoms/SortableColumnHeadCell/SortableColumnHeadCell";
import { ComponentProps, useEffect, useRef, useState } from "react";
import { csx } from "../../../helpers/utils";

type OnTrClickPropType<T> = {
  original: T[];
  row: T;
  id: string | number;
}

type OnTdClickPropType<T> = OnTrClickPropType<T> & {
  accessor: keyof T;
}

type TrPropType<T> = {
  style?: React.CSSProperties;
  className?: string;
  onClick?: ( onClickProps : OnTrClickPropType<T>) => void;
  onHoverStyle?: React.CSSProperties;
}

type TdPropType<T> = Omit<TrPropType<T>, 'onClick'> & {
  onClick?: ( onClickProps : OnTdClickPropType<T>) => void;
}

type SortingColumnPropType<T> = {
  sortedColumn: keyof T | null;
  sortDirection: 'asc' | 'desc';
  handleSort: (accessor: keyof T) => void;
}

type ColumnCellPropType<T> = {
  original: T[];
  row: T;
  id: string | number;
  value: any;
  accessor: keyof T;
}

export type ColumnPropType<T> = {
  label: React.ReactNode;
  cell?: (columnCell: ColumnCellPropType<T>) => React.ReactNode;
  accessor: keyof T;
  sortable?: boolean;
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  align?: 'left' | 'center' | 'right';
}

export type FixedColumnPropType<T> = ColumnPropType<T> & {thresold: number}

type SelectionProps = {
  selected: Set<string|number>;
  updateSelection: (selection: Set<string|number>) => void;
}

type TableManagerPropType<T extends Record<string, any>> = {
  data: T[];
  trProps?: TrPropType<T>;
  tdProps?: TdPropType<T>;
  style?: React.CSSProperties;
  className?: string;
  columns: ColumnPropType<T>[];
  showHeader?: boolean;
  showPagination?: boolean;
  currentPage: number;
  rowPerPage: number;
  totalCount: number;
  onPageChange: (pageNumber: number) => void;
  onRowPerPageChange: (pageSize: number) => void;
  rowsPerPageOptions: number[];
  fixedColumn?: FixedColumnPropType<T>;
  selectionProps?: SelectionProps;
  actionBarContent?: React.ReactNode | JSX.Element;
} & SortingColumnPropType<T>;

const SELECTION_COL_WIDTH = 52;

type CheckBoxCellPropType = {
  selected: boolean;
  indeterminate: boolean;
  onToggle: () => void;
} & ComponentProps<typeof TableCell>;


const CheckBoxCell:React.FC<CheckBoxCellPropType> = (props) => {
  const {selected, indeterminate, onToggle, ...tableCellProps} = props;
  return (
    <TableCell {...tableCellProps}>
      <Checkbox
        size="small"
        className={csx(styles.checkbox, (selected || indeterminate) ? styles.selected : '')}
        checked={selected}
        indeterminate={indeterminate}
        onClick={onToggle}
      />
    </TableCell>
  );
};


const TableManager = <T extends Record<string, any>>(props: TableManagerPropType<T>): JSX.Element => {
  const {
    data,
    trProps,
    tdProps,
    style,
    className,
    columns,
    sortedColumn,
    handleSort,
    sortDirection,
    showHeader = true,
    showPagination = true,
    currentPage,
    rowPerPage,
    totalCount = 0,
    onPageChange,
    onRowPerPageChange,
    rowsPerPageOptions,
    fixedColumn,
    selectionProps,
    actionBarContent
  } = props;

  const {
    style: trStyle,
    className: trClassName,
    onClick: trOnClick,
    onHoverStyle: trOnHoverStyle,
  } = trProps || {};

  const {
    style: tdStyle,
    className: tdClassName,
    onClick: tdOnClick,
    onHoverStyle: tdOnHoverStyle,
  } = tdProps || {};

  const tableRef = useRef<HTMLTableElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [showFixedCol, setShowFixedCol] = useState(false);

  useEffect(() => {
    if(tableContainerRef && tableContainerRef.current && fixedColumn){
      const handleScroll = (e: any) => {
        if(e.target.scrollLeft > fixedColumn.thresold){
          setShowFixedCol(true);
        }else{
          setShowFixedCol(false);
        }
      }
      tableContainerRef.current.addEventListener('scroll' , handleScroll);

      return () => {
        tableContainerRef.current?.removeEventListener('scroll' , handleScroll);
      }
    }
  }, [tableContainerRef, tableContainerRef.current]);

  useEffect(() => {
    scrollToTop();
  }, [currentPage])

  const handleChangePage = (_: unknown, newPage: number) => {
    onPageChange(newPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowPerPageChange(+event.target.value);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      if (tableRef.current) {
          tableRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
      };
    }, 0);
  };

  // table-checkbox
  const isAllSelected = () => {
    const {selected} = selectionProps!;
    return selected.size > 0 && selected.size === data.length;
  }

  const isIndeterminate = () => {
    const {selected} = selectionProps!;
    return selected.size > 0 && selected.size < data.length;
  }

  const toggleTableCheckbox = () => {
    const {updateSelection} = selectionProps!;
    if(isAllSelected()){
      updateSelection(new Set());
    }else{
      updateSelection(new Set([...data.map(i => i.unique_key)]));
    }
  }

  // tr-checkbox
  const isRowSelected = (unique_key: string | number) => {
    const {selected} = selectionProps!;
    return selected.has(unique_key);
  }

  const toggleRowSelection = (unique_key: string | number) => {
    const {updateSelection, selected} = selectionProps!;
    if(isRowSelected(unique_key)){
      updateSelection(new Set([...selected].filter(uk => uk !== unique_key)));
    }else{
      updateSelection(new Set([...selected, unique_key]));
    }
  }

  const tableRows = data;

  const tableContainerClasses = csx(styles.tableContainer, fixedColumn && styles.withFixedColumn, showFixedCol ? styles.showFixedCol : styles.hideFixedCol);

  return (
    <Paper className={csx (className, styles.tableWrapper)} sx={{ ...style}}>
      {actionBarContent ? (
        <Box className={styles.actionBar}>
          {actionBarContent}
        </Box>
      ): null}
      {showPagination ? (
        <TablePagination
          className={styles.pagination}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{overflow: 'unset'}}
        />
      ): null}
      <TableContainer ref={tableContainerRef} className={tableContainerClasses}>
        <Table stickyHeader aria-label="sticky table" ref={tableRef}>
          {showHeader && (
            <TableHead>
              <TableRow>
                {selectionProps && (
                  <CheckBoxCell
                    selected={isAllSelected()}
                    indeterminate={isIndeterminate()}
                    className={styles.fixedCol}
                    onToggle={toggleTableCheckbox}
                  />
                )}
                {[...(fixedColumn ? [fixedColumn] : []), ...columns].map((col, idx) => {
                  const {label, sortable, width, maxWidth, minWidth, accessor, align} = col;
                  const isHiddenRelativeCol = fixedColumn && idx === 0;
                  const isFirstRelativeCol = idx === (fixedColumn ? 1 : 0);
                  const hiddenRelColLeft = !!selectionProps ? SELECTION_COL_WIDTH : 0;
                  return <TableCell
                    key={idx}
                    style={{width, maxWidth, minWidth, textAlign: align,
                      ...(isFirstRelativeCol ? {padding: 16} : {}),
                      ...(isHiddenRelativeCol ? {left: hiddenRelColLeft} : {})
                    }}
                    className={isHiddenRelativeCol ? styles.hiddenRelativeCol : styles.relativeCol}>
                    {sortable ? (
                      <SortableColumnHeadCell<T>
                        accessor={accessor}
                        handleSort={handleSort} 
                        label={label}
                        sortedColumn={sortedColumn}
                        sortDirection={sortDirection}
                      />
                    ):label}
                  </TableCell>
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {tableRows.map((row) => {
              return (
                <TableRow 
                  hover
                  role="checkbox" 
                  tabIndex={-1} 
                  key={row.unique_key} 
                  sx={{...trStyle, ':hover': {...trOnHoverStyle}}} 
                  className={trClassName}
                  onClick={() => {if(trOnClick) trOnClick({original: data, row, id: row.unique_key})}}
                >
                  {selectionProps && (
                    <CheckBoxCell
                      selected={isRowSelected(row.unique_key)}
                      indeterminate={false}
                      className={styles.fixedCol}
                      onToggle={() => toggleRowSelection(row.unique_key)}
                    />
                  )}
                  {[...(fixedColumn ? [fixedColumn] : []), ...columns].map((col, idx) => {
                    const isHiddenRelativeCol = fixedColumn && idx === 0;
                    const isFirstRelativeCol = idx === (fixedColumn ? 1 : 0);
                    const hiddenRelColLeft = !!selectionProps ? SELECTION_COL_WIDTH : 0;
                    const {cell, accessor, width, minWidth, maxWidth, align} = col;
                    const renderCell = cell ? cell({original: data, row, accessor, value: row[accessor], id: row.unique_key}) : row[accessor];
                    return (
                            <TableCell
                              key={idx}
                              className={csx(tdClassName, isHiddenRelativeCol ? styles.hiddenRelativeCol : styles.relativeCol)}
                              sx={{width, minWidth, maxWidth, ...tdStyle,
                                ...(isFirstRelativeCol ? {padding: '8px 16px !important'} : {}), 
                                ':hover' : {...tdOnHoverStyle}, textAlign: align,
                                ...(isHiddenRelativeCol ? {left: hiddenRelColLeft} : {})
                              }}
                              onClick={() => {if(tdOnClick) tdOnClick({original: data, row, id: row.unique_key, accessor: accessor})}}
                            >
                              {renderCell}
                            </TableCell>
                  )})}
                </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TableManager;