import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance, { Api } from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type ProductTypeMasterDataHash = {
  id: number | string;
  unique_key: string | number;
  name: string | null;
  sub_category: string | null;
  category: string | null;
  created_at: string;
}

type ProductTypeMasterCatalogueApiResponse = {
  product_types: ProductTypeMasterDataHash[];
  results_count: number;
  sub_categories: string[];
  categories: string[]
}

type ProductTypeCatalogueApiState = {
  productTypeMasterCatalogueData?: ProductTypeMasterCatalogueApiResponse | null;
  productTypeMasterCatalogueApiStatus: "idle" | "loading" | "failed";
  productTypeMasterCatalogueApiError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ProductTypeMasterDataHash | null;
}

const initialState: ProductTypeCatalogueApiState = {
  productTypeMasterCatalogueData: null,
  productTypeMasterCatalogueApiStatus: "loading",
  productTypeMasterCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, sub_url: string, download:boolean = false): string => {
  let url = `${sub_url}${download ? '.csv' : ''}?`;

  url += `q[master_category_category_i_cont]=${requestData.productTypeMasterCatalogueCategoryFilter.trim()}&`
  url += `q[master_sub_category_sub_category_i_cont]=${requestData.productTypeMasterCatalogueSubCategoryFilter.trim()}&`
  url += `q[product_type_i_cont]=${requestData.productTypeMasterCatalogueNameFilter.trim()}&`
  if(requestData.productTypeMasterCatalogueCreatedAtStartDateFilter){
    url += `q[created_at_gteq]=${requestData.productTypeMasterCatalogueCreatedAtStartDateFilter}&`;
    url += `q[created_at_lteq]=${requestData.productTypeMasterCatalogueCreatedAtEndDateFilter}&`
  }
  if (!download) {
    url += `page=${requestData.page + 1 }&`;
    url += `per_page=${requestData.rowsPerPage}`;
  }
  return url;
}

export const ProductTypeMasterCatalogueApi = createAsyncThunk("productTypeMasterCatalogueApi", async ({...payload}: any, { rejectWithValue }) => {
  const url = formatRequest(payload, '/solomon/masters/get_product_types');
  return await Api({url, method: "GET", rejectWithValue});
});

export const createProductType = createAsyncThunk("createProductType", async ({formData}: any, { rejectWithValue }) => {
  const url = '/solomon/masters/create_product_type';
  return await Api({url, method: "POST", rejectWithValue, data: formData});
});

const productTypeMasterCatalogueSlice = createSlice({
  name: 'productTypeMasterCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ProductTypeMasterDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.productTypeMasterCatalogueData?.product_types && column){
        const sorted = sortRecords(state.productTypeMasterCatalogueData?.product_types, column, direction);
        state.productTypeMasterCatalogueData.product_types = sorted as ProductTypeMasterDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ProductTypeMasterCatalogueApi.pending, (state) => {
        state.productTypeMasterCatalogueApiStatus = "loading";
        state.productTypeMasterCatalogueApiError = null;
      })
      .addCase(
        ProductTypeMasterCatalogueApi.fulfilled,
        (state, action: PayloadAction<ProductTypeMasterCatalogueApiResponse>) => {
          state.productTypeMasterCatalogueApiStatus = "idle";
          state.productTypeMasterCatalogueData = {
            ...action.payload,
            product_types: action.payload.product_types.map((pt) => ({...pt, unique_key: pt.id}))
          }
        }
      )
      .addCase(ProductTypeMasterCatalogueApi.rejected, (state, action) => {
        state.productTypeMasterCatalogueApiStatus = "failed";
        if (action.payload) {
          state.productTypeMasterCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.productTypeMasterCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = productTypeMasterCatalogueSlice.actions;
export default productTypeMasterCatalogueSlice.reducer;
