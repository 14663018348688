import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import InputX from "components/atoms/InputX/InputX";
import SelectX from "components/atoms/SelectX/SelectX";
import Loader from 'components/atoms/Loader/Loader';
import styles from './MasterForm.module.scss';
import { RootState, AppDispatch } from '../../store';
import {
  fetchMetafieldNames,
  createMaster,
  MasterFormData,
} from 'slices/masterFormSlice';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import { useAppSelector } from 'hooks/redux-hooks';
import ButtonX from 'components/atoms/ButtonX/ButtonX';
import { createSetPiece } from 'slices/setPieceMasterCatalogueSlice';

const PAGE_TITLE_NEW = "Add New Set & Piece";
const PAGE_TITLE_EDIT = "Edit Set & Piece";

type FormData = {
  value: string;
}

const SetPieceForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const isEditMode = !!id;
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const metafieldNames = useAppSelector((state) => state.masterForm.metafieldNames) as {name: string, id: string }[];
  const [formData, setFormData] = useState<FormData>({value: ''});

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');

    if (userInfo) {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

        await dispatch(fetchMetafieldNames({ headers })).unwrap();
      } catch (error: any) {
        setFetchError(error.message || 'Failed to fetch data');
      } finally {
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
      setLoaderActive(false);
    }
  };

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        await handler(headers);
      }catch(e) {
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
    }
  }

  useEffect(() => {
    fetchData();
  }, [dispatch, id, isEditMode]);

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.reload();
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};
    if (!formData.value.trim()) {
      errors.value = 'Value is required';
    }
    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoaderActive(true);
    
    try {
      formData.value = formData.value.trim();
      await dispatch(createSetPiece({ formData: formData })).unwrap();
      window.location.replace('/masters/set_pieces');
    }catch(e){
      setLoaderActive(false);
      console.error(e)
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <Loader show={loaderActive} />
        {!loaderActive && (
          <>
            <MainHeader label={isEditMode ? PAGE_TITLE_EDIT : PAGE_TITLE_NEW} allowBack={true}>
              <ButtonX color="#000" variant="outlined" onClick={handleDiscard}>Discard</ButtonX>
              <ButtonX type="submit">{isEditMode ? 'Update' : 'Save'}</ButtonX>
            </MainHeader>
            <Box className={styles.formSection}>
              <Box className={styles.halfWidth}>
                <InputX
                  wrapperClass={styles.textField}
                  label={"Value"}
                  placeholder="Add Value"
                  required
                  value={formData.value}
                  error={validationErrors.value}
                  onChange={(e) => {
                    validationErrors.value = "";
                    setFormData((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};

export default SetPieceForm;

