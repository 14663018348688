import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import BasicDetails from './components/BasicDetails/BasicDetails';
import VariantDetails, { VarintErrors } from './components/VariantDetails/VariantDetails';
import ProductDetailsTabs from './components/ProductDetailsTabs/ProductDetailsTabs';
import Loader from 'components/atoms/Loader/Loader';
import styles from './ProductForm.module.scss';
import { RootState, AppDispatch } from '../../store';
import {
  fetchVendorDetails,
  fetchCategories,
  fetchSubcategories,
  fetchProductTypes,
  fetchSetPieces,
  fetchColors,
  fetchReturnPolicies,
  fetchMaterials,
  fetchTags,
  ProductFormData,
  BasicProductFormData,
  VariantData,
  fetchProductDetails,
  updateProduct,
  createProduct,
  getSpecificDetailOptions,
  SpecificDetail,
} from 'slices/productFormSlice';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import { useAppSelector } from 'hooks/redux-hooks';
import ButtonX from 'components/atoms/ButtonX/ButtonX';
import SyncStatus from 'components/atoms/SyncStatus/SyncStatus';
import { NotificationType, showNotification } from 'slices/notificationSlice';

const PAGE_TITLE_NEW = "Add New Product";
const PAGE_TITLE_EDIT = "Edit Product";

const ProductForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const isEditMode = !!id;
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [variantErrors, setVariantErrors] = useState<VarintErrors[]>([]);

  const { loading, productDetails } = useSelector(
    (state: RootState) => state.productForm
  );

  const initialFormData: ProductFormData = {
    basicDetails: {
      title: "",
      description: "",
      vendor: "",
      setOf: "",
      category: "",
      subCategory: "",
      productType: "",
      tags: [],
      selectedImages: [],
      sizeChartImage: undefined,
      removedImages: [],
      specificDetails: {},
      shopify_sync_status: null,
      shopify_sync_error: null,
      last_shopify_synced_at: null,
      variantType: "",
    },
    variantDetails: {
      variantType: "",
      variantRowData: []
    }
  };

  const categories = useAppSelector((state) => state.productForm.categories) as {name: string, id: string | number}[];
  const subCategories = useAppSelector((state) => state.productForm.subcategories) as {name: string, id: string | number} [];
  const setPieces = useAppSelector((state) => state.productForm.setPieces) as {name: string, id: string | number} [];
  const productTypes = useAppSelector((state) => state.productForm.productTypes) as {name: string, id: string | number, master_category_id: string, master_sub_category_id: string } [];
  const vendorDetails = useAppSelector((state) => state.productForm.vendorDetails) as {name: string, id: string | number} [];;
  const returnPolicies = useAppSelector((state: RootState) => state.productForm.returnPolicies) as {name: string, id: string | number} [];
  
  const [originalFormData, setOriginalFormData] = useState<ProductFormData | null>(null);
  const [formData, setFormData] = useState<ProductFormData>(initialFormData);

  const handleBasicDetailsChange = (field: keyof BasicProductFormData, value: any) => {
    const otherFieldsToUpdate: Record<string, string> = {};
    if(field === 'category'){
      otherFieldsToUpdate['subCategory'] = '';
      otherFieldsToUpdate['productType'] = '';
    }else if(field === 'subCategory'){
      otherFieldsToUpdate['productType'] = '';
    }else if(field === 'productType'){
      const productType = productTypes.find((pt) => pt.id === value)
      if(productType){
        otherFieldsToUpdate['category'] = productType.master_category_id;
        otherFieldsToUpdate['subCategory'] = productType.master_sub_category_id;
      }
    }
    setFormData((prev) => ({
      ...prev,
      basicDetails: {
        ...prev.basicDetails,
        [field]: value,
        ...otherFieldsToUpdate,
      },
    }));
  };

  const handleVariantDetailsChange = (field: keyof VariantData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      variantDetails: {
        ...prev.variantDetails,
        [field]: value,
      }
    }));
  };

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');

    if (userInfo) {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

        // Fetch common details like vendors, categories, etc.
        await dispatch(fetchVendorDetails({})).unwrap();
        await dispatch(fetchProductTypes({})).unwrap();
        await dispatch(fetchCategories({})).unwrap();
        await dispatch(fetchSetPieces({})).unwrap();
        await dispatch(fetchTags({})).unwrap();
        await dispatch(fetchReturnPolicies({})).unwrap();

        // If in edit mode, fetch the existing product details
        if (isEditMode && id) {
          await dispatch(fetchProductDetails({ id, headers })).unwrap();  // Fetch product details for editing
        }
      } catch (error: any) {
        setFetchError(error.message || 'Failed to fetch data');
      } finally {
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
      setLoaderActive(false);
    }
  };

  const fetchSpecificDetailOptions = async (id: string | number) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        const formData = { sub_category_id: id };
        await dispatch(getSpecificDetailOptions({ headers, formData })).unwrap();
      }catch(e) {
        console.error(e);
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
    }else{
      dispatch(resetAuth());
    }
  }

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        await handler(headers);
      }catch(e) {
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
    }
  }

  const fetchUpdatedSubCategories = (category_id: string) => {
    const payload = { category_id: category_id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(fetchSubcategories({ headers, payload })).unwrap();
    handleRequest(handler);
  }

  const fetchUpdatedProductTypes = (sub_category_id: string) => {
    const payload = { sub_category_id: sub_category_id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(fetchProductTypes({ headers, payload })).unwrap();
    handleRequest(handler);
  }

  const fetchSpecificDetails = (product_type_id: string) => {
    const payload = { product_type_id: product_type_id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(getSpecificDetailOptions({ headers, payload })).unwrap();
    handleRequest(handler);
  }

  useEffect(() => {
    fetchData();
  }, [dispatch, id, isEditMode]);

  useEffect(() => {
    if(!!formData?.basicDetails?.category){
      fetchUpdatedSubCategories(formData.basicDetails.category);
    }
  }, [formData?.basicDetails?.category]);

  useEffect(() => {
    if(!!formData?.basicDetails?.productType){
      fetchSpecificDetails(formData.basicDetails.productType);
    }
  }, [formData?.basicDetails?.productType])

  const prepareSpecificDetailsData = (specificData: any) => {
    if(!specificData) return {};
    const res:Record<string, SpecificDetail> = {};
    if(specificData instanceof Array){
      specificData.forEach((data: SpecificDetail) => {
        res[`${data.namespace}.${data.key}`] = data;
      });
    }
    return res;
  }

  // Pre-fill the form when editing
  useEffect(() => {
    if (productDetails && isEditMode) {
      const formData:ProductFormData = {
        basicDetails: {
          title: productDetails.product?.title || "",
          description: productDetails.product?.description || "",
          vendor: productDetails.product?.vendor?.id || "",
          setOf: productDetails.product?.set_of?.id || "",
          category: productDetails.product?.category?.id || "",
          subCategory: productDetails.product?.sub_category?.id || "",
          productType: productDetails.product?.product_types?.id || "",
          tags: productDetails.product?.tags?.map((tag: any) => tag.name) || [],
          selectedImages: productDetails?.product?.primary_images || [],
          sizeChartImage: productDetails?.product?.size_chart_image,
          removedImages: [],
          specificDetails: prepareSpecificDetailsData(productDetails?.product?.specific_details),
          shopify_sync_status:  productDetails?.product?.shopify_sync_status,
          shopify_sync_error: productDetails?.product?.shopify_sync_error,
          last_shopify_synced_at: productDetails?.product?.last_shopify_synced_at,
          variantType: productDetails.product?.variant_type || "",
        },
        variantDetails: {
          variantType: productDetails.product?.variant_type || "",
          variantRowData: productDetails.product?.variants || []
        }
      };
      setFormData(formData);
      setOriginalFormData(formData); // Set original form data for discard
    }
  }, [productDetails, isEditMode]);

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.reload();
  };

  if (fetchError) {
    return <p>Error: {fetchError}</p>;
  }

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData.basicDetails.title) {
      errors.title = 'Title is required';
    }
    if (!formData.basicDetails.vendor) {
      errors.vendor = 'Vendor is required';
    }
    if (!formData.basicDetails.category) {
      errors.category = 'Category is required';
    }
    if (!formData.basicDetails.subCategory) {
      errors.subCategory = 'Sub-Category is required';
    }
    if (!formData.basicDetails.productType) {
      errors.productType = 'Product type is required';
    }
    if (formData.basicDetails.tags.length === 0) {
      errors.tags = 'Tag is required';
    }

    if (!formData.variantDetails.variantType) {
      errors.variantType = 'Variant type is required';
    }

    const allVariantErrors:VarintErrors[] = [];
    let isVariantErrorPresent = false;
    if(formData.variantDetails.variantRowData){
      const validSkus: string[] = [];
      const validNames: string[] = [];
      formData.variantDetails.variantRowData.forEach((vrd) => {
        const variantErrors: VarintErrors = {mrp: '', variantName: '', listingPrice: '', skuId: '', costPrice: '', stock: ''};
        if (!vrd.variantName?.trim()) variantErrors.variantName = 'Empty Variant Name';
        if(vrd.variantName?.trim()){
          if (validNames.includes(vrd.variantName?.trim())) {
            variantErrors.variantName = 'Duplicate Variant Name';
          }else{
            validNames.push(vrd.variantName?.trim());
          }
        }
        if (!vrd.skuId?.trim()) variantErrors.skuId = 'Empty SKU Id';
        if(vrd.skuId?.trim()){
          if (validSkus.includes(vrd.skuId?.trim())) {
            variantErrors.skuId = 'SKU Id already exists';
          }else{
            validSkus.push(vrd.skuId?.trim());
          }
        }
        if(+vrd.costPrice && +vrd.listingPrice && +vrd.listingPrice <= +vrd.costPrice){
          variantErrors.listingPrice = 'Listing price should be > cost Price'
        }
        if(+vrd.listingPrice && +vrd.mrp && +vrd.mrp <= +vrd.listingPrice){
          variantErrors.mrp = 'MRP should be > listing price'
        }
        if(+vrd.costPrice && +vrd.mrp && +vrd.mrp <= +vrd.costPrice){
          variantErrors.mrp = 'MRP should be > cost price'
        }
        allVariantErrors.push(variantErrors);
        if(Object.values(variantErrors).filter((val) => !!val).length) isVariantErrorPresent = true;
      });
    }
    setValidationErrors(errors);
    setVariantErrors(allVariantErrors);
    const isValidFormDetails = Object.keys(errors).length === 0 && !isVariantErrorPresent;
    if(!isValidFormDetails){
      dispatch(showNotification({
        message: 'Invalid Form Inputs',
        type: NotificationType.Error
      }));
    }
    return isValidFormDetails;
  };

  const formatPayload = () => {
    const selectedCategory = categories.filter((category) => category.id === formData.basicDetails.category).map((category) => category.name)
    const selectedSubCategory = subCategories.filter((subCategory) => subCategory.id === formData.basicDetails.subCategory).map((subCategory) => subCategory.name)
    const setPieceValue = setPieces.find((setPiece) => setPiece.id === formData.basicDetails.setOf)?.name || '';
    const vendorValue = vendorDetails.find((vendor) => vendor.id === formData.basicDetails.vendor)?.name || '';
    let productTypeValue = productTypes.find((productType) => productType.id === formData.basicDetails.productType)?.name || '';
    const return_policy_value = returnPolicies.find((rp) => rp.id === formData.basicDetails.specificDetails['custom.return_policy_object'].value)?.name || '';
    
    productTypeValue = productTypeValue.split(" (")[0];
    const {specificDetails, setOf, category, subCategory, shopify_sync_error, shopify_sync_status, last_shopify_synced_at, ...basicDetails} = formData.basicDetails;
    const {variantRowData} = formData.variantDetails;
    return {
      ...formData,
      variantDetails: {
        variantRowData
      },
      basicDetails: {
        ...basicDetails,
        vendor: vendorValue,
        productType: productTypeValue,
        variantType: formData.variantDetails?.variantType || "",
      },
      specificDetails: [
        ...Object.values(specificDetails).filter(({key}) => key !== 'set_pieces' && key !== 'sub_category' && key !== 'main_category' && key != 'return_policy_object' && key != 'product_type'),
        {key: "main_category", namespace: "custom", value:  JSON.stringify(selectedCategory) },
        {key: "sub_category", namespace: "custom", value: JSON.stringify(selectedSubCategory) },
        {key: "set_pieces", namespace: "custom", value: setPieceValue },
        {key: "product_type", namespace: "custom", value: JSON.stringify([productTypeValue]) },
        {key: "return_policy_object", namespace: "custom", value: return_policy_value },
      ]
    };
  }

  const handleSubmit = async () => {
    if (!(validateForm())) {
      return;
    }
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const token = JSON.parse(userInfo).token;
        const headers = token
          ? { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
          : undefined;

        if (isEditMode) {
          await dispatch(updateProduct({ id: productDetails?.product?.id, headers, formData: formatPayload() })).unwrap();
          window.location.reload();
        } else {
          await dispatch(createProduct({ formData: formatPayload(), headers })).unwrap();
          window.location.replace('/');
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(resetAuth());
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <Box >
        <Loader show={loaderActive} />
        {!loaderActive && !loading && (
          <>
            <MainHeader label={isEditMode ? PAGE_TITLE_EDIT : PAGE_TITLE_NEW} allowBack={true}>
              <SyncStatus 
                syncStatus={formData?.basicDetails?.shopify_sync_status}
                syncError={formData?.basicDetails?.shopify_sync_error}
                lastSyncedAt={formData?.basicDetails?.last_shopify_synced_at}
                className={styles.syncStatus}
              />
              <ButtonX color='#000' variant='outlined' onClick={handleDiscard}>Discard</ButtonX>
              <ButtonX onClick={handleSubmit}>{isEditMode ? 'Update' : 'Save'}</ButtonX>
            </MainHeader>

            <BasicDetails
              basicFormData={formData.basicDetails}
              onChange={handleBasicDetailsChange}
              validationErrors={validationErrors}
              isEditMode={isEditMode}
            />
            <VariantDetails
              variantDetails={formData.variantDetails}
              onChange={handleVariantDetailsChange}
              validationErrors={validationErrors}
              setVariantErrors={setVariantErrors}
              variantErrors={variantErrors}
              isEditMode={isEditMode}
            />
            <ProductDetailsTabs formData={formData} setFormData={setFormData} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductForm;
