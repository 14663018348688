import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './MasterCatalogue.module.scss';
import { setSort, SubCategoryMasterCatalogueApi, SubCategoryMasterDataHash } from "../../slices/subCategoryMasterCatalogueSlice";
import Loader from "../../components/atoms/Loader/Loader";
import { useNavigate} from "react-router-dom";

import { 
  resetFilter,
  setSubCategoryMasterCatalogueNameFilter,
  setSubCategoryMasterCatalogueCategoryFilter,
  setSubCategoryMasterCatalogueCreatedAtStartDateFilter,
  setSubCategoryMasterCatalogueCreatedAtEndDateFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import { subCategoryMasterCatalogueColumns } from "./SubCategoryMasterCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "../../components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "../../components/molecules/SearchByFilter/SearchByFilter";

import { DateRangeType, ROUTES } from "../../constants";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";

const searchByOptions = [
  {id: 'name', label: 'Name'},
]

const SubCategoryMasterCatalogue = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // Using theme to check the device is mobile or not
  const theme = useTheme();

  // Creating state to store loader status, filter applied and filter open
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  // Check whether the logged user is fbv user or not
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  
  // Get the collection data from store.
  const subCategoryMasterCatalogueData = useAppSelector((state: RootState) => state.subCategoryMasterCatalogue);
  const subCategoryMasterCatalogueNameFilter = useAppSelector((state: RootState) => state.filters.subCategoryMasterCatalogueNameFilter);
  const subCategoryMasterCatalogueCategoryFilter = useAppSelector((state: RootState) => state.filters.subCategoryMasterCatalogueCategoryFilter);
  const subCategoryMasterCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.subCategoryMasterCatalogueCreatedAtStartDateFilter);
  const subCategoryMasterCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.subCategoryMasterCatalogueCreatedAtEndDateFilter);

  let tableData:SubCategoryMasterDataHash[] = subCategoryMasterCatalogueData?.subCategoryMasterCatalogueData?.sub_categories || [];

  const availableCategories = subCategoryMasterCatalogueData.subCategoryMasterCatalogueData?.available_categories || [];
  const categoryOptions = availableCategories.map((c) => ({label: c, value: c}));

  const handleSort = (column: keyof SubCategoryMasterDataHash) => {
    const direction = column === subCategoryMasterCatalogueData.sortedColumn && subCategoryMasterCatalogueData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };


  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(SubCategoryMasterCatalogueApi({
        subCategoryMasterCatalogueNameFilter,
        subCategoryMasterCatalogueCategoryFilter,
        subCategoryMasterCatalogueCreatedAtStartDateFilter,
        subCategoryMasterCatalogueCreatedAtEndDateFilter,
        headers,
      })).unwrap();
      setPage(0);
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  useEffect(() => {
    if(filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!subCategoryMasterCatalogueNameFilter
      ? [
        {
          id: "name",
          label: "Name",
          value: subCategoryMasterCatalogueNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!subCategoryMasterCatalogueCategoryFilter
      ? [
        {
          id: "category",
          label: "Category",
          value: subCategoryMasterCatalogueCategoryFilter,
          type: "select",
          options: categoryOptions
        },
      ]
      : []) as AppliedFilterType[]),
  ];


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'name': {
        dispatch(setSubCategoryMasterCatalogueNameFilter(value as string)); break;
      }
      case 'category': {
        dispatch(setSubCategoryMasterCatalogueCategoryFilter(value as string)); break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setSubCategoryMasterCatalogueNameFilter(''));
    dispatch(setSubCategoryMasterCatalogueCategoryFilter(''));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setSubCategoryMasterCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setSubCategoryMasterCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Sub Category Master">
        <button
          className={styles.primaryBtn}
          onClick={() => {
            window.location.href = '/masters/sub_categories/new';
          }}
        >
          Create Sub Category
        </button>
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <SelectFilter 
            label={'Category'}
            value={subCategoryMasterCatalogueCategoryFilter}
            options={categoryOptions}
            onChange={(val) => {
              dispatch(setSubCategoryMasterCatalogueCategoryFilter(val));
              setFilterApplied(true);
            }}
          />
          <DateRangeLabel
            noDateLabel={'Created At'}
            startDate={subCategoryMasterCatalogueCreatedAtStartDateFilter}
            endDate={subCategoryMasterCatalogueCreatedAtEndDateFilter}
            onClick={() => {setShowDateRangeFilter(true)}}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />


      {!loaderActive && (
        <TableManager<SubCategoryMasterDataHash>
          data={tableData}
          columns={subCategoryMasterCatalogueColumns()}
          sortedColumn={subCategoryMasterCatalogueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={subCategoryMasterCatalogueData.sortDirection}
          showPagination
          totalCount={tableData.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={subCategoryMasterCatalogueCreatedAtStartDateFilter}
        endDate={subCategoryMasterCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default SubCategoryMasterCatalogue;
