import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError } from "axios";

type ResetPasswordApiState = {
  resetPasswordToken: string | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

type RequestResetPayload = {
  secret_key: string,
  new_password: string
}

type ErrorResponse = {
  errors: string;
};

const initialState: ResetPasswordApiState = {
  resetPasswordToken: null,
  status: "idle",
  error: null,
};

export const reset = createAsyncThunk(
  "reset",
  async (data: RequestResetPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/solomon/reset_password", data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.resetPasswordToken = action.payload;
    },
    clearState: (state) => {
      state.resetPasswordToken = initialState.resetPasswordToken;
      state.error = initialState.error;
      state.status = initialState.status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(reset.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(reset.fulfilled, (state, action) => {
          state.status = "idle";
          state.resetPasswordToken = null;
      })
      .addCase(reset.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            (action.payload as ErrorResponse).errors || "Failed to update password";
        } else {
          state.error = action.error.message || "Failed to update password";
        }
      });
  },
});

export const {clearState, setToken} = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;