import styles from "./CollectionTab.module.scss";
import { csx } from "helpers/utils";
import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import Metafields from "../Metafields/Metafields";
import Seo from "../Seo/Seo";

const CollectionTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderContent = () => {
    switch (value) {
      case 0:
        return <Metafields />;
      case 1:
        return <Seo />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%' }} className={styles.CollectionTabs}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Meta Fields" />
        <Tab label="For SEO" />
      </Tabs>
      
      {/* Render the component based on selected tab */}
      <Box sx={{ py: 3  }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default CollectionTab;

