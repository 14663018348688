import ModalBox from "components/atoms/ModalBox/ModalBox";
import styles from "./EditProductVariant.module.scss";
import { Box, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { RootState, AppDispatch } from "store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { ProductVariantFormData, updateProductVariant } from "slices/productFormSlice";
import VariantTypeView from "./VariantTypeView";
import VariantDetails from "./VariantDetails";
import { NotificationType, showNotification } from "slices/notificationSlice";

type EditProductVariantPropType = {
  open: boolean;
  onClose: () => void;
  variantMarketplaceId: string | number;
}

const EditProductVariant: React.FC<EditProductVariantPropType> = ({ open, onClose, variantMarketplaceId }) => {
  const dispatch: AppDispatch = useDispatch();
  const { productDetails } = useSelector((state: RootState) => state.productForm);

  const [selectedVariantDetail, setSelectedVariantDetail] = useState<any>(null);
  const [formData, setFormData] = useState<ProductVariantFormData | undefined>(undefined);
  const [originalFormData, setOriginalFormData] = useState<ProductVariantFormData | undefined>(undefined);

  // Fetch variant details when selectedVariant or productDetails changes
  useEffect(() => {
    if (productDetails && variantMarketplaceId) {
      const variantDetail = productDetails.product.variants.find(
        (variant: any) => variant.marketplace_unique_id === variantMarketplaceId
      );
      setSelectedVariantDetail(variantDetail);
    }
  }, [productDetails, variantMarketplaceId]);

  // Initialize or update form data when selectedVariantDetail changes
  useEffect(() => {
    if (selectedVariantDetail) {
      const formData = {
        variantImage: "",
        variantName: selectedVariantDetail.variantName || "",
        variantPricing: {
          mrp: selectedVariantDetail.mrp || 0,
          listingPrice: selectedVariantDetail.listingPrice || 0,
          costPerItem: selectedVariantDetail.costPrice || 0,
          gst: selectedVariantDetail.gst || 0,
        },
        variantInventory: {
          skuID: selectedVariantDetail.skuId || "",
          hsnCode: selectedVariantDetail.hsnCode || 0,
          stockOnHand: selectedVariantDetail.stock || 0,
          vin: selectedVariantDetail.vin || "",
          isFBV: selectedVariantDetail.isFBV || false,
          trackQuantity: selectedVariantDetail.trackQuantity || false,
          sellOnOOS: selectedVariantDetail.sellOnOOS || false,
        },
        metafields: {
          upsell_product_variants: selectedVariantDetail.metafields?.upsell_product_variants || "",
          measurement: selectedVariantDetail.metafields?.measurement || "",
          includes: selectedVariantDetail.metafields?.includes || "",
          old_price: selectedVariantDetail.metafields?.old_price || 0,
        },
        marketplace_unique_id: selectedVariantDetail.marketplace_unique_id,
      };
      setFormData(formData);
      setOriginalFormData(formData);
    }
  }, [selectedVariantDetail]);

  // Handle form data change
  const handleFormDataChange = useCallback(
    (field: keyof ProductVariantFormData, value: any) => {
      setFormData((prev) => prev ? { ...prev, [field]: value } : undefined);
    },
    []
  );

  // Handle variant type change (select another variant)
  const handleVariantTypeChange = (_variantMarketplaceId: string | number) => {
    const variantDetail = productDetails?.product?.variants.find(
      (variant: any) => variant.marketplace_unique_id === _variantMarketplaceId
    );
    setSelectedVariantDetail(variantDetail);
  };

  const isValidForm = () => {
    // check all those variants whose id is present
    const variantName = formData?.variantName?.trim() || '';
    let error = '';
    if(!variantName) 
      error = 'Empty Variant Name';
    else {
      const availableVariantNames = (productDetails?.product?.variants?.filter((variant: {marketplace_unique_id: string}) => variant.marketplace_unique_id !== formData?.marketplace_unique_id) || []).map((variant: {variantName: string}) => variant?.variantName?.trim() || '');
      if(availableVariantNames.includes(variantName)){
        error = 'Variant name already present'
      }
    }
    if(error){
      dispatch(showNotification({
        message: error,
        type: NotificationType.Error
      }));
    }
    
    return !error;
  }

  // Submit the form data
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isValidForm()) return;
    try {
      await dispatch(updateProductVariant({ id: selectedVariantDetail?.id, formData })).unwrap();
      onClose();
    }catch(e){
      console.error(e);
    }
  }

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFormData(originalFormData);
  }

  return (
    <ModalBox className={styles.wrapper} open={open} onClose={onClose}>
      {formData && (
        <form onSubmit={handleSubmit}>
          <Box className={styles.header}>
            <p className={styles.title}>Edit Variant</p>
            <CloseIcon onClick={onClose} className={styles.closeIcon} />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box className={styles.formContainer}>
            <VariantTypeView
              productData={productDetails}
              selectedVariant={formData.marketplace_unique_id}
              onVariantTypeChange={handleVariantTypeChange}
            />
            <VariantDetails
              variantDetail={formData}
              onChange={handleFormDataChange}
            />
          </Box>

          <Box className={styles.actionBtns}>
            <ButtonX color="#211913" variant="outlined" size="small" onClick={handleDiscard} className={styles.clearBtn}>
              Discard
            </ButtonX>
            <ButtonX color="#3361FF" variant="contained" className={styles.saveBtn} size="small" type="submit">
              Save
            </ButtonX>
          </Box>
        </form>
      )}
    </ModalBox>
  );
};

export default EditProductVariant;
