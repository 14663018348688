import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type SubCategoryMasterDataHash = {
  unique_key: string | number;
  name: string | null;
  category: string | null;
  created_at: string;
}

type SubCategoryMasterCatalogueApiResponse = {
  sub_categories: SubCategoryMasterDataHash[];
  available_categories: string[];
}

type SubCategoryMasterCatalogueApiState = {
  subCategoryMasterCatalogueData?: SubCategoryMasterCatalogueApiResponse | null;
  subCategoryMasterCatalogueApiStatus: "idle" | "loading" | "failed";
  subCategoryMasterCatalogueApiError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof SubCategoryMasterDataHash | null;
}

const initialState: SubCategoryMasterCatalogueApiState = {
  subCategoryMasterCatalogueData: null,
  subCategoryMasterCatalogueApiStatus: "loading",
  subCategoryMasterCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

// TODO: Update the formatRequest as per sub category
const formatRequest = (requestData: any, sub_url: string, download:boolean = false): string => {
  let url = `${sub_url}${download ? '.csv' : '.json'}?`;

  url += `q[sub_category_cont]=${requestData.subCategoryMasterCatalogueNameFilter}&`
  url += `q[master_category_category_cont]=${requestData.subCategoryMasterCatalogueCategoryFilter}&`
  if(requestData.subCategoryMasterCatalogueCreatedAtStartDateFilter){
    url += `q[created_at_gteq]=${requestData.subCategoryMasterCatalogueCreatedAtStartDateFilter}&`;
    url += `q[created_at_lteq]=${requestData.subCategoryMasterCatalogueCreatedAtEndDateFilter}&`
  }
  return url;
}

export const SubCategoryMasterCatalogueApi = createAsyncThunk(
  "subCategoryMasterCatalogueApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, '/solomon/masters/get_sub_categories');
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)



const subCategoryMasterCatalogueSlice = createSlice({
  name: 'subCategoryMasterCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof SubCategoryMasterDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.subCategoryMasterCatalogueData?.sub_categories && column){
        const sorted = sortRecords(state.subCategoryMasterCatalogueData?.sub_categories, column, direction);
        state.subCategoryMasterCatalogueData.sub_categories = sorted as SubCategoryMasterDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SubCategoryMasterCatalogueApi.pending, (state) => {
        state.subCategoryMasterCatalogueApiStatus = "loading";
        state.subCategoryMasterCatalogueApiError = null;
      })
      .addCase(
        SubCategoryMasterCatalogueApi.fulfilled,
        (state, action: PayloadAction<SubCategoryMasterCatalogueApiResponse>) => {
          state.subCategoryMasterCatalogueApiStatus = "idle";
          state.subCategoryMasterCatalogueData = {
            sub_categories: action.payload.sub_categories,
            available_categories: action.payload.available_categories
          }
        }
      )
      .addCase(SubCategoryMasterCatalogueApi.rejected, (state, action) => {
        state.subCategoryMasterCatalogueApiStatus = "failed";
        if (action.payload) {
          state.subCategoryMasterCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.subCategoryMasterCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = subCategoryMasterCatalogueSlice.actions;
export default subCategoryMasterCatalogueSlice.reducer;
