import { Box, Select } from "@mui/material";
import styles from "./FilterGroup.module.scss";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import SelectX from "components/atoms/SelectX/SelectX";
import InputX from "components/atoms/InputX/InputX";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useEffect, useState } from "react";
import AutoCompleteX from "components/atoms/AutoCompleteX/AutoCompleteX";

type FilterTypeOptions = 'text' | 'numeric' | 'select' | 'search';

type Option = {
  label: string;
  value: string;
}

export type FilterOption = {
  label: string;
  column: string;
  type: FilterTypeOptions;
  condition_connectors: {label: string, value: string}[];
  selectOptions: Option[];
}

export type Filter = {
  column: string;
  relation: string;
  condition: string | number;
}

type FilterGroupPropType = {
  options: FilterOption[];
  filters: Filter[];
  onChange: (filters: Filter[]) => void;
}

const FilterGroup:React.FC<FilterGroupPropType> = (props) => {
  const {options, filters, onChange} = props;
  const [filterOptionMap, setFilterOptionMap] = useState<Record<string, FilterOption>>({});

  useEffect(() => {
    const currFilterOptionMap: Record<string, FilterOption> = {};
    options.forEach((option) => {
      currFilterOptionMap[option.column] = option;
    });
    setFilterOptionMap(currFilterOptionMap);
    if(options.length && !filters.length){
      addMoreCondition();
    }
  }, [options]);


  const onFilterChange = (ith: number, option: FilterOption) => {
    const updatedFilters = filters.map((filter, idx) => {
      if(idx === ith) return {
        column: option.column,
        relation: option.condition_connectors[0].value,
        condition: option.type === 'numeric' ? 0 : ''
      }
      return filter;
    });
    onChange(updatedFilters);
  }

  const onConnectorChange = (ith: number, connector: string) => {
    const updatedFilters = filters.map((filter, idx) => {
      if(idx === ith) {
        const type = filterOptionMap[filter.column].type;
        return {...filter, relation: connector, condition: (type === 'numeric' ? 0 : '')}
      }
      return filter;
    });
    onChange(updatedFilters);
  }

  const onValueChange = (ith: number, value: string | number) => {
    const updatedFilters = filters.map((filter, idx) => {
      if(idx === ith) return {...filter, condition: value}
      return filter;
    });
    onChange(updatedFilters);
  }

  const onDeleteFilter = (ith: number) => {
    const updatedFilters = filters.filter((_, idx) => idx !== ith);
    onChange(updatedFilters);
  }
  
  const addMoreCondition = () => {
    const option = options[0];
    const newFilter: Filter = {
      column: option.column,
      relation: option.condition_connectors[0].value,
      condition: option.type === 'numeric' ? 0 : ''
    }
    onChange([...filters, newFilter]);
  }

  return (
    <Box className={styles.wrapper}>
      {!!filters.length && !!Object.keys(filterOptionMap).length && (
        <Box className={styles.selectedValues}>
          {filters.map((filter, idx) => {
            if(!filterOptionMap[filter.column]) return null;
            return (
              <FilterItem
                ith={idx}
                options={options}
                filter={filter}
                onFilterChange={onFilterChange}
                onConnectorChange={onConnectorChange}
                onValueChange={onValueChange}
                onDeleteFilter={onDeleteFilter}
                filterOptionMap={filterOptionMap}
                filtersCount={filters.length}
              />
            )
          })}
        </Box>
      )}
      <ButtonX size="small" color="#211913" variant="outlined" onClick={addMoreCondition} disabled={!options.length}>
        Add more condition
      </ButtonX>
    </Box>
  );
}

type FilterItemPropType = {
  ith: number;
  options: FilterOption[];
  filter: Filter;
  onFilterChange: (ith: number, option: FilterOption) => void;
  onConnectorChange: (ith: number, connector: string) => void;
  onValueChange: (ith: number, value: string | number) => void;
  onDeleteFilter: (ith: number) => void;
  filterOptionMap: Record<string, FilterOption>;
  filtersCount: number;
}

const FilterItem:React.FC<FilterItemPropType> = (props) => {
  const {ith, options, filter, onConnectorChange, onDeleteFilter, onFilterChange, onValueChange, filterOptionMap, filtersCount} = props;
  const filterOption = filterOptionMap[filter.column];

  return (
    <Box className={styles.filterItem}>
      <SelectX
        options={options.map((op) => ({label : op.label, value: op.column}))}
        value={filter.column}
        onChange={(value) => {
          onFilterChange(ith, filterOptionMap[value] )
        }}
        allowSearch
      />
      <SelectX
        options={filterOption.condition_connectors}
        value={filter.relation}
        onChange={(value) => {onConnectorChange(ith,  value)}}
        allowSearch
      />
      {filterOption.type === 'select' && (
        <SelectX
          options={filterOption.selectOptions}
          value={filter.condition as string}
          onChange={(value) => {onValueChange(ith, value)}}
          allowSearch
        />
      )}
      {filterOption.type === 'search' && (
        <AutoCompleteX
          inputOptions={filterOption.selectOptions.map(({label}) => label)}
          value={filter.condition}
          type="text"
          onChange={(e) => onValueChange(ith, e.target.value)}
        />
      )}
      {(filterOption.type === 'text' || filterOption.type === 'numeric') && (
        <InputX
          value={filter.condition}
          type={filterOption.type === 'text' ? 'text': 'number'}
          onChange={(e) => onValueChange(ith, e.target.value)}
        />
      )}
      {filtersCount > 1 && (
        <DeleteOutlinedIcon
          fontSize="small" 
          className={styles.deleteIcon}
          onClick={() => onDeleteFilter(ith)}
        />
      )}
    </Box>
  );
}

export default FilterGroup;