import { Box, Divider } from "@mui/material";
import styles from "./BannerDetails.module.scss";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BannerMetaobject } from "slices/collectionFormSlice";
import { useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import Img from "components/atoms/Img/Img";
import ImgSelector from "components/molecules/ImgSelector/ImgSelector";

type BannerDetailsPropType = {
  label: JSX.Element | React.ReactNode;
  allowMobileBanner: boolean;
  allowWebBanner: boolean;
  mobileBannerImage: BannerMetaobject | null;
  webBannerImage: BannerMetaobject | null;
  onChange: (type: 'web' | 'mobile', image: BannerMetaobject | null) => void;
}

const SingleBannerDetails:React.FC<BannerDetailsPropType> = (props) => {
  const {
    label, allowMobileBanner, allowWebBanner, onChange, webBannerImage, mobileBannerImage
  } = props;
  
  const selectedMobileBannerImage = mobileBannerImage;
  const selectedWebBannerImage = webBannerImage;
  const [showBannerSelectionPopup, setShowBannerSelectionPopup] = useState<boolean>(false);

  const [uploadImgType, setUploadImgType] = useState<'web'|'mobile'>('web');

  const onDeleteBanner = (type: 'mobile' | 'web') => {
    onChange(type,  null);
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.label}>
        {label}
      </Box>
      {(!!selectedMobileBannerImage || !!selectedWebBannerImage) && allowMobileBanner && (
        <>
          <Box className={styles.imagesWrapper}>
            <p className={styles.title}>{'Mobile Banner'}</p>
            {selectedMobileBannerImage && (
              <BannerImage 
                image={selectedMobileBannerImage.image_url as File | string} 
                onDelete={() => {onDeleteBanner('mobile')}}
                title={selectedMobileBannerImage.display_name || ''}
              />
            )}
            <BannerSelectionPopup
              open={showBannerSelectionPopup && uploadImgType === 'mobile'}
              type={'mobile'}
              showPopover={(show) => {
                uploadImgType === 'mobile' && setShowBannerSelectionPopup(show);
              }}
              onChange={onChange}
              currentBanner={selectedMobileBannerImage || null}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowMobileBanner} onClick={(e) => {
                setUploadImgType('mobile');
                setShowBannerSelectionPopup(true);
              }}>Add Mobile Banner</ButtonX>
            </BannerSelectionPopup>
          </Box>
          <Divider />
        </>
      )}
      {(!!selectedMobileBannerImage || !!selectedWebBannerImage) && allowWebBanner && (
        <>
          <Box className={styles.imagesWrapper}>
            <p className={styles.title}>{'Web Banner'}</p>
            {selectedWebBannerImage && (
              <BannerImage 
                image={selectedWebBannerImage.image_url as File | string} 
                onDelete={() => {onDeleteBanner('web')}}
                title={selectedWebBannerImage.display_name || ''}
              />
            )}
            <BannerSelectionPopup
             open={showBannerSelectionPopup && uploadImgType === 'web'}
             type={'web'}
             showPopover={(show) => {
              uploadImgType === 'web' && setShowBannerSelectionPopup(show);
            }}
            onChange={onChange}
            currentBanner={selectedWebBannerImage || null}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowWebBanner} onClick={() => {
                setUploadImgType('web');
                setShowBannerSelectionPopup(true);
              }}>Add Web Banner</ButtonX>
            </BannerSelectionPopup>
          </Box>
          <Divider />
        </>
      )}
      {!selectedMobileBannerImage && !selectedWebBannerImage && (
        <Box className={styles.actionBtns}>
          {allowMobileBanner && (
            <BannerSelectionPopup
              open={showBannerSelectionPopup && uploadImgType === 'mobile'}
              type={'mobile'}
              showPopover={(show) => {
                uploadImgType === 'mobile' && setShowBannerSelectionPopup(show);
              }}
              onChange={onChange}
              currentBanner={selectedMobileBannerImage || null}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowMobileBanner} onClick={() => {
                setUploadImgType('mobile');
                setShowBannerSelectionPopup(true);
              }}>Add Mobile Banner</ButtonX>
            </BannerSelectionPopup>
          )}
          {allowWebBanner && (
            <BannerSelectionPopup
              open={showBannerSelectionPopup && uploadImgType === 'web'}
              type={'web'}
              showPopover={(show) => {
                uploadImgType === 'web' && setShowBannerSelectionPopup(show);
              }}
              onChange={onChange}
              currentBanner={selectedWebBannerImage || null}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowWebBanner} onClick={() => {
                setUploadImgType('web');
                setShowBannerSelectionPopup(true);
              }}>Add Web Banner</ButtonX>
            </BannerSelectionPopup>
          )}
        </Box>
      )}
    </Box>
  );
}

export default SingleBannerDetails;

type BannerImagePropType = {
  image: File|string;
  title: string;
  onDelete: () => void;
}

const BannerImage:React.FC<BannerImagePropType> = (props) => {
  const {image, title, onDelete} = props;
  const imgSrc = (image instanceof File) ? URL.createObjectURL(image) : image;
  return (
    <Box className={styles.bannerImageBox}>
      <Box className={styles.bannerImg}>
        <img src={imgSrc} alt={title} />
      </Box>
      <p className={styles.filename}>{title}</p>
      <DeleteOutlinedIcon
        fontSize="small" 
        className={styles.deleteIcon}
        onClick={() => onDelete()}
      />
    </Box>
  );
}

type BannerSelectionPopup = {
  open: boolean;
  type: 'web' | 'mobile';
  showPopover: (show: boolean) => void;
  children: JSX.Element;
  onChange: (type: 'web' | 'mobile', id : BannerMetaobject | null) => void;
  currentBanner: BannerMetaobject | null;
}

const BannerSelectionPopup:React.FC<BannerSelectionPopup> = (props) => {
  const {open, type, showPopover, children, onChange, currentBanner} = props;
  const [showSelectImageOptions, setShowSelectImageOptions] = useState<boolean>(false);

  const availableImages = useAppSelector((state: RootState) => state.collectionForm.mediaImageData) || [];

  const handleAddBanner = (ids : (string|number)[]) => {
    if(ids.length){
      const mediaImage = availableImages.find((img) => img.id === ids[0]);
      if(mediaImage){
        onChange(type, mediaImage);
      }
      setShowSelectImageOptions(false);
      showPopover(false);
    }else{
      setShowSelectImageOptions(false);
    }
  }

  return (
    <ClickTooltip
      title={
        <Box className={styles.bannerOptionsWrapper}>
          <ButtonX
            color={'#262727'} size="small" variant="outlined"
            onClick={() => setShowSelectImageOptions(true)}
          >
            {!!currentBanner ? 'Update' : 'Add'}
          </ButtonX>
        </Box>
      }
      show={open && !showSelectImageOptions}
      className={styles.singleBannerPopupStyle}
      showPopper={(show) => {
        !showSelectImageOptions && showPopover(show)
      }}
      placement={'top'}
    >
      <Box>
        {children}
        {showSelectImageOptions && 
          <ImgSelector
            multiple={false}
            onSelect={handleAddBanner}
            onClose={() => setShowSelectImageOptions(false)}
            open
          />
        }
      </Box>
    </ClickTooltip>
  );
}
