import { Box } from "@mui/material";
import { csx } from "helpers/utils";
import { ProductVariantFormData, variantMetafield } from "slices/productFormSlice";
import styles from "./EditProductVariant.module.scss";
import InputX from "components/atoms/InputX/InputX";
import { useMemo } from "react";
import MultiInputX, { MultiInputItem } from "components/molecules/MultiInputX/MultiInputX";

type VariantMetafieldEditProps = {
  metafieldProps: variantMetafield,
  onMetafieldChange: (field: keyof ProductVariantFormData, value: any) => void;
}

const VariantMetafieldEdit: React.FC<VariantMetafieldEditProps> = ({
  metafieldProps,
  onMetafieldChange,
}) => {

  const measurementValue = useMemo(() => {
    let measurements: string[] = [];
    try {
      measurements = JSON.parse(metafieldProps.measurement) as string[] || [];
    }catch(e){ 
      measurements =  []
    }
    return measurements;
  }, [metafieldProps.measurement]);

  const includesValue = useMemo(() => {
    let includes: string[] = [];
    try {
      includes = JSON.parse(metafieldProps.includes) as string[] || [];
    }catch(e){ 
      includes =  []
    }
    return includes;
  }, [metafieldProps.includes]);

  const upsellSkuValues = useMemo(() => {
    let upsellSkus: string[] = [];
    try {
      upsellSkus = JSON.parse(metafieldProps.upsell_product_variants) as string[] || [];
    }catch(e){ 
      upsellSkus =  []
    }
    return upsellSkus;
  }, [metafieldProps.upsell_product_variants]);

  return (
    <Box className={csx(styles.formSection, styles.metafieldsForm)}>
      <p className={styles.formTitle}>Metafield</p>
      <Box className={styles.fields}>
        <p className={styles.fieldName}>Upsell Product Variants</p>
        <MultiInputX inputs={upsellSkuValues}
          onChange={(values) => onMetafieldChange("metafields", { ...metafieldProps, "upsell_product_variants": JSON.stringify(values)})} 
        />
        <p className={styles.fieldName}>Measurement</p>
        <MultiInputX inputs={measurementValue} 
          onChange={(values) => onMetafieldChange("metafields", { ...metafieldProps, "measurement": JSON.stringify(values)})} 
        />
        <p className={styles.fieldName}>Includes</p>
        <MultiInputX inputs={includesValue} 
          onChange={(values) => onMetafieldChange("metafields", { ...metafieldProps, "includes": JSON.stringify(values)})} 
        />
        <p className={styles.fieldName}>Old Price</p>
        <InputX value={metafieldProps.old_price} onChange={(e) => onMetafieldChange("metafields", { ...metafieldProps, "old_price": +e.target.value })} type="number" />
      </Box>
    </Box>
  );
}

export default VariantMetafieldEdit;