import { Routes, Route } from "react-router-dom";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import DefaultLayout from "./layouts/DefaultLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";
import ProductCatalogue from "./pages/ProductCatalogue/ProductCatalogue";
import CategoryMasterCatalogue from "./pages/MasterCatalogue/CategoryMasterCatalogue";
import CategoryForm from "./pages/MasterForm/CategoryForm";
import SubCategoryMasterCatalogue from "./pages/MasterCatalogue/SubCategoryMasterCatalogue";
import SubCategoryForm from "./pages/MasterForm/SubCategoryForm";
import AllMasterCatalogue from "./pages/MasterCatalogue/AllMasterCatalogue";
import MasterForm from "./pages/MasterForm/MasterForm";
import CollectionCatalogue from "./pages/CollectionCatalogue/CollectionCatalogue";
import CollectionForm from "./pages/CollectionForm/CollectionForm";
import './App.scss';
import ProductForm from "pages/ProductForm/ProductForm";
import RequestTracking from "pages/RequestTracking/RequestTracking";
import BulkImageUpload from "pages/BulkImageUpload/BulkImageUpload";
import ProductTypeMasterCatalogue from "pages/MasterCatalogue/ProductTypeMasterCatalogue";
import SetPieceMasterCatalogue from "pages/MasterCatalogue/SetPieceMasterCatalogue";
import ProductTypeForm from "pages/MasterForm/ProductTypeForm";
import SetPieceForm from "pages/MasterForm/SetPiecesForm";

function App() {
  return (
    <>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<ProductCatalogue />} />
          <Route path="/all_collections" element={<CollectionCatalogue />} />
          <Route path="/products/new" element={<ProductForm />} />
          <Route path="/products/:id/edit" element={<ProductForm />} />
          <Route path="/collections" element={<CollectionForm/>} />
          <Route path="/collections/:id/edit" element={<CollectionForm/>} />
          <Route path="/masters" element={<AllMasterCatalogue/>} />
          <Route path="/masters/new" element={<MasterForm/>} />
          <Route path="/masters/categories/new" element={<CategoryForm/>} />
          <Route path="/masters/categories" element={<CategoryMasterCatalogue/>} />
          <Route path="/masters/sub_categories" element={<SubCategoryMasterCatalogue/>} />
          <Route path="/masters/sub_categories/new" element={<SubCategoryForm/>} />
          <Route path="/masters/product_types" element={<ProductTypeMasterCatalogue />} />
          <Route path="/masters/product_types/new" element={<ProductTypeForm />} />
          <Route path="/masters/set_pieces" element={<SetPieceMasterCatalogue/>} />
          <Route path="/masters/set_pieces/new" element={<SetPieceForm />} />
          <Route path="/request-tracking" element={<RequestTracking />} />
          <Route path="/content/bulk-image-upload" element={<BulkImageUpload />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
