import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type AllMasterDataHash = {
  unique_key: string | number;
  name: string | null;
  value: string | null;
  created_at: string;
}

type AllMasterCatalogueApiResponse = {
  all_masters: AllMasterDataHash[];
  available_metafields: string[];
  total_count: number | null;
}

type AllMasterCatalogueApiState = {
  allMasterCatalogueData?: AllMasterCatalogueApiResponse | null;
  allMasterCatalogueApiStatus: "idle" | "loading" | "failed";
  allMasterCatalogueApiError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof AllMasterDataHash | null;
}

const initialState: AllMasterCatalogueApiState = {
  allMasterCatalogueData: null,
  allMasterCatalogueApiStatus: "loading",
  allMasterCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

// TODO: Update the formatRequest as per sub value
const formatRequest = (requestData: any, sub_url: string, download:boolean = false): string => {
  let url = `${sub_url}${download ? '.csv' : '.json'}?`;

  url += `q[metafield_definition_name_cont]=${requestData.allMasterCatalogueNameFilter}&`
  url += `q[name_cont]=${requestData.allMasterCatalogueValueFilter}&`
  if(requestData.allMasterCatalogueCreatedAtStartDateFilter){
    url += `q[created_at_gteq]=${requestData.allMasterCatalogueCreatedAtStartDateFilter}&`;
    url += `q[created_at_lteq]=${requestData.allMasterCatalogueCreatedAtEndDateFilter}&`
  }
  if (!download) {
    url += `page=${requestData.page + 1 }&`;
    url += `per_page=${requestData.rowsPerPage}`;
  }
  return url;
}

export const AllMasterCatalogueApi = createAsyncThunk(
  "allMasterCatalogueApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, '/solomon/masters/get_all_masters');
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

const allMasterCatalogueSlice = createSlice({
  name: 'allMasterCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof AllMasterDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.allMasterCatalogueData?.all_masters && column){
        const sorted = sortRecords(state.allMasterCatalogueData?.all_masters, column, direction);
        state.allMasterCatalogueData.all_masters = sorted as AllMasterDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AllMasterCatalogueApi.pending, (state) => {
        state.allMasterCatalogueApiStatus = "loading";
        state.allMasterCatalogueApiError = null;
      })
      .addCase(
        AllMasterCatalogueApi.fulfilled,
        (state, action: PayloadAction<AllMasterCatalogueApiResponse>) => {
          state.allMasterCatalogueApiStatus = "idle";
          state.allMasterCatalogueData = {
            all_masters: action.payload.all_masters,
            available_metafields: action.payload.available_metafields,
            total_count: action.payload.total_count
          }
        }
      )
      .addCase(AllMasterCatalogueApi.rejected, (state, action) => {
        state.allMasterCatalogueApiStatus = "failed";
        if (action.payload) {
          state.allMasterCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.allMasterCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = allMasterCatalogueSlice.actions;
export default allMasterCatalogueSlice.reducer;
