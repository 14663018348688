import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import styles from './Collections.module.scss';
import { useAppSelector } from 'hooks/redux-hooks';

const Collections: React.FC = () => {
  const [collections, setCollections] = useState<string[]>([]);  // Initialize as an empty array

  const productDetails = useAppSelector((state) => state.productForm.productDetails);

  useEffect(() => {
    if (productDetails && productDetails.product && productDetails.product.collections) {
      setCollections(productDetails.product.collections);
    }
  }, [productDetails]);

  return (
    <Box className={styles.container}>
      <Box display="flex" gap={1} flexWrap="wrap" mt={2}>
        {collections.map((collection, index) => (
          <Chip key={index} label={collection} clickable />
        ))}
      </Box>
    </Box>
  );
};

export default Collections;
