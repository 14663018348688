import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

export type ProductType = {
  id: string | number;
  image: string | null;
  title: string;
  status: string;
}

type SearchProductsData = {
  data: ProductType[];
}

type SearchProductsApiState = {
  searchResults?: SearchProductsData | null;
  searchProductsApiStatus: "idle" | "loading" | "failed";
  searchProductsApiError: string | null;
};

const initialState: SearchProductsApiState = {
  searchResults: null,
  searchProductsApiStatus: "loading",
  searchProductsApiError: null
};

type ErrorResponse = {
  errors: string;
};

export const searchProductsApi = createAsyncThunk(
  "searchProductsApi",
  async ({searchTxt, headers, excludedProductsCollectionId} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/solomon/products/product_search?query=${encodeURIComponent(searchTxt)}`;
      if(excludedProductsCollectionId) 
        url += `&excluded_products_collection_id=${excludedProductsCollectionId}`;
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

const searchProductSlice = createSlice({
  name: 'searchProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchProductsApi.pending, (state) => {
        state.searchProductsApiStatus = "loading";
        state.searchProductsApiError = null;
      })
      .addCase(
        searchProductsApi.fulfilled,
        (state, action: PayloadAction<(ProductType[]|null)>) => {
          state.searchProductsApiStatus = "idle";
          let data:ProductType[] = [];
          if(action.payload){
            data = action.payload;
          }
          state.searchResults = {data};
        }
      )
      .addCase(searchProductsApi.rejected, (state, action) => {
        state.searchProductsApiStatus = "failed";
        if (action.payload) {
          state.searchProductsApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.searchProductsApiError = action.error.message || "Error occured";
        }
      })
  }
});

export default searchProductSlice.reducer;
