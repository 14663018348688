import { Box, Button, Chip, ClickAwayListener } from "@mui/material";
import styles from "./TagList.module.scss";
import { csx } from "helpers/utils";
import CloseIcon from '@mui/icons-material/Close';
import InputX from "../InputX/InputX";
import { useEffect, useState } from "react";
import ClickTooltip from "../ClickTooltip/ClickTooltip";
import ButtonX from "../ButtonX/ButtonX";
import CheckboxFilter from "../CheckboxFilter/CheckboxFilter";

type PropType = {
  label?: JSX.Element | React.ReactNode;
  required?: boolean;
  method: 'input' | 'search' | 'select';
  tags: string[];
  wrapperClass?: string;
  labelClass?: string;
  tagListClass?: string;
  onChange: (tags: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  options?: string[];
}

const TagListX:React.FC<PropType> = (props) => {
  const {
    label, required, method, wrapperClass, labelClass, tagListClass, 
    tags, onChange, placeholder = 'Type...', disabled, error, options = []
  } = props;
  const [tagInput, setTagInput] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selections, setSelections] = useState<string[]>([]);
  const [invalidInput, setInvalidInput] = useState<boolean>(false);
  const allowedShowOptions = method !== 'input' && !!options.length;

  useEffect(() => {
    setSelections(tags);
  }, [showOptions]);

  const deleteTag = (ith: number) => {
    onChange(tags.filter((_, idx) => idx !== ith));
  }

  const addTags = (newTags: string[]) => {
    onChange([...new Set([...tags, ...newTags])]);
  }

  const handleTagInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const {nativeEvent: {key}} = e;
    if(key === 'Enter' ){
      if(method !== 'select'){
        if(!tagInput.trim()){
          // TODO: Need regex to validate
          setInvalidInput(true);
        }else{
          const tag = tagInput.trim();
          setTagInput('')
          setInvalidInput(false);
          setShowOptions(false);
          addTags([tag]);
        }
      }
    }else{
      setInvalidInput(false);
    }
  }

  const handleSubmit = () => {
    addTags(selections);
    setShowOptions(false);
    setTagInput('');
  }

  return (
    <Box className={csx(wrapperClass, styles.wrapper)}>
      {!!label && (
        <Box className={csx(styles.label, labelClass)}>
          {label} {required && <sup>*</sup>}
        </Box>
      )}
      <Box className={csx(tagListClass, styles.tags)}>
        {tags.map((tag, idx) => 
          <Chip
            className={styles.chip}
            label={tag}
            onDelete={() => deleteTag(idx)}
            deleteIcon={<CloseIcon fontSize="small" />}
          />
        )}
          <ClickTooltip
            title={
              <TagListOptions 
                options={options}
                searchTxt={tagInput}
                onSubmit={handleSubmit}
                selections={selections}
                setSelections={setSelections}
              />
            }
            show={showOptions}
            className={styles.popupStyle}
            showPopper={setShowOptions}
            placement={'bottom-start'}
          >
            <Box className={styles.tagInputSection}>
              <InputX
                value={tagInput}
                onKeyDown={handleTagInput}
                wrapperClass={styles.tagInput}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete="off"
                onChange={(e) => {setTagInput(e.target.value)}}
                onFocus={() => {allowedShowOptions && setShowOptions(true)}}
                error={!!error ? error : (invalidInput ? 'Invalid Input' : '')}
              />
            </Box>
          </ClickTooltip>
      </Box>
    </Box>
  );
}

export default TagListX;


type TagListOptionsPropType = {
  options: string[];
  searchTxt: string;
  onSubmit: () => void;
  selections: string[];
  setSelections: (selections: string[]) => void;
}

const TagListOptions:React.FC<TagListOptionsPropType> = (props) => {

  const {options, searchTxt, onSubmit, selections, setSelections} = props;

  const updateTag = (ith: number) => {
    if(selections.includes(options[ith])){
      setSelections(selections.filter((tag) => tag !== options[ith]));
    }else{
      setSelections([...selections, options[ith]]);
    }
  }

  return (
    <Box className={styles.optionsWrapper}>
      <Box className={styles.options}>
        {options.map((tag, idx) => {
          const selected = selections.includes(tag);
          if(!tag.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())) return null;
          return (
            <Box className={csx(styles.option, selected && styles.selected)} onClick={() => updateTag(idx)} tabIndex={-1}>
              <CheckboxFilter
                label={tag}
                checked={selected}
                onChange={() => {}}
              />
            </Box>
          )
        })}
      </Box>
      <Box className={styles.actionSection}>
        <ButtonX size='small' onClick={onSubmit}>Done</ButtonX>
      </Box>
    </Box>
  )
}