import styles from "./Metafields.module.scss";
import React, { useState } from "react";
import {
  Box,
  Divider,
  TextField,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import WithLabel from "components/atoms/WithLabel/WithLabel";
import SelectX from "components/atoms/SelectX/SelectX";
import InputX from "components/atoms/InputX/InputX";
import Seo from "../Seo/Seo";
import MultiInputX, { MultiInputItem } from "components/molecules/MultiInputX/MultiInputX";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import { updateCollectionFilter, updateEventCollectionGradient, updateCollectionDescription, updateCounterEnabled, updateMetakeywords, updateMidCollectionFilters, updateSubCollection } from "slices/collectionFormSlice";

const Metafields = () => {

  const dispatch = useAppDispatch();
  
  const selectInputBox = () => {
    return (
      <SelectX
        options={[
          {
            label: "Option 1",
            value: "Option 1",
          },
          {
            label: "Option 2",
            value: "Option 2",
          },
        ]}
        value={"Option 1"}
        onChange={(value) => {
          // onConnectorChange(ith, value);
        }}
        allowSearch
      />
    );
  };

  const collectionForm = useAppSelector((state: RootState) => state.collectionForm);
  const collectionFormData = collectionForm.collectionForm;

  const sub_collections_and_link = JSON.parse(collectionFormData?.metafields?.sub_collection_title_and_link.value || "[]") as string[];
  const mid_collection_filters = JSON.parse(collectionFormData?.metafields?.mid_collection_filters.value || "[]") as string[];
  const meta_keywords = JSON.parse(collectionFormData?.metafields?.meta_keywords.value || "[]") as string[];

  const handleCollectionFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCollectionFilter(event.target.value));
  };

  const handleEventCollectionGradient = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEventCollectionGradient(event.target.value));
  };

  const handleCollectionDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCollectionDescription(event.target.value));
  };

  const handleCounterEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let counter_enabled = (event.target.checked == true ? 'true' : 'false')
    dispatch(updateCounterEnabled(counter_enabled));
  };

  const handleSubCollectionChange = (sub_collections: MultiInputItem[]) => {
    const value = (sub_collections.map((sub_collection) => (sub_collection as String)))
    dispatch(updateSubCollection(JSON.stringify(value)));
  };

  const handleMidCollectionFiltersChange = (mid_collection_filters: MultiInputItem[]) => {
    const value = (mid_collection_filters.map((mid_collection_filter) => (mid_collection_filter as String)))
    dispatch(updateMidCollectionFilters(JSON.stringify(value)));
  };

  const handleMetakeywordsChange = (meta_keywords: MultiInputItem[]) => {
    const value = (meta_keywords.map((meta_keyword) => (meta_keyword as String)))
    dispatch(updateMetakeywords(JSON.stringify(value)));
  };


  return (
    <Box className={styles.Metafields}>
      <Box className={styles.header}> Meta Fields </Box>
      <Divider className={styles.divider} />

      <Box className={styles.gridContainer}>
        {/* Row 1 */}

        <Box className={styles.gridItem}>
          <WithLabel
              label="Collection Filters"
              labelPosition="left"
              className={styles.fieldClass}
            >
              <InputX
                placeholder="Collection Filters"
                required={false}
                error={""}
                value={collectionFormData?.metafields?.collection_filters.value} 
                onChange={handleCollectionFilterChange}
              />
            </WithLabel>
        </Box>

        {/* Row 2 */}
        <Box className={styles.gridItem}>
          <WithLabel
            label="Sub Collection title and link"
            labelPosition="left"
            className={styles.fieldClass}
          >
            <MultiInputX
              inputs={sub_collections_and_link} 
              onChange={handleSubCollectionChange}
            />     
          </WithLabel>
        </Box>

        <Box className={styles.gridItem}>
          <WithLabel
            label="Mid Collection Filters"
            labelPosition="left"
            className={styles.fieldClass}
          >
            <MultiInputX 
              inputs={mid_collection_filters} 
              onChange={handleMidCollectionFiltersChange}           
            />   
          </WithLabel>
        </Box>

        <Box className={styles.gridItem}>
          <WithLabel
            label="Counter Enabled"
            labelPosition="left"
            className={styles.fieldClass}
          >
            <Switch
              checked={collectionFormData?.metafields?.counter_enabled.value == "true"}
              onChange={handleCounterEnabledChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </WithLabel>
        </Box>

        {/* Row 4 */}
        <Box className={styles.gridItem}>
          <WithLabel
            label="Meta Keywords"
            labelPosition="left"
            className={styles.fieldClass}
          >
            <MultiInputX
              inputs={meta_keywords} 
              onChange={handleMetakeywordsChange}
            />    
          </WithLabel>
        </Box>

        <Box className={styles.gridItem}>
          <WithLabel
              label="Event collection gradient"
              labelPosition="left"
              className={styles.fieldClass}
            >
              <InputX
                placeholder="Event collection gradient"
                required={false}
                error={""}
                value={collectionFormData?.metafields?.event_collection_gradient.value} 
                onChange={handleEventCollectionGradient}
              />
            </WithLabel>
        </Box>

        <Box className={styles.gridItem}>
          <WithLabel
              label="Collection Description"
              labelPosition="left"
              className={styles.fieldClass}
            >
              <InputX
                placeholder="Collection Description"
                required={false}
                error={""}
                value={collectionFormData?.metafields?.collection_description.value} 
                onChange={handleCollectionDescription}
              />
            </WithLabel>
        </Box>
      </Box>

      <Seo/>
      {/* <Box className={styles.header}> For SEO </Box>

      <Box className={styles.gridContainer}>
        <Box className={styles.gridItem}>
          <WithLabel
            label="Handle"
            labelPosition="left"
            className={styles.fieldClass}
          >
            <InputX
              placeholder="Handle"
              required={false}
              error={""}
              // value={"Title value"}
              // onChange={(e) => onValueChange(ith, e.target.value)}
            />
          </WithLabel>
        </Box>
      </Box> */}

      <Divider className={styles.divider} />

    </Box>
  );
};

export default Metafields;
