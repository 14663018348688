import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";
import { Filter, FilterOption } from "components/organisms/FilterGroup/FilterGroup";
import { BannerImage } from "components/molecules/ImgSelector/ImgSelector";
import { SyncStatus } from "components/atoms/SyncStatus/SyncStatus";


type collectionMetafield = {  
  key: string;
  namespace: string;
  type: string;
  value: string;
}

type CollectionProducs = {
  id: string | number;
  image: string | null;
  title : string | null;
  status: string | null;
}

type CollectionProductData = {
  data: CollectionProducs[];
  totalCount: 0;
}

type collectionForm = {
  title: string | null;
  description: string | null;
  thumbnail: string | undefined | File; 
  alt_text: string | undefined; 
  collection_type: string | null;
  disjunctive: boolean;
  handle: string | null;
  rules: (Filter & {condition_object_id?: number|string})[];
  shopify_sync_status: SyncStatus | null;
  shopify_sync_error: string | null;
  last_shopify_synced_at: string | null;
  metafields: {
    event_collection_gradient: collectionMetafield;
    collection_description: collectionMetafield;
    collection_filters: collectionMetafield;
    sub_collection_title_and_link: collectionMetafield; 
    mid_collection_filters: collectionMetafield;
    counter_enabled: collectionMetafield;
    meta_keywords: collectionMetafield;
    sub_collections: collectionMetafield;
  };
  collection_products: CollectionProductData | null;
  collectionBanners: (string | number)[];
  leadGenerationBanners: (string | number)[];
  eventBanner: BannerMetaobject | null;
}


type FilterTypeOptions = 'text' | 'numeric' | 'select';

type CollectionFormApiResponse = collectionForm

type CollectionCondition = {
  label: string;
  column: string;
  type: FilterTypeOptions;
  condition_connectors: {label: string, value: string}[];
}[];

type CollectionConditionsApiResponse = (FilterOption & {condition_object_id?: number|string}) [];


export type BannerMetaobject = {
  id: string | number;
  image_url: string | File;
  display_name : string | null;
}

type CollectionFormApiState = {
  collectionForm: CollectionFormApiResponse;
  initialCollectionFormState: CollectionFormApiResponse;
  CollectionFormApiStatus: "idle" | "loading" | "failed";
  CollectionFormApiError: string | null;

  collectionConditions: CollectionConditionsApiResponse;
  CollectionConditionsApiStatus: "idle" | "loading" | "failed";
  CollectionConditionsApiError: string | null;

  collectionBannerMetaobjectsData: BannerMetaobject[];
  collectionBannerMetaobjectsApiStatus: "idle" | "loading" | "failed";
  collectionBannerMetaobjectsApiError: string | null;

  leadGenBannerMetaobjectsData: BannerMetaobject[];
  leadGenBannerMetaobjectsApiStatus: "idle" | "loading" | "failed";
  leadGenBannerMetaobjectsApiError: string | null;

  mediaImageData: BannerMetaobject[];
  mediaImageDataApiState: "idle" | "loading" | "failed";
  mediaImageApiError: string | null;
};

const initialState: CollectionFormApiState = {
  collectionForm: {
    title: '',
    description: '',
    thumbnail: '', 
    alt_text: '', 
    collection_type: 'smart_collection',
    disjunctive: true,
    shopify_sync_status: null,
    shopify_sync_error: null,
    last_shopify_synced_at:null,
    handle: '',
    rules: [],
    metafields: {
      collection_filters: { key: "collection_filters", namespace: "custom", type: "single_line_text_field" , value: ''},
      sub_collection_title_and_link: { key: "sub_collection_title_and_link", namespace: "custom", type: "list.single_line_text_field" , value: ''}, 
      mid_collection_filters: { key: "mid_collection_filters", namespace: "custom", type: "list.single_line_text_field" , value: ''},
      counter_enabled: { key: "counter_enabled", namespace: "custom", type: "boolean" , value: ''},
      meta_keywords: { key: "meta_keywords", namespace: "custom", type: "list.single_line_text_field" , value: ''},
      sub_collections: { key: "sub_collections", namespace: "custom", type: "list.metaobject_reference" , value: ''},
      event_collection_gradient: { key: "event_collection_gradient", namespace: "custom", type: "single_line_text_field" , value: ''},
      collection_description: { key: "collection_description", namespace: "custom", type: "single_line_text_field" , value: ''},
    },
    collectionBanners: [],
    leadGenerationBanners: [],
    eventBanner: null,
    collection_products: null,
  },
  initialCollectionFormState: {
    title: '',
    description: '',
    thumbnail: '', 
    alt_text: '', 
    collection_type: 'smart_collection',
    disjunctive: true,
    handle: '',
    shopify_sync_status: null,
    shopify_sync_error: null,
    last_shopify_synced_at:null,
    rules: [],
    metafields: {
      collection_filters: { key: "collection_filters", namespace: "custom", type: "single_line_text_field" , value: ''},
      sub_collection_title_and_link: { key: "sub_collection_title_and_link", namespace: "custom", type: "list.single_line_text_field" , value: ''}, 
      mid_collection_filters: { key: "mid_collection_filters", namespace: "custom", type: "list.single_line_text_field" , value: ''},
      counter_enabled: { key: "counter_enabled", namespace: "custom", type: "boolean" , value: ''},
      meta_keywords: { key: "meta_keywords", namespace: "custom", type: "list.single_line_text_field" , value: ''},
      sub_collections: { key: "sub_collections", namespace: "custom", type: "list.metaobject_reference" , value: ''},
      event_collection_gradient: { key: "event_collection_gradient", namespace: "custom", type: "single_line_text_field" , value: ''},
      collection_description: { key: "collection_description", namespace: "custom", type: "single_line_text_field" , value: ''},
    },
    collectionBanners: [],
    leadGenerationBanners: [],
    eventBanner: null,
    collection_products: null,
  },
  CollectionFormApiStatus: "idle",
  CollectionFormApiError: null,

  collectionConditions: [],

  CollectionConditionsApiStatus: "idle",
  CollectionConditionsApiError: null,

  collectionBannerMetaobjectsData: [],
  collectionBannerMetaobjectsApiStatus: "idle",
  collectionBannerMetaobjectsApiError: null,

  leadGenBannerMetaobjectsData: [],
  leadGenBannerMetaobjectsApiStatus: "idle",
  leadGenBannerMetaobjectsApiError:null,

  mediaImageData: [],
  mediaImageDataApiState: "idle",
  mediaImageApiError: null
};

type ErrorResponse = {
  error: {
    message: string;
  }
};

type UpdateBannerPayload = {
  type: 'collection' | 'lead_generation';
  banners: (string|number)[];
}

type AddBannerOptionPayload = {
  type: 'collection' | 'lead_generation';
  banners: BannerMetaobject[];
}

const formatRequest = (requestData: any): string => {
  let url = `/solomon/collections/get_collection?id=${requestData.id}`;
  return url;
}

export const CollectionFormApi = createAsyncThunk(
  "CollectionFormApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const CollectionConditionsApi = createAsyncThunk(
  "CollectionConditionsApi",
  async ({headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = `/solomon/collections/get_collection_conditions`
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const getLeadGenBannerMetaobjects = createAsyncThunk(
  "getLeadGenBannerMetaobjects",
  async ({ headers, ...requestData }: any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };

    const formattedRequestUrl = `solomon/collections/lead_gen_banner_metaobjects`;

    try {
      // Using POST instead of GET for saving the collection
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({ errors: "Unauthorized! Login again" }); // Handle 401 error
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getCollectionBannerMetaobjects = createAsyncThunk(
  "getCollectionBannerMetaobjects",
  async ({ headers, ...requestData }: any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };

    const formattedRequestUrl = `solomon/collections/collection_banner_metaobjects`;

    try {
      // Using POST instead of GET for saving the collection
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({ errors: "Unauthorized! Login again" }); // Handle 401 error
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);


export const getMediaImages = createAsyncThunk(
  "getMediaImages",
  async ({ headers, ...requestData }: any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };

    const formattedRequestUrl = `solomon/collections/available_media_images`;

    try {
      // Using POST instead of GET for saving the collection
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({ errors: "Unauthorized! Login again" }); // Handle 401 error
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const CollectionSaveApi = createAsyncThunk(
  "CollectionFormApi",
  async ({ headers, formData, id }: any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };

    const formattedRequestUrl = (id == null ? `/solomon/collections/create_collection` : `/solomon/collections/update_collection`);

    try {
      // Using POST instead of GET for saving the collection
      const response = await axiosInstance.post(formattedRequestUrl, formData, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({ errors: "Unauthorized! Login again" }); // Handle 401 error
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const CollectionFormSlice = createSlice({
  name: 'CollectionForm',
  initialState,
  reducers: {
    updateTitle(state, action: PayloadAction<string>) {
      if (state.collectionForm) {
        state.collectionForm.title = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateDescription(state, action: PayloadAction<string>) {
      if (state.collectionForm) {
        state.collectionForm.description = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateCollectionType(state, action: PayloadAction<string>) {
      if (state.collectionForm) {
        state.collectionForm.collection_type = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateDisjunctive(state, action: PayloadAction<boolean>) {
      if (state.collectionForm) {
        state.collectionForm.disjunctive = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateHandle(state, action: PayloadAction<string>) {
      if (state.collectionForm) {
        state.collectionForm.handle = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateThumbnail(state, action: PayloadAction<File|string|undefined>) {
      if (state.collectionForm) {
        state.collectionForm.thumbnail = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateThumbnailAltText(state, action: PayloadAction<string|undefined>) {
      if (state.collectionForm) {
        state.collectionForm.alt_text = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateCollectionFilter(state, action: PayloadAction<string|''>) {
      let collection_filter = state.collectionForm.metafields.collection_filters;
      collection_filter.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.collection_filters = collection_filter
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateCounterEnabled(state, action: PayloadAction<string|''>) {
      let counter_enabled = state.collectionForm.metafields.counter_enabled;
      counter_enabled.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.counter_enabled = counter_enabled
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateSubCollection(state, action: PayloadAction<string>) {
      let sub_collection_title_and_link = state.collectionForm.metafields.sub_collection_title_and_link;
      sub_collection_title_and_link.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.sub_collection_title_and_link = sub_collection_title_and_link
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateMidCollectionFilters(state, action: PayloadAction<string>) {
      let mid_collection_filters = state.collectionForm.metafields.mid_collection_filters;
      mid_collection_filters.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.mid_collection_filters = mid_collection_filters
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateMetakeywords(state, action: PayloadAction<string>) {
      let meta_keywords = state.collectionForm.metafields.meta_keywords;
      meta_keywords.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.meta_keywords = meta_keywords
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateEventCollectionGradient(state, action: PayloadAction<string|''>) {
      let event_collection_gradient = state.collectionForm.metafields.event_collection_gradient;
      event_collection_gradient.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.event_collection_gradient = event_collection_gradient
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateCollectionDescription(state, action: PayloadAction<string|''>) {
      let collection_description = state.collectionForm.metafields.collection_description;
      collection_description.value = action.payload
      if (state.collectionForm) {
        state.collectionForm.metafields.collection_description = collection_description
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateRules(state, action: PayloadAction<Filter[]>) {
      if (state.collectionForm) {
        state.collectionForm.rules = action.payload;
      } else {
        // Optionally handle the case where collectionForm is null
        console.warn('collectionForm is null');
      }
    },
    updateBanners(state, action: PayloadAction<UpdateBannerPayload>) {
      switch(action.payload.type){
        case 'collection': {
          state.collectionForm.collectionBanners = action.payload.banners;
          break;
        }
        case 'lead_generation': {
          state.collectionForm.leadGenerationBanners = action.payload.banners;
          break;
        }
      }
    },
    updateEventBanner(state, action: PayloadAction<BannerMetaobject|null>) {
      state.collectionForm.eventBanner = action.payload;
    },
    addAvailableImages(state, action: PayloadAction<BannerImage[]>) {
      state.mediaImageData = [...action.payload,  ...state.mediaImageData];
    },
    addBannerOptions(state, action: PayloadAction<AddBannerOptionPayload>) {
      switch(action.payload.type){
        case 'collection': {
          state.collectionBannerMetaobjectsData = [...state.collectionBannerMetaobjectsData, ...action.payload.banners]
          break;
        }
        case 'lead_generation': {
          state.leadGenBannerMetaobjectsData = [...state.leadGenBannerMetaobjectsData, ...action.payload.banners]
          break;
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CollectionFormApi.pending, (state) => {
        state.CollectionFormApiStatus = "loading";
        state.CollectionFormApiError = null;
      })
      .addCase(
        CollectionFormApi.fulfilled,
        (state, action: PayloadAction<CollectionFormApiResponse>) => {
          state.CollectionFormApiStatus = "idle";
          state.collectionForm = action.payload;
          let collectionForm = action.payload;
          if(collectionForm.rules){
            collectionForm = {
              ...collectionForm,
              rules: state.collectionForm.rules.map((rule) => ({
                ...rule, 
                column: `${rule.column}${rule.condition_object_id ? rule.condition_object_id : ''}`
              }))
            }
          }
          
          if(state.collectionForm?.eventBanner){
            state.collectionForm.eventBanner.display_name =  state.collectionForm.eventBanner.image_url ? (state.collectionForm.eventBanner.image_url as string).split('/').slice(-1)[0] :  'Auto generated' ;
          }
          state.collectionForm = collectionForm;
          state.initialCollectionFormState = collectionForm;
        }
      )
      .addCase(CollectionFormApi.rejected, (state, action) => {
        state.CollectionFormApiStatus = "failed";
        if (action.payload) {
          state.CollectionFormApiError = 
          (action.payload as ErrorResponse).error.message || "Error occured";
        } else {
          state.CollectionFormApiError = action.error.message || "Error occured";
        }
      })
      .addCase(CollectionConditionsApi.pending, (state) => {
        state.CollectionConditionsApiStatus = "loading";
        state.CollectionConditionsApiError = null;
      })
      .addCase(
        CollectionConditionsApi.fulfilled,
        (state, action: PayloadAction<CollectionConditionsApiResponse>) => {
          state.CollectionConditionsApiStatus = "idle";
          let options = action.payload ;
          options = options.map((option) => ({
            ...option, 
            column: `${option.column}${option.condition_object_id ? option.condition_object_id : ''}`
          }))
          state.collectionConditions = options;
        }
      )
      .addCase(CollectionConditionsApi.rejected, (state, action) => {
        state.CollectionConditionsApiStatus = "failed";
        if (action.payload) {
          state.CollectionConditionsApiError =
            (action.payload as ErrorResponse).error.message || "Error occured";
        } else {
          state.CollectionConditionsApiError = action.error.message || "Error occured";
        }
      })
      .addCase(getCollectionBannerMetaobjects.pending, (state) => {
        state.collectionBannerMetaobjectsApiStatus = "loading";
        state.collectionBannerMetaobjectsApiError = null;
      })
      .addCase(
        getCollectionBannerMetaobjects.fulfilled,
        (state, action: PayloadAction<BannerMetaobject[]>) => {
          state.collectionBannerMetaobjectsApiStatus = "idle";
          state.collectionBannerMetaobjectsData = action.payload
        }
      )
      .addCase(getCollectionBannerMetaobjects.rejected, (state, action) => {
        state.collectionBannerMetaobjectsApiStatus = "failed";
        if (action.payload) {
          state.collectionBannerMetaobjectsApiError =
          (action.payload as ErrorResponse).error.message || "Error occured";
        } else {
          state.collectionBannerMetaobjectsApiError = action.error.message || "Error occured";
        }
      })
      .addCase(getLeadGenBannerMetaobjects.pending, (state) => {
        state.leadGenBannerMetaobjectsApiStatus = "loading";
        state.leadGenBannerMetaobjectsApiError = null;
      })
      .addCase(
        getLeadGenBannerMetaobjects.fulfilled,
        (state, action: PayloadAction<BannerMetaobject[]>) => {
          state.leadGenBannerMetaobjectsApiStatus = "idle";
          state.leadGenBannerMetaobjectsData = action.payload
        }
      )
      .addCase(getLeadGenBannerMetaobjects.rejected, (state, action) => {
        state.leadGenBannerMetaobjectsApiStatus = "failed";
        if (action.payload) {
          state.leadGenBannerMetaobjectsApiError =
          (action.payload as ErrorResponse).error.message || "Error occured";
        } else {
          state.leadGenBannerMetaobjectsApiError = action.error.message || "Error occured";
        }
      })
      .addCase(getMediaImages.pending, (state) => {
        state.mediaImageDataApiState = "loading";
        state.mediaImageApiError = null;
      })
      .addCase(
        getMediaImages.fulfilled,
        (state, action: PayloadAction<BannerMetaobject[]>) => {
          state.mediaImageDataApiState = "idle";
          state.mediaImageData = action.payload
        }
      )
      .addCase(getMediaImages.rejected, (state, action) => {
        state.mediaImageDataApiState = "failed";
        if (action.payload) {
          state.mediaImageApiError =
          (action.payload as ErrorResponse).error.message || "Error occured";
        } else {
          state.mediaImageApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { updateTitle, updateDescription, updateCollectionType, updateDisjunctive, updateHandle, updateThumbnail, updateThumbnailAltText, updateRules, updateCollectionFilter, updateEventCollectionGradient, updateCollectionDescription, updateCounterEnabled, updateSubCollection, updateMidCollectionFilters, updateMetakeywords, updateBanners, addAvailableImages, addBannerOptions, updateEventBanner} = CollectionFormSlice.actions;
export default CollectionFormSlice.reducer;
