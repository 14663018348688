import React from 'react';
import { Box } from "@mui/material";
import styles from "./WithLabel.module.scss";

type PropType = {
  label: JSX.Element | React.ReactNode;
  children?: JSX.Element | React.ReactNode;
  labelPosition?: 'left' | 'top';
  className?: string;
  gap?: number;
}

const WithLabel: React.FC<PropType> = ({ label, children: childComponent = null, labelPosition = 'left', className, gap = 8 }) => {
  return (
    <Box className={`${styles.WithLabelWrapper} ${styles[labelPosition]} ${className}` } sx={{gap}}>
      <Box className={styles.label}>
        {label}
      </Box>
      <Box className={styles.childComponent}>
        {childComponent}
      </Box>
    </Box>
  );
}

export default WithLabel;