import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './MasterCatalogue.module.scss';
import { setSort, AllMasterCatalogueApi, AllMasterDataHash } from "../../slices/allMasterCatalogueSlice";
import Loader from "../../components/atoms/Loader/Loader";
import { useNavigate} from "react-router-dom";

import { 
  resetFilter,
  setAllMasterCatalogueNameFilter,
  setAllMasterCatalogueValueFilter,
  setAllMasterCatalogueCreatedAtStartDateFilter,
  setAllMasterCatalogueCreatedAtEndDateFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import { allMasterCatalogueColumns } from "./AllMasterCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "../../components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "../../components/molecules/SearchByFilter/SearchByFilter";

import { DateRangeType, ROUTES } from "../../constants";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";

const searchByOptions = [
  {id: 'value', label: 'Value'},
]

const AllMasterCatalogue = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // Creating state to store loader status, filter applied and filter open
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  
  // Get the collection data from store.
  const allMasterCatalogueData = useAppSelector((state: RootState) => state.allMasterCatalogue);
  const allMasterCatalogueNameFilter = useAppSelector((state: RootState) => state.filters.allMasterCatalogueNameFilter);
  const allMasterCatalogueValueFilter = useAppSelector((state: RootState) => state.filters.allMasterCatalogueValueFilter);
  const allMasterCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.allMasterCatalogueCreatedAtStartDateFilter);
  const allMasterCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.allMasterCatalogueCreatedAtEndDateFilter);

  const availableMetafields = allMasterCatalogueData.allMasterCatalogueData?.available_metafields || [];
  const metafieldOptions = availableMetafields.map((c) => ({label: c, value: c}));

  const handleSort = (column: keyof AllMasterDataHash) => {
    const direction = column === allMasterCatalogueData.sortedColumn && allMasterCatalogueData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };


  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(AllMasterCatalogueApi({
        page,
        rowsPerPage,
        allMasterCatalogueNameFilter,
        allMasterCatalogueValueFilter,
        allMasterCatalogueCreatedAtStartDateFilter,
        allMasterCatalogueCreatedAtEndDateFilter,
        headers,
      })).unwrap();
      setPage(0);
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  useEffect(() => {
    if(filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setFilterApplied(true);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!allMasterCatalogueNameFilter
      ? [
        {
          id: "name",
          label: "Name",
          value: allMasterCatalogueNameFilter,
          type: "select",
          options: metafieldOptions
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!allMasterCatalogueValueFilter
      ? [
        {
          id: "value",
          label: "Value",
          value: allMasterCatalogueValueFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
  ];


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'name': {
        dispatch(setAllMasterCatalogueNameFilter(value as string)); break;
      }
      case 'value': {
        dispatch(setAllMasterCatalogueValueFilter(value as string)); break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setAllMasterCatalogueNameFilter(''));
    dispatch(setAllMasterCatalogueValueFilter(''));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setAllMasterCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setAllMasterCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="All Masters">
        <button
          className={styles.primaryBtn}
          onClick={() => {
            window.location.href = '/masters/new';
          }}
        >
          Create Master
        </button>
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SelectFilter 
            label={'Metafield Type'}
            value={allMasterCatalogueNameFilter}
            options={metafieldOptions}
            onChange={(val) => {
              dispatch(setAllMasterCatalogueNameFilter(val));
              setFilterApplied(true);
            }}
          />
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <DateRangeLabel
            noDateLabel={'Created At'}
            startDate={allMasterCatalogueCreatedAtStartDateFilter}
            endDate={allMasterCatalogueCreatedAtEndDateFilter}
            onClick={() => {setShowDateRangeFilter(true)}}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />


      {!loaderActive && (
        <TableManager<AllMasterDataHash>
          data={allMasterCatalogueData?.allMasterCatalogueData?.all_masters || []}
          columns={allMasterCatalogueColumns()}
          sortedColumn={allMasterCatalogueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={allMasterCatalogueData.sortDirection}
          showPagination
          totalCount={allMasterCatalogueData?.allMasterCatalogueData?.total_count || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={allMasterCatalogueCreatedAtStartDateFilter}
        endDate={allMasterCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default AllMasterCatalogue;
