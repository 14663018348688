import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import styles from './ProductDetailsTabs.module.scss';
import Collections from '../Collections/Collections';
import ProductDetails from '../ProductDetails/ProductDetails';
import { BasicProductFormData, ProductFormData } from 'slices/productFormSlice';

type ProductDetailsTabsPropType = {
  formData: ProductFormData ;
  setFormData: (data: ProductFormData) => void;
}


const ProductDetailsTabs:React.FC<ProductDetailsTabsPropType> = (props) => {
  const {formData, setFormData} = props;
  const [activeTab, setActiveTab] = useState(0);

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box className={styles.reportWrapper}>
      <Box className={styles.pageTabsWrapper}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="product-details-tabs">
          <Tab label="Product Details" className={styles.tabTextField} />
          <Tab label="Collections" className={styles.tabTextField} />
          <Tab label="Activity" disabled className={styles.tabTextField} />
        </Tabs>

        {activeTab === 0 && <ProductDetails formData={formData} setFormData={setFormData} />}
        {activeTab === 1 && <Collections />}
      </Box>
    </Box>
  );
};

export default ProductDetailsTabs;
