import React, { useRef } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { XYCoord } from "dnd-core";
import { Box } from "@mui/material";

type DragItemType = 'PRODUCT_IMG_CARD' | 'INPUT_ITEM_CARD'

type DragCard = {
  id: string | number;
  children: JSX.Element | React.ReactNode;
  index: number;
  className?: string;
  moveCard: (dragIndex:number, hoverIndex:number) => void;
  cardItemType: DragItemType;
  dragRef?: React.RefObject<HTMLElement>;
}

interface DragItem {
  index: number;
  id: string;
}

const DragCard:React.FC<DragCard> = ({ id, children: content, index, moveCard, className, cardItemType, dragRef }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: cardItemType,
    hover(item: unknown, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      // Explicitly cast to DragItem
      const dragItem = item as DragItem;

      const dragIndex = dragItem.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as { y: number }).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: cardItemType,
    item: {id, index},
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drop(ref)
  if(dragRef?.current){
    drag(dragRef)
  }
  preview(ref)

  // TODO: Need functionality to update moving card css

  return (
    <Box ref={ref} sx={{opacity: opacity}}   className={className}>
      {content}
    </Box>
  );
};

export default DragCard;
