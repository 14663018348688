import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BACKEND_BASE_URL } from "../constants";
import { GetThunkAPI } from "@reduxjs/toolkit";

// Create an Axios instance with default options
const axiosInstance = axios.create({
  baseURL: BACKEND_BASE_URL,
  withCredentials: true,
});

export default axiosInstance;

const authorizationHeaders = () => {
  const userInfo = localStorage.getItem('userInfo');
  if(!userInfo) return {};
  try {
    const parsedUserInfo = JSON.parse(userInfo);
    const token = parsedUserInfo.token;
    return token ? { Authorization: `Bearer ${token}` } : {};
  }catch(e){
    console.error(e);
    return {};
  }
}

type ApiType = {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  url: string;
  headers?: Record<string,any>;
  data?: Record<string, any>;
} & Pick<GetThunkAPI<unknown>, 'rejectWithValue'>;

export const Api = async ({ method, url, headers = {}, data = {}, rejectWithValue }: ApiType) => {
  const config: AxiosRequestConfig = {
    method, url, data, headers: {...authorizationHeaders(), ...headers},
  };
  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 401) {
      return rejectWithValue({ errors: "Unauthorized! Login again" });
    }
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data);
    }
    throw error;
  }
}