import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Fab, Tab, Tabs, useMediaQuery, useTheme, } from "@mui/material";
import { RootState } from "../../store";
import styles from "./BulkImageUpload.module.scss";
import { downloadImageUploadRequestAttachedFilesApi, downloadImageUploadRequestProcessedResponseApi, setSort, uploadBulkProductImages} from "slices/bulkImageUploadSlice";
import Loader from "components/atoms/Loader/Loader";
import {
  resetFilter,
  
  setImageUploadRequestReportStatusFilter,
  setImageUploadRequestReportFileNameFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import SearchByFilter, {
  AppliedFilterType,
  SearchSelections,
} from "../../components/molecules/SearchByFilter/SearchByFilter";

import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { imageUploadRequestsApi, ImageUploadRequestDataHash } from "slices/bulkImageUploadSlice";
import { bulkImageUploadRequestsColumns } from "./BulkImageUpload.constant";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import UploadModal from "./UplodModal";
import { downloadFile } from "helpers/utils";
import TreeDropDownX from "components/molecules/TreeDropDownX/TreeDropDownX";

const PAGE_TITLE = "Image Upload Requests";

const statusOptions = [
  { value: 'processing', label: 'Processing'},
  { value: 'success', label: 'Success'},
  { value: 'failed', label: 'Failed'},
  { value: 'processed', label: 'Processed'},
];

const searchByOptions = [
  {id: 'fileName', label: 'File Name'}
]

export const bulkImageUploadRequestTypes = {
  bulk_product_image_upload: 'bulk_product_image_upload',
  bulk_product_last_image_upload: 'bulk_product_last_image_upload',
  bulk_product_size_chart_image_upload: 'bulk_product_size_chart_image_upload'
}


const BulkImageUpload = () => {
  const dispatch = useAppDispatch();

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [showBulkImportModal, setShowBulkImportModal] = useState<boolean>(false);
  const [uploadRequestType , setUploadRequestType] = useState<string>('');
  const [fileUploading, setFileUploading] = useState<boolean>(false);

  const bulkImageUploadRequestsData = useAppSelector(
    (state: RootState) => state.bulkImageUploadRequests
  );
  const imageUploadRequestReportStatusFilter = useAppSelector(
    (state: RootState) => state.filters.imageUploadRequestReportStatusFilter
  );
  const imageUploadRequestReportFileNameFilter = useAppSelector(
    (state: RootState) => state.filters.imageUploadRequestReportFileNameFilter
  );

  const tableData = bulkImageUploadRequestsData.imageUploadRequestData?.data || [];
  const totalCount: number = bulkImageUploadRequestsData?.imageUploadRequestData?.total_count || 0;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!imageUploadRequestReportFileNameFilter
      ? [
        {
          id: "fileName",
          label: "File Name",
          value: imageUploadRequestReportFileNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!imageUploadRequestReportStatusFilter
      ? [
        {
          id: "status",
          label: "Status",
          value: imageUploadRequestReportStatusFilter,
          type: "select",
          options: statusOptions,
        },
      ]
      : []) as AppliedFilterType[])
  ] as AppliedFilterType[];

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const handleSort = (column: keyof ImageUploadRequestDataHash) => {
    const direction = column === bulkImageUploadRequestsData.sortedColumn && bulkImageUploadRequestsData.sortDirection === "asc" ? "desc" : "asc";
    dispatch(setSort({ column, direction }));
  };

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      let data: any = undefined;
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        data = await handler(headers);
      }catch(e) {
        console.error(e);
      }finally{
        setFilterApplied(false);
        setLoaderActive(false);
        return data;
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const fetchData = () => {
    const payload = { imageUploadRequestReportStatusFilter, imageUploadRequestReportFileNameFilter, page, rowsPerPage};
    let handler = (headers: { Authorization: string } | undefined) => dispatch(imageUploadRequestsApi({ headers, ...payload })).unwrap();
    handleRequest(handler);
  }

  const downloadAttachments = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadImageUploadRequestAttachedFilesApi({ headers, ...payload })).unwrap();
    const data = await handleRequest(handler);
    if(data) {
      const urls = data?.urls || [];
      urls.forEach((url:string) => {
        const decodedUrl = decodeURIComponent(url);
        const match = decodedUrl.match(/(?<=filename=").*?(?=")/);
        const link = document.createElement('a');
        const fileName = match ? match[0] : 'Attachment.zip';
        link.href = url;
        link.target = "_blank";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }

  const downloadResponse = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadImageUploadRequestProcessedResponseApi({ headers, ...payload })).unwrap();
    const downloadApi = async () => {
      const data = await handleRequest(handler);
      return {payload: data};
    };
    await downloadFile({downloadApi, fileName: 'processed_response.csv', fileType: 'text/csv'});
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const uploadBulkProductImagesHandler = async (payload: FormData) => {
    setFileUploading(true);
    let handler = (headers: { Authorization: string } | undefined) => dispatch(uploadBulkProductImages({ headers, payload })).unwrap();
    await handleRequest(handler);
    setFileUploading(false);
    setShowBulkImportModal(false)
    setFilterApplied(true);
  }

  const handleFilterValueChange = ({
    id,
    value,
  }: {
    id: string;
    value: string | boolean | string[];
  }) => {
    switch (id) {
      case "status": {
        dispatch(setImageUploadRequestReportStatusFilter(value as string));
        break;
      }
      case "fileName": {
        dispatch(setImageUploadRequestReportFileNameFilter(value as string));
        break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({ id, value: "" });
  };

  const onSearchByAllClear = () => {
    dispatch(setImageUploadRequestReportFileNameFilter(""));
    dispatch(setImageUploadRequestReportStatusFilter(""));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <TreeDropDownX 
          direction="bottomRight"
          options={[
            { children: "Upload Product Image", id: bulkImageUploadRequestTypes.bulk_product_image_upload },
            { children: "Upload Size Chart", id: bulkImageUploadRequestTypes.bulk_product_size_chart_image_upload },
            { children: "Upload Last Image", id: bulkImageUploadRequestTypes.bulk_product_last_image_upload },
          ]}
          id=""
          onClick={(id) =>{
            if(id){
              setUploadRequestType(id);
              setShowBulkImportModal(true);
            }
          }}
        >
          <ButtonX>Upload File</ButtonX>
        </TreeDropDownX>
      </MainHeader>
      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <SelectFilter
            label={"Status"}
            value={imageUploadRequestReportStatusFilter}
            options={statusOptions}
            onChange={(val) => {
              dispatch(setImageUploadRequestReportStatusFilter(val));
              setFilterApplied(true);
            }}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ImageUploadRequestDataHash>
          data={tableData}
          totalCount={totalCount}
          columns={bulkImageUploadRequestsColumns({downloadAttachments, downloadResponse})}
          sortedColumn={bulkImageUploadRequestsData.sortedColumn}
          handleSort={handleSort}
          sortDirection={bulkImageUploadRequestsData.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}
      <UploadModal 
        handleUpload={uploadBulkProductImagesHandler}
        open={showBulkImportModal}
        onClose={() => !fileUploading && setShowBulkImportModal(false)}
        requestType={uploadRequestType}
        uploading={fileUploading}
      />
    </Box>
  );
};

export default BulkImageUpload;
