import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import InputX from "components/atoms/InputX/InputX";
import SelectX from "components/atoms/SelectX/SelectX";
import Loader from 'components/atoms/Loader/Loader';
import styles from './SubCategoryForm.module.scss';
import { RootState, AppDispatch } from '../../store';
import {
  fetchCategoryNames,
  createSubCategory,
  SubCategoryFormData,
} from 'slices/subCategoryFormSlice';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import { useAppSelector } from 'hooks/redux-hooks';

const PAGE_TITLE_NEW = "Add New SubCategory";
const PAGE_TITLE_EDIT = "Edit SubCategory"; // TODO: Edit functionality to be handled later

const SubCategoryForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const isEditMode = !!id;
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const { loading, productDetails } = useSelector(
    (state: RootState) => state.productForm
  );

  const initialFormData: SubCategoryFormData = {
    categoryId: undefined,
    value: ""
  };

  const categoryNames = useAppSelector((state) => state.subCategoryForm.categoryNames) as {name: string, id: string }[];
  const [formData, setFormData] = useState<SubCategoryFormData>(initialFormData);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');

    if (userInfo) {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

        await dispatch(fetchCategoryNames({ headers })).unwrap();
      } catch (error: any) {
        setFetchError(error.message || 'Failed to fetch data');
      } finally {
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
      setLoaderActive(false);
    }
  };

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        await handler(headers);
      }catch(e) {
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
    }
  }

  useEffect(() => {
    fetchData();
  }, [dispatch, id, isEditMode]);

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.reload();
  };

  if (fetchError) {
    return <p>Error: {fetchError}</p>;
  }

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData.categoryId) {
      errors.categoryId = 'Category selection is required';
    }
    if (!formData.value) {
      errors.value = 'Value is required';
    }
    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const token = JSON.parse(userInfo).token;
        const headers = token
          ? { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
          : undefined;

        if (isEditMode) {
        } else {
          await dispatch(createSubCategory({ formData: formData, headers }));
          window.location.replace('/masters/sub_categories');
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(resetAuth());
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <Loader show={loaderActive} />
        {!loaderActive && !loading && (
          <>
            <MainHeader label={isEditMode ? PAGE_TITLE_EDIT : PAGE_TITLE_NEW} allowBack={true}>
              <button className={styles.secondaryBtn} onClick={handleDiscard}>Discard</button>
              <button type="submit" className={styles.primaryBtn}>{isEditMode ? 'Update' : 'Save'}</button>
              {/* <ButtonX color='#000' variant='outlined' onClick={handleDiscard}>Discard</ButtonX>
              <ButtonX>{isEditMode ? 'Update' : 'Save'}</ButtonX> */}
            </MainHeader>
            <Box className={styles.formSection}>
              <Box className={styles.halfWidth}>
                <SelectX
                  wrapperClass={styles.textField}
                  label={"Category"}
                  required={true}
                  options={categoryNames.map((categoryName) => ({
                    label: categoryName.name,
                    value: categoryName.id,
                  }))}
                  value={formData.categoryId}
                  error={validationErrors.categoryId}
                  onChange={(value) => {
                    validationErrors.categoryId = "";
                    setFormData((prev) => ({
                      ...prev,
                      categoryId: value
                    }));
                  }}
                  allowSearch
                />
              </Box>
              <Box className={styles.halfWidth}>
                <InputX
                  wrapperClass={styles.textField}
                  label={"Value"}
                  placeholder="Add Value"
                  required
                  value={formData.value}
                  error={validationErrors.value}
                  onChange={(e) => {
                    validationErrors.value = "";
                    setFormData((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};

export default SubCategoryForm;

