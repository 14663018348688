import { ProductVariantFormData, variantPricing } from "slices/productFormSlice";
import styles from "./EditProductVariant.module.scss";
import { Box } from "@mui/material";
import InputX from "components/atoms/InputX/InputX";
import { csx } from "helpers/utils";
import SelectX from "components/atoms/SelectX/SelectX";

type VariantPriceEditProps = {
  onVariantPricingChange: (field: keyof ProductVariantFormData, value: any) => void;
  pricingProps: variantPricing
}
type GSTOption = { label: string, value: string };
const gstOptions: GSTOption[] = [
  { label: "5", value: "5" },
  { label: "12", value: "12" },
  { label: "18", value: "18" },
  { label: "28", value: "28" }
];

const VariantPriceEdit: React.FC<VariantPriceEditProps> = ({ pricingProps, onVariantPricingChange }) => {
  return (
    <Box className={csx(styles.formSection, styles.pricingForm)}>
      <p className={styles.formTitle}>Pricing</p>
      <Box className={styles.priceInputsWrapper}>
        <InputX label="MRP" value={pricingProps.mrp} type="number" onChange={(e) => onVariantPricingChange("variantPricing", { ...pricingProps, "mrp": +e.target.value })}
        />
        <InputX label="Listing Price" value={pricingProps.listingPrice} type="number" onChange={(e) => onVariantPricingChange("variantPricing", { ...pricingProps, "listingPrice": +e.target.value })} />
        <InputX label="Cost per Item" value={pricingProps.costPerItem} onChange={(e) => onVariantPricingChange("variantPricing", { ...pricingProps, "costPerItem": +e.target.value })} type="number" />
        <SelectX
          label="GST"
          value={pricingProps.gst.toString()}
          onChange={(e) => onVariantPricingChange("variantPricing", { ...pricingProps, "gst": (e) })}
          options={gstOptions}
          className={styles.gstDropdown}
          wrapperClass={styles.gstDropdownWrapper}
          allowSearch
        />
      </Box>
    </Box>
  );
}

export default VariantPriceEdit;