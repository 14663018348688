import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import InputX from "components/atoms/InputX/InputX";
import SelectX from "components/atoms/SelectX/SelectX";
import Loader from 'components/atoms/Loader/Loader';
import styles from './ProductTypeForm.module.scss';
import { RootState, AppDispatch } from '../../store';
import {
  fetchMetafieldNames,
  createMaster,
  MasterFormData,
} from 'slices/masterFormSlice';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import { useAppSelector } from 'hooks/redux-hooks';
import ButtonX from 'components/atoms/ButtonX/ButtonX';
import { createProductType } from 'slices/productTypeMasterCatalogueSlice';
import { fetchCategories, fetchSubcategories } from 'slices/productFormSlice';

const PAGE_TITLE_NEW = "Add New Product Type";
const PAGE_TITLE_EDIT = "Edit Product Type";

type FormData = {
  value: string;
  categoryId: string;
  subCategoryId: string;
}

const ProductTypeForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const isEditMode = !!id;
  const [formData, setFormData] = useState<FormData>({value: '', subCategoryId: '' , categoryId: ''});

  const categories = useAppSelector((state) => state.productForm.categories) as {name: string, id: string | number}[];
  const subCategories = useAppSelector((state) => state.productForm.subcategories) as {name: string, id: string | number} [];

  const fetchData = async () => {
    setLoaderActive(true);
    try {
      await dispatch(fetchCategories({})).unwrap();
      await dispatch(fetchSubcategories({payload: {}})).unwrap();
    }catch(e){
      console.error(e)
    }finally{
      setLoaderActive(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDiscard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.reload();
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};
    if (!formData.value.trim()) {
      errors.value = 'Value is required';
    }
    if(!formData.categoryId){
      errors.categoryId = 'Category is required'
    }
    if(!formData.subCategoryId){
      errors.subCategoryId = 'Sub Category is required'
    }
    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoaderActive(true);
    formData.value = formData.value.trim();
    
    try {
      await dispatch(createProductType({ formData: formData })).unwrap();
      window.location.replace('/masters/product_types');
    }catch(e){
      setLoaderActive(false);
      console.error(e)
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <Loader show={loaderActive} />
        {!loaderActive && (
          <>
            <MainHeader label={isEditMode ? PAGE_TITLE_EDIT : PAGE_TITLE_NEW} allowBack={true}>
              <ButtonX color="#000" variant="outlined" onClick={handleDiscard}>Discard</ButtonX>
              <ButtonX type="submit">{isEditMode ? 'Update' : 'Save'}</ButtonX>
            </MainHeader>
            <Box className={styles.formSection}>
              <Box className={styles.halfWidth}>
                <SelectX
                  wrapperClass={styles.textField}
                  label={"Category"}
                  required={true}
                  options={categories.map((categoryName) => ({
                    label: categoryName.name,
                    value: categoryName.id.toString(),
                  }))}
                  value={formData.categoryId}
                  error={validationErrors.categoryId}
                  onChange={(value) => {
                    validationErrors.categoryId = "";
                    setFormData((prev) => ({
                      ...prev,
                      categoryId: value
                    }));
                  }}
                  allowSearch
                />
              </Box>
              <Box className={styles.halfWidth}>
                <SelectX
                  wrapperClass={styles.textField}
                  label={"Sub Category"}
                  required={true}
                  options={subCategories.map((sc) => ({
                    label: sc.name,
                    value: sc.id.toString(),
                  }))}
                  value={formData.subCategoryId}
                  error={validationErrors.subCategoryId}
                  onChange={(value) => {
                    validationErrors.subCategoryId = "";
                    setFormData((prev) => ({
                      ...prev,
                      subCategoryId: value
                    }));
                  }}
                  allowSearch
                />
              </Box>
              <Box className={styles.halfWidth}>
                <InputX
                  wrapperClass={styles.textField}
                  label={"Value"}
                  placeholder="Add Value"
                  required
                  value={formData.value}
                  error={validationErrors.value}
                  onChange={(e) => {
                    validationErrors.value = "";
                    setFormData((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};

export default ProductTypeForm;

