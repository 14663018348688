import { createTheme } from "@mui/material";

const appTheme = createTheme({
  palette: {
    text: {
      primary: '#262727',
      secondary: '#7A7A7A',
    }
  },
  typography: {
    fontFamily : "'Poppins', sans-serif",
  },
});

export default appTheme;