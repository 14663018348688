import { Box } from "@mui/material";
import styles from "./MultiInputX.module.scss";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import { useEffect, useState } from "react";
import InputX from "components/atoms/InputX/InputX";
import { v4 as uuid } from 'uuid';
import InputDragger from "./InputDragger";
import { csx } from "helpers/utils";
import React from "react";

const getRandomKey = () => uuid();

export type MultiInputItem = string | number;

export type MultiInputPropType = {
  inputs: MultiInputItem[];
  onChange: (values: MultiInputItem[]) => void;
  type?: 'text' | 'number';
  error?: string;
  required?: boolean;
  label?: JSX.Element | React.ReactNode;
  labelClass?: string;
  wrapperClass?: string;
  inputWrapperClass?: string;
  disabled?: boolean;
}

export type CurrentMultiInputItemType = {
  id: number | string;
  value: MultiInputItem;
}

const separator = " • ";

const MultiInputX:React.FC<MultiInputPropType> = (props) => {
  const {inputs, onChange, type = 'text',  wrapperClass} = props;
  const [showInputForm, setShowInputForm] = useState<boolean>(false);
  const [currentInputs, setCurrentInputs] = useState<CurrentMultiInputItemType[]>([]);
  
  const renderedValues = inputs.join(separator);

  const handleInputUpdates = () => {
    let updatedInputs = currentInputs.filter((input) => {
      if(type === 'text' && !(input.value as string).trim()){
        return false;
      }
      return true;
    });
    updatedInputs = updatedInputs.map((input) => {
      if(type === 'text') return {...input, value: (input.value as string).trim()}
      return input;
    });
    const formattedInputs = updatedInputs.map((input) => input.value);
    onChange(formattedInputs);
    setShowInputForm(false);
  }

  useEffect(() => {
    if(showInputForm){
      setCurrentInputs(inputs.map(input => ({value: input, id: getRandomKey()})));
    }
  }, [showInputForm]);

  return (
    <ClickTooltip
      title={
        <InputDragger 
          onChange={setCurrentInputs}
          inputs={currentInputs}
          handleSubmit={handleInputUpdates}
          type={type}
        />
      }
      show={showInputForm}
      className={styles.popupStyle}
      showPopper={setShowInputForm}
      placement={'bottom-start'}
      wrapperClassName={csx(styles.wrapper, wrapperClass)}
    >
      <Input 
        {...props}
        renderedValues={renderedValues}
        showInputForm={() => {setShowInputForm(prev => !prev)}}
      />
    </ClickTooltip>
  );
}

export default MultiInputX;

type InputPropType = Omit<MultiInputPropType, 'onChange' | 'wrapperClass'> & {
  renderedValues: string;
  showInputForm: () => void;
}

const Input = React.forwardRef<HTMLDivElement, InputPropType>((props, ref) => {
  const {label, required, inputWrapperClass, type, error, renderedValues, labelClass, showInputForm, disabled} = props;
  return (
    <>
      {!!label && (
        <Box className={csx(styles.textLabel, labelClass)}>
          {label} {required && <sup>*</sup>}
        </Box>
      )}
      <Box className={csx(styles.inputWrapper, inputWrapperClass)} ref={ref}> 
        <InputX value={renderedValues} type={type} error={error} required={required} disabled={disabled} />
        <Box className={csx(styles.clickLayer, error && styles.err, disabled && styles.disabled)}  onClick={() => !disabled && showInputForm()} />
      </Box>
    </>
  )
})