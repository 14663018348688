import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type CollectionDataHash = {
  unique_key: string | number;
  marketplace_unique_id: string | number;
  name: string | null;
  type: string | null;
  collection_products: number | null;
  created_at: string;
  updated_at: string;
  updated_by: string;
  rules: string[];
  image: string | null;
}

type CollectionCatalogueApiResponse = {
  collections: CollectionDataHash[];
  total_count: number | null;
}

type CollectionCatalogueApiState = {
  collectionCatalogueData?: CollectionCatalogueApiResponse | null;
  collectionCatalogueApiStatus: "idle" | "loading" | "failed";
  collectionCatalogueApiError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof CollectionDataHash | null;
};

const initialState: CollectionCatalogueApiState = {
  collectionCatalogueData: null,
  collectionCatalogueApiStatus: "loading",
  collectionCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/solomon/collections/get_all_collections${download ? '.csv' : '.json'}?`;

  url += `q[title_cont]=${requestData.collectionCatalogueCollectionNameFilter}&`
  url += `q[collection_type_cont]=${requestData.collectionCatalogueCollectionTypeFilter}&`
  url += `q[collection_products_product_product_variants_sku_eq]=${requestData.collectionCatalogueSkuFilter}&`
  if(requestData.collectionCatalogueCreatedAtStartDateFilter){
    url += `q[created_at_gteq]=${requestData.collectionCatalogueCreatedAtStartDateFilter}&`;
    url += `q[created_at_lteq]=${requestData.collectionCatalogueCreatedAtEndDateFilter}&`
  }
  if (!download) {
    url += `page=${requestData.page + 1 }&`;
    url += `per_page=${requestData.rowsPerPage}`;
  }
  return url;
}

export const collectionCatalogueApi = createAsyncThunk(
  "collectionCatalogueApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const collectionCatalogueDownloadApi = createAsyncThunk(
  "collectionCatalogueDownloadApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const collectionCatalogueSlice = createSlice({
  name: 'collectionCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof CollectionDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.collectionCatalogueData?.collections && column){
        const sorted = sortRecords(state.collectionCatalogueData?.collections, column, direction);
        state.collectionCatalogueData.collections = sorted as CollectionDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(collectionCatalogueApi.pending, (state) => {
        state.collectionCatalogueApiStatus = "loading";
        state.collectionCatalogueApiError = null;
      })
      .addCase(
        collectionCatalogueApi.fulfilled,
        (state, action: PayloadAction<CollectionCatalogueApiResponse>) => {
          state.collectionCatalogueApiStatus = "idle";
          state.collectionCatalogueData = {
            collections: action.payload.collections,
            total_count: action.payload.total_count
          }
        }
      )
      .addCase(collectionCatalogueApi.rejected, (state, action) => {
        state.collectionCatalogueApiStatus = "failed";
        if (action.payload) {
          state.collectionCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.collectionCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = collectionCatalogueSlice.actions;
export default collectionCatalogueSlice.reducer;
