import {
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

import solomonDesktopBanner from "../../images/solomon_desktop.png"
import heroBanner from "../../images/hero_banner_2.png"
import Footer from "../../components/atoms/Footer/Footer";
import { clearState, generateResetOtp, validateResetOtp } from "../../slices/forgotPasswordSlice";
import { Navigate } from "react-router-dom";
import styles from "./Auth.module.scss";

const formatRemainTime = (timeLeft: number) => {
  let min = Math.floor(timeLeft/60);
  let sec = timeLeft % 60;
  return `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
}

type InputErrors = {
  email: string,
  otp: string,
}

const ForgotPassword = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [intervalId, setIntervalId] = useState<any>(null);
  const [timeLeft, setTimeLeft] = useState(180);
  const [errors, setErrors] = useState<InputErrors>({email: '' , otp: ''});

  const resetPasswordToken = useAppSelector((state) => state.resetPassword.resetPasswordToken);
  const forgotPasswordData = useAppSelector((state) => state.forgotPassword);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
      dispatch(clearState());
    }
  }, []);


  useEffect(() => {
    if(forgotPasswordData.otpValidationToken){
      const currentIntervalId = setInterval(() => {
        setTimeLeft((oldTime) => {
          if(oldTime === 0){
            clearInterval(currentIntervalId);
            setIntervalId(null);
            return 180;
          }
          return oldTime - 1;
        });
      }, 1000);
      setIntervalId(currentIntervalId);
    }
  }, [forgotPasswordData.otpValidationToken]);


  if(resetPasswordToken){
    return <Navigate replace to={'/reset-password'} />
  }

  const generateOTP = () => {
    const emailPattern =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(email); 
    if(!isValid){
      errors.otp = 'Invalid email entered';
      return;
    }
    dispatch(generateResetOtp({email}));
  }

  const handleOTPSumit = () => {
    if(otp.toString().length !== 6){
      errors.otp = 'Invalid OTP entered';
      return;
    }
    dispatch(validateResetOtp({secret_key: forgotPasswordData.otpValidationToken || '', current_otp: otp.toString()}));
  }
  
  const updateEmail = (e: any) => {
    setEmail(e.target.value);
    setErrors({email: '' , otp: ''});
  }
  const updateOtp = (e: any) => {
    setOtp(e.target.value);
    setErrors({email: '' , otp: ''});
  }

  const have_token = !!forgotPasswordData.otpValidationToken;


  return (
    <Box className={styles.authWrapper}>
      <Box className={styles.mainContent}>
        <Box className={styles.bannerSection}>
          <img className={styles.headerBanner} src={solomonDesktopBanner} alt="Solomon desktop banner" />
          <img className={styles.heroBanner} src={heroBanner} alt="Solomon login hero banner" />
        </Box>
        <Box className={styles.inputSection}>
          <Box className={styles.inputForm}>
            <Typography className={styles.heading}>Forgot Password</Typography>
            <Typography className={styles.subHeading}>Enter your email Id and we will send you an OTP to reset the password.</Typography>
            <TextField
              className={styles.txtInput}
              error={!!errors.email}
              required
              autoFocus
              id="email"
              type="email"
              label="Email Id"
              value={email}
              onChange={updateEmail}
              disabled={have_token}
              helperText={errors.email}
            />
            {have_token ? (
              <>
                <TextField
                  className={styles.txtInput}
                  error={!!errors.otp}
                  required
                  autoFocus
                  id="otp"
                  type='number'
                  label="OTP"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                    MozAppearance: "textfield",
                  }}}
                  value={otp}
                  onChange={updateOtp}
                  helperText={errors.otp}
                />
                <Box className={styles.otpSection}>
                  {intervalId ? (
                    <Typography sx={{mr: 'auto'}} className={styles.basicTxt}>
                      OTP Validity {formatRemainTime(timeLeft)}
                    </Typography>
                  ): (
                    <Typography sx={{ml: 'auto'}} className={styles.basicTxt}>
                      Haven't received? <Typography component={'span'} className={styles.link} onClick={generateOTP}>Resend OTP</Typography>
                    </Typography>
                  )}
                </Box>
                <Button
                  className={styles.actionBtn}
                  variant="contained" 
                  onClick={handleOTPSumit}
                >
                  Submit
                </Button>
              </>
            ): (
              <Button
                className={styles.actionBtn}
                variant="contained" 
                onClick={generateOTP}
              >
                Generate OTP
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ForgotPassword;
