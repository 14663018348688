import { formatCurrency } from "./formatUtils";

export const disableFutureDates = (date: Date) => {
  const currentDate = new Date();
  return date > currentDate;
}
export const disablePaymentReconFutureDates = (date: Date) => {
  const currentDate = new Date(new Date().setDate(new Date().getDate() + 30));
  return date > currentDate;
}

export const getPercentVal = (num: number, den: number) => {
  if (den === 0) return 0;
  return (num / den) * 100;
}
export const formatToIndianCurrency = (num: number, decimals: number = 2) => {
  if(num >= 1e5){
    return `₹${(num/1e5).toFixed(decimals)}L`
  }
  return formatCurrency(num, decimals);
}


export const sortRecords = (data: Record<string, any>[], key: string |  null, direction: 'asc' | 'desc') => {

  if (!key || !data.length || !(key in data[0])) return data;

  return [...data].sort((a, b) => {

    const isNumber = (value: any): value is number => typeof value === 'number';
    const isString = (value: any): value is string => typeof value === 'string';

    let compareResult: number;
    
    if (isNumber(a[key]) && isNumber(b[key])) {
      compareResult = a[key] - b[key];
    } else if (isString(a[key]) && isString(b[key])) {
      compareResult = a[key].localeCompare(b[key]);
    } else {
      // Handle other types as needed
      compareResult = 0; // Default to no change
    }
    return direction === 'asc' ? compareResult : -compareResult;
  });

}

type downloadFilePropType = {
  downloadApi: () => Promise<any>;
  fileName: string;
  fileType: string;
}
export const downloadFile = async (props: downloadFilePropType) => {
  const {downloadApi, fileName, fileType} = props;
  try{
    const data = await downloadApi();
    {/* @ts-ignore */}
    if(data) {
      const blob = new Blob([data.payload], { type: fileType });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  }catch(e){
    console.error(e)
  }
  return false;
}

export const checkNA = (value: string | number | undefined | null) => {
  return (value === null || value === undefined) ? 'NA' : value
}
export const checkCurrencyNA = (value: number | undefined | null, full: boolean = true) => {
  return (value === null || value === undefined) 
  ? 'NA' 
  : (full ? formatCurrency(value) : formatToIndianCurrency(value))
}
export const checkPercentNA = (value: number | undefined | null) => {
  return (value === null || value === undefined) 
  ? 'NA' 
  : `${value}%`
}

export const csx = (...classNames: (any)[]) => classNames.filter(className => typeof className === 'string' && !!className).join(' ');