import { SetPieceMasterDataHash } from "slices/setPieceMasterCatalogueSlice";
import { ColumnPropType } from "../../components/organisms/TableManager/TableManager"


export const setPieceMasterCatalogueColumns = ():ColumnPropType<SetPieceMasterDataHash>[] => [
  {
    label: 'Name',
    accessor: 'name',
    minWidth: 300
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 250,
    sortable: true,
  }
]
