import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

export interface MasterFormData {
  metafieldId: string | undefined;
  value: string | null;
}

interface MasterFormState {
  metafieldNames: any[];
  loading: boolean;
  error: string | null;
}

const initialState: MasterFormState = {
  metafieldNames: [],
  loading: false,
  error: null,
};

const fetchApiData = async (url: string, method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", headers: any, requestData: any = {}, rejectWithValue: any) => {
  const config: AxiosRequestConfig = {
    headers: headers || {},
    method,
    url,
    data: requestData,
  };
  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 401) {
      return rejectWithValue({ errors: "Unauthorized! Login again" });
    }
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data);
    }
    throw error;
  }
};

export const createMaster = createAsyncThunk("masterForm/createMaster", async ({ headers, formData }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/create_master";
  return await fetchApiData(url, "POST", headers, formData, rejectWithValue);
});

export const fetchMetafieldNames = createAsyncThunk("masterForm/fetchMetafieldNames", async ({ headers }: any, { rejectWithValue }) => {
  const url = `/solomon/masters/get_metafield_definitions`;
  return await fetchApiData(url, "GET", headers, null, rejectWithValue);
});

const masterFormSlice = createSlice({
  name: "masterForm",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.metafieldNames = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMetafieldNames.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMetafieldNames.fulfilled, (state, action: PayloadAction<any>) => {
      state.metafieldNames = action.payload.metafield_names;
      state.loading = false;
    });
    builder.addCase(fetchMetafieldNames.rejected, (state, action) => {
      state.error = action.error.message || "Failed to fetch vendor details";
      state.loading = false;
    });
  },
});

export const { resetForm } = masterFormSlice.actions;
export default masterFormSlice.reducer;
