import { Box, TextField } from "@mui/material";
import styles from "./InputX.module.scss";
import { TextFieldProps } from "@mui/material";
import { csx } from "helpers/utils";

export type InputXProps = Omit<TextFieldProps, 'error' | 'helperText' | 'label'> & {
  label?: JSX.Element | React.ReactNode;
  error?: string;
  wrapperClass?: string;
  labelClass?: string;
  inputWrapperClass?: string;
}

const InputX: React.FC<InputXProps> = (props) => {
  const { inputWrapperClass, label, required, error, className, wrapperClass, labelClass, multiline = false, type, value, rows = 1,  ...rest } = props;
  const val = (type === 'number' && typeof value === 'number') ? value.toString() : value;

  return (
    <Box className={csx(wrapperClass, styles.inputXWrapper)}>
      {!!label && (
        <Box className={csx(styles.textLabel, labelClass)}>
          {label} {required && <sup>*</sup>}
        </Box>
      )}
      <Box className={csx(inputWrapperClass, styles.inputWrapper)}>
        <TextField
          fullWidth
          multiline={multiline}
          value={val}
          rows={multiline ? rows : undefined}
          className={csx(className, styles.inputX)}
          error={!!error}
          helperText={error}
          type={type}
          {...rest}
        />
      </Box>
    </Box>
  );
}

export default InputX;