import { UnfoldMoreRounded } from "@mui/icons-material";
import { TableSortLabel } from "@mui/material";
import "./SortableColumnHeadCell.css";
import React from "react";

type SortableColumnHeadCellPropType<T extends Record<string,any>> = {
  accessor: keyof T;
  label: React.ReactNode;
  handleSort: (accessor: keyof T) => void;
  sortedColumn: keyof T | null;
  sortDirection: 'asc' | 'desc';
}

const SortableColumnHeadCell = <T extends Record<string,any>>(props: SortableColumnHeadCellPropType<T>): JSX.Element => {
  const {accessor, label, handleSort, sortedColumn, sortDirection} = props;
  return (
    <TableSortLabel
      active={sortedColumn === accessor}
      direction={sortDirection}
      onClick={() => handleSort(accessor)}
      hideSortIcon
    >
      <div className="sortable-column-label">
        <p>{label}</p>
        {sortedColumn !== accessor && (
          <UnfoldMoreRounded sx={{ml: 0.4, color: '#00000099'}} fontSize='small'/>
        )}
      </div>
    </TableSortLabel>
  );
}
export default SortableColumnHeadCell;