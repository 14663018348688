import Box from '@mui/material/Box';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from "../../../constants";
import { useLocation } from 'react-router-dom';
import styles from "./Navbar.module.scss";
import TreeDropDownX from '../TreeDropDownX/TreeDropDownX';
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSelected = (route: string) => {
    return location.pathname === route ? 'selected' : '';
  }

  return (
    <Box className={styles['nav-topbar-wrapper']}>
      <List className={styles['topbar-link-list']}>
        <ListItem
          className={`${styles['topbar-link']} ${isSelected(ROUTES.PRODUCTS) == 'selected' ? styles['selected'] : 'unselected'}`}
          component={Link}
          to={ROUTES.PRODUCTS}
        >
          <ListItemText primary="Products" className={styles['link-text']} />
        </ListItem>
        <ListItem
          className={`${styles['topbar-link']} ${styles[isSelected(ROUTES.COLLECTIONS)]}`}
          component={Link}
          to={ROUTES.COLLECTIONS}
        >
          <ListItemText primary="Collections" className={styles['link-text']} />
        </ListItem>
        <TreeDropDownX
          direction={'bottom'}
          id={ROUTES.MASTERS}
          onClick={(route) => navigate(route)}
          options={[
            {children: 'Categories' , id: ROUTES.CATEGORIES},
            {children: 'Sub Categories' , id: ROUTES.SUB_CATEGORIES},
            {children: 'Product Types' , id: ROUTES.PRODUCT_TYPES},
            {children: 'Set & Pieces' , id: ROUTES.SET_PIECES},
            {children: 'All Masters' , id: ROUTES.MASTERS},
          ]}
        >
          <ListItem className={`${styles['topbar-link']} ${styles[isSelected(ROUTES.MASTERS)]}`} component={Link} to={ROUTES.MASTERS}>
            <ListItemText primary="Masters" className={styles['link-text']} />
          </ListItem>
        </TreeDropDownX>
        <TreeDropDownX
          direction={'bottom'}
          id={ROUTES.BULK_IMAGE_UPLOAD}
          onClick={(route) => navigate(route)}
          options={[
            {children: 'Bulk Image Upload' , id: ROUTES.BULK_IMAGE_UPLOAD},
          ]}
        >
          <ListItem
            className={`${styles['topbar-link']} ${styles[isSelected(ROUTES.BULK_IMAGE_UPLOAD)]}`} component={Link} to={ROUTES.BULK_IMAGE_UPLOAD}
          >
            <ListItemText primary="Content" className={styles['link-text']} />
          </ListItem>
        </TreeDropDownX>
      </List>
    </Box>
  );
}

export default Navbar;
