import { ColumnPropType } from "../../components/organisms/TableManager/TableManager"
import { CategoryMasterDataHash } from "../../slices/categoryMasterCatalogueSlice";


export const categoryMasterCatalogueColumns = ():ColumnPropType<CategoryMasterDataHash>[] => [
  {
    label: 'Name',
    accessor: 'name',
    minWidth: 300
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 250,
    sortable: true,
  }
]
