import React from "react";
import {
  Box,
  TextField
} from "@mui/material";
import styles from "./BasicDetails.module.scss";
import WithLabel from "components/atoms/WithLabel/WithLabel";
import InputX from "components/atoms/InputX/InputX";
import { useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import { useDispatch } from 'react-redux';
import { updateTitle, updateDescription, updateHandle } from "slices/collectionFormSlice";

type BasicDetailsPropType = {
  validationErrors: Record<string, string>;
  action: 'create' | 'edit';
};

const BasicDetails: React.FC<BasicDetailsPropType> = ({validationErrors, action}) => {

  const collectionFormData = useAppSelector((state: RootState) => state.collectionForm.collectionForm);

  const dispatch = useDispatch();

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title = event.target.value;
    const handle = title.toLowerCase().replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '-').trim();
    dispatch(updateTitle(title));
    if(action === 'create')
      dispatch(updateHandle(handle));
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateDescription(event.target.value));
  };

  return (
    <Box className={styles.BasicDetails}>
      <InputX
        label={"Title"}
        placeholder="Title"
        required={true}
        error={validationErrors.title}
        value={collectionFormData?.title} 
        onChange={handleChangeTitle}
      />

      <InputX
        label={"Description"}
        placeholder="Description"
        required={false}
        multiline
        rows={4}
        error={validationErrors.description}
        value={collectionFormData?.description}
        onChange={handleChangeDescription}
      />

      {/* <WithLabel label="Description" labelPosition="top">
        <TextField
          fullWidth
          className={styles.TextField}
          hiddenLabel
          id="filled-hidden-label-small"
          size="small"
          placeholder="Description"
          multiline
          minRows={3}
          maxRows={20}
          // value="Sample Description"
        />
      </WithLabel> */}
    </Box>
  );
};

export default BasicDetails;
