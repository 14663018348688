import { Box, Radio } from "@mui/material";
import styles from "./NamedToggle.module.scss";
import { csx } from "helpers/utils";

type PropType = {
  value: boolean;
  falseLabel: string;
  falseLabelDescription: string;
  trueLabel: string;
  trueLabelDescription: string;
  onChange: (value: string) => void;
}

const NamedToggle: React.FC<PropType> = ({value, falseLabel, falseLabelDescription, trueLabel, trueLabelDescription, onChange}) => {

  return (
    <Box className={styles.toggle}>
      <Box className={csx( styles['true-label'], value ? styles['selected']: '')} onClick={() => onChange('smart_collection')}>
        
        <Box className={styles.toggleElement}>
          <Radio
            checked={value}
            value="a"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
            className={styles.customRadio} 
          />
          <Box>
            <p className={styles['label']}>{trueLabel}</p>
            <p className={styles['description']}>{trueLabelDescription}</p>
          </Box>
        </Box>
        
      </Box>
      <Box className={csx(styles['false-label'], !value ? styles['selected']: '')} onClick={() => onChange('custom_collection')}>
        
        <Box className={styles.toggleElement}>
          <Radio
            checked={!value}
            value="a"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
            className={styles.customRadio} 
          />
          <Box>
            <p className={styles['label']}>{falseLabel}</p>
            <p className={styles['description']}>{falseLabelDescription}</p>
          </Box>
        </Box>
        
      </Box>
    </Box>
  );
}

export default NamedToggle;