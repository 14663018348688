import styles from "./EditProductVariant.module.scss";
import { Box, Divider } from "@mui/material";
import Img from "components/atoms/Img/Img";
import { csx } from "helpers/utils";
import { useMemo } from "react";
import { ProductDetailsData } from "slices/productFormSlice";


type VariantTypeViewProps = {
  productData: ProductDetailsData
  selectedVariant: string | number;
  onVariantTypeChange: (variant: string | number) => void;
}

const VariantTypeView: React.FC<VariantTypeViewProps> = ({ productData, selectedVariant, onVariantTypeChange }) => {

  const variantOptions = useMemo(() => {
    return productData.product.variants.filter((variant) => !!variant.marketplace_unique_id).map((variant) => ({name: variant.variantName, id: variant.marketplace_unique_id}));
  }, [productData.product.variants]);
  return (
    <Box className={styles.variantView}>
      <Img src={productData.product.primary_images[0]?.src} alt={'Product'} className={styles.variantImg} />
      <p className={styles.nameTxt}>{productData.product.title}</p>
      <Box className={styles.variantTypes}>
        <p className={styles.variantTitle}>
          Variant Type: <span>{productData.product.variant_type}</span>
        </p>
        <Divider sx={{ my: 1.5 }} />
        <Box className={styles.tabsWrapper}>
          {variantOptions.map((variant) => (
            <Box
              key={variant.id}
              onClick={() => onVariantTypeChange(variant.id)}
              className={csx(styles.tabItem, variant.id === selectedVariant ? styles.selected : null)}
            >
              {variant.name}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
export default VariantTypeView;