import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './MasterCatalogue.module.scss';
import { setSort, CategoryMasterCatalogueApi, CategoryMasterDataHash } from "../../slices/categoryMasterCatalogueSlice";
import Loader from "../../components/atoms/Loader/Loader";
import { useNavigate} from "react-router-dom";

import { 
  resetFilter,
  setCategoryMasterCatalogueNameFilter,
  setCategoryMasterCatalogueCreatedAtStartDateFilter,
  setCategoryMasterCatalogueCreatedAtEndDateFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import { categoryMasterCatalogueColumns } from "./CategoryMasterCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "../../components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "../../components/molecules/SearchByFilter/SearchByFilter";

import { DateRangeType, ROUTES } from "../../constants";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";

const searchByOptions = [
  {id: 'name', label: 'Name'},
]

const CategoryMasterCatalogue = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // Using theme to check the device is mobile or not
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Creating state to store loader status, filter applied and filter open
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  // Check whether the logged user is fbv user or not
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  
  // Get the collection data from store.
  const categoryMasterCatalogueData = useAppSelector((state: RootState) => state.categoryMasterCatalogue);
  const categoryMasterCatalogueNameFilter = useAppSelector((state: RootState) => state.filters.categoryMasterCatalogueNameFilter);
  const categoryMasterCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.categoryMasterCatalogueCreatedAtStartDateFilter);
  const categoryMasterCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.categoryMasterCatalogueCreatedAtEndDateFilter);

  let tableData:CategoryMasterDataHash[] = []

  const handleSort = (column: keyof CategoryMasterDataHash) => {
    const direction = column === categoryMasterCatalogueData.sortedColumn && categoryMasterCatalogueData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };


  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(CategoryMasterCatalogueApi({
        categoryMasterCatalogueNameFilter,
        categoryMasterCatalogueCreatedAtStartDateFilter,
        categoryMasterCatalogueCreatedAtEndDateFilter,
        headers,
      })).unwrap();
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  useEffect(() => {
    if(filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setFilterApplied(true);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!categoryMasterCatalogueNameFilter
      ? [
        {
          id: "name",
          label: "Name",
          value: categoryMasterCatalogueNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
  ];


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'name': {
        dispatch(setCategoryMasterCatalogueNameFilter(value as string)); break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setCategoryMasterCatalogueNameFilter(''));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setCategoryMasterCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setCategoryMasterCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Category Master">   
        <button
          className={styles.primaryBtn}
          onClick={() => {
            window.location.href = '/masters/categories/new';
          }}
        >
          Create Category
        </button>
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <DateRangeLabel
            noDateLabel={'Created At'}
            startDate={categoryMasterCatalogueCreatedAtStartDateFilter}
            endDate={categoryMasterCatalogueCreatedAtEndDateFilter}
            onClick={() => {setShowDateRangeFilter(true)}}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />


      {!loaderActive && (
        <TableManager<CategoryMasterDataHash>
          data={categoryMasterCatalogueData?.categoryMasterCatalogueData?.categories || []}
          columns={categoryMasterCatalogueColumns()}
          sortedColumn={categoryMasterCatalogueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={categoryMasterCatalogueData.sortDirection}
          showPagination
          totalCount={categoryMasterCatalogueData?.categoryMasterCatalogueData?.categories.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={categoryMasterCatalogueCreatedAtStartDateFilter}
        endDate={categoryMasterCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default CategoryMasterCatalogue;
