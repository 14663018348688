import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError } from "axios";
import { setToken } from "./resetPasswordSlice";


type ForgotPasswordApiState = {
  otpValidationToken: string | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

type ErrorResponse = {
  errors: string;
};

const initialState: ForgotPasswordApiState = {
  otpValidationToken: null,
  status: "idle",
  error: null,
};

type RequestOtpPayload = {
  email: string
}
type ValidateOtpPayload = {
  secret_key: string,
  current_otp: string,
}

export const generateResetOtp = createAsyncThunk(
  "generateResetOtp",
  async (data: RequestOtpPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("solomon/generate_reset_otp", data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const validateResetOtp = createAsyncThunk(
  "validateResetOtp",
  async (data: ValidateOtpPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("solomon/verify_reset_otp", data);
      const reset_password_token = response.data.secret_key as string;
      dispatch(setToken(reset_password_token));
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    clearState: (state) => {
      state.otpValidationToken = initialState.otpValidationToken;
      state.error = initialState.error;
      state.status = initialState.status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateResetOtp.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(generateResetOtp.fulfilled, (state, action) => {
          state.status = "idle";
          state.otpValidationToken = action.payload.secret_key as string;
      })
      .addCase(generateResetOtp.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            (action.payload as ErrorResponse).errors || "Failed to generate OTP";
        } else {
          state.error = action.error.message || "Failed to generate OTP";
        }
      })
      .addCase(validateResetOtp.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(validateResetOtp.fulfilled, (state, action) => {
          state.status = "idle";
      })
      .addCase(validateResetOtp.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            (action.payload as ErrorResponse).errors || "Failed to validate OTP";
        } else {
          state.error = action.error.message || "Failed to validate OTP";
        }
      });
  },
});

export const {clearState} = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;