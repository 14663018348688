import {
  Container,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import SolomonDesktopIcon from "../../images/solomon_desktop.png"
import heroBanner from "../../images/hero_banner_1.png"
import Footer from "../../components/atoms/Footer/Footer";
import { RootState } from "../../store";
import { Navigate } from "react-router-dom";
import { reset , clearState} from "../../slices/resetPasswordSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./Auth.module.scss";
import forgeDesktopBanner from "../../images/forge_by_vaaree_desktop.png"

type InputErrors = {
  password: string,
  confirmPassword: string,
}


const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  }
});

const ResetPassword = () => {
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<InputErrors>({password: '', confirmPassword: ''});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const resetPasswordData = useAppSelector((state: RootState) => state.resetPassword);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    }
  }, []);

  if(!resetPasswordData.resetPasswordToken){
    return <Navigate replace to={'/login'} />
  }

  const handleResetPassword = async () => {
    if(!password.trim()){
      setErrors({password: "Password cannot be empty", confirmPassword: ''});
      return;
    }
    if(password !== confirmPassword){
      setErrors({password: '', confirmPassword: "Password doesn't match"});
      return;
    }
    dispatch(reset({secret_key: resetPasswordData.resetPasswordToken || '', new_password: password}));
  };

  const updatePassword = (e: any) => {
    setPassword(e.target.value);
    setErrors({password: '', confirmPassword: ''});
  }
  
  const updateConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
    setErrors({password: '', confirmPassword: ''});
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseUpDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  return (
    <Box className={styles.authWrapper}>
      <Box className={styles.mainContent}>
        <Box className={styles.bannerSection}>
          <img className={styles.headerBanner} src={SolomonDesktopIcon} alt="Solomon desktop banner" />
          <img className={styles.heroBanner} src={heroBanner} alt="Solomon login hero banner" />
        </Box>
        <Box className={styles.inputSection}>
          <Box className={styles.inputForm}>
            <Typography className={styles.heading} sx={{mb: 2}}>Reset Password</Typography>
            <FormControl className={styles.txtInput} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Enter New Password *</InputLabel>
              <OutlinedInput
                error={!!errors.password}
                required
                id="outlined-adornment-password"
                autoFocus
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={updatePassword}
                disabled={resetPasswordData.status === 'loading'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseUpDownPassword}
                      onMouseUp={handleMouseUpDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter New Password *"
              />
              {!!errors.password && (
                <FormHelperText error id="password-error">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={styles.txtInput} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password *</InputLabel>
              <OutlinedInput
                error={!!errors.confirmPassword}
                required
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={updateConfirmPassword}
                disabled={resetPasswordData.status === 'loading'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseUpDownPassword}
                      onMouseUp={handleMouseUpDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password *"
              />
              {!!errors.confirmPassword && (
                <FormHelperText error id="confirm-password-error">
                  {errors.confirmPassword}
                </FormHelperText>
              )}
            </FormControl>
            <Button

              variant="contained"
              className={styles.actionBtn}
              onClick={handleResetPassword}
              disabled={resetPasswordData.status === 'loading'}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ResetPassword;
