import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

export interface CategoryFormData {
  value: string | null;
}

interface CategoryFormState {
  loading: boolean;
  error: string | null;
}

const initialState: CategoryFormState = {
  loading: false,
  error: null,
};

const fetchApiData = async (url: string, method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", headers: any, requestData: any = {}, rejectWithValue: any) => {
  const config: AxiosRequestConfig = {
    headers: headers || {},
    method,
    url,
    data: requestData,
  };
  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 401) {
      return rejectWithValue({ errors: "Unauthorized! Login again" });
    }
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data);
    }
    throw error;
  }
};

export const createCategory = createAsyncThunk("categoryForm/createCategory", async ({ headers, formData }: any, { rejectWithValue }) => {
  const url = "/solomon/masters/create_category";
  return await fetchApiData(url, "POST", headers, formData, rejectWithValue);
});

const categoryFormSlice = createSlice({
  name: "categoryForm",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
  },
});

export const { resetForm } = categoryFormSlice.actions;
export default categoryFormSlice.reducer;
