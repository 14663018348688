import ModalBox from "components/atoms/ModalBox/ModalBox";
import styles from "./ImgSelector.module.scss";
import { Box, Checkbox } from "@mui/material";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import Img from "components/atoms/Img/Img";
import { useMemo, useRef, useState } from "react";
import { csx } from "helpers/utils";
import { addAvailableImages } from "slices/collectionFormSlice";
import { v4 as uuidv4 } from 'uuid';


export type BannerImage = {
  id: string | number;
  image_url: string | File;
  display_name : string | null;
}

type ImgSelectorPropType = {
  multiple: boolean;
  onSelect: (ids: (string | number)[]) => void;
  onClose: () => void;
  open: boolean;
}

const ImgSelector:React.FC<ImgSelectorPropType> = (props) => {

  const dispatch = useAppDispatch();

  const availableImages = useAppSelector((state: RootState) => state.collectionForm.mediaImageData) || [];
  const [selectedImage, setSelectedImages] = useState<Set<number|string>>(new Set());

  const {open, multiple, onSelect, onClose} = props;
  const uploadRef = useRef<HTMLInputElement|null>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const files = e.target.files;
      const mediaImages:BannerImage[] = [];
      for(let i = 0 ; i < files.length ; i += 1){
        mediaImages.push({
          id: uuidv4(),
          image_url: files[i],
          display_name: files[i].name,
        })
      }
      dispatch(addAvailableImages(mediaImages));
    }
    e.target.value = "";
  }

  const uploadFile = () => {
    if(uploadRef.current){
      uploadRef.current.click();
    }
  }

  const selectImage = (id: string | number) => {
    if(multiple){
      if(selectedImage.has(id)){
        setSelectedImages(new Set([...selectedImage].filter((ith) => ith !== id)));
      }else{
        setSelectedImages(new Set([...selectedImage, id]));
      }
    }else{
      if(selectedImage.has(id)){
        setSelectedImages(new Set());
      }else{
        setSelectedImages(new Set([id]));
      }
    }
  }

  const handleSelection = () => {
    onSelect([...selectedImage]);
    onClose();
  }

  return (
    <ModalBox 
      open={open}
      onClose={onClose}
      className={styles.wrapper}
    >
      <>
        <Box className={styles.heading}>
          <p>Select File</p>
          <ButtonX onClick={uploadFile}><FileUploadOutlinedIcon /> Upload</ButtonX>
        </Box>
        <Box className={styles.imageOptions}>
          {availableImages.map((image) => {
            return (
              <ImgCard
                selected={selectedImage.has(image.id)}
                image={image}
                onSelect={() => selectImage(image.id)}
              />
            )
          })}
          {Array(8).fill(null).map(() => <Box sx={{width: 124}} />)} 
        </Box>
        <Box className={styles.footer}>
          <ButtonX color="#ffffff" size="small" onClick={onClose}>Cancel</ButtonX>
          <ButtonX disabled={!selectedImage.size } size="small" onClick={handleSelection}>Done</ButtonX>
        </Box>
        <input ref={uploadRef}
          type="file"
          multiple={true}
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          accept={'image/*'}
        />
      </>
    </ModalBox>
  );
}

export default ImgSelector;

type ImgCardPropType = {
  selected: boolean;
  image: BannerImage;
  onSelect: () => void;
}

const ImgCard:React.FC<ImgCardPropType> = (props) => {
  const {selected, image, onSelect} = props;

  const src = useMemo(() => {
    if(!image.image_url) return undefined;
    if(typeof image.image_url === 'string') return  image.image_url;
    return URL.createObjectURL(image.image_url)
  }, [image.image_url]);

  const altTxt = useMemo(() => {
    let txt = image.display_name || 'Image';
    if(txt.length > 56) txt = txt.substring(0, 56) + '...';
    return txt;
  }, [image.display_name]);

  return (
    <Box className={styles.imgCard} onClick={(e) => {
      e.stopPropagation();
      onSelect();
    }}>
      <Box className={csx(styles.imgBox,selected ? styles.selected: '')}>
        <Checkbox checked={selected} className={styles.checkbox} size="small" />
        <Img src={src} alt={altTxt} />
      </Box>
      <p>{altTxt}</p>
    </Box>
  );
}