import { ProductVariantFormData } from "slices/productFormSlice";
import styles from "./EditProductVariant.module.scss";
import { Box } from "@mui/material";
import InputX from "components/atoms/InputX/InputX";

type VariantNameEditProps = {
  variantName: string;
  onVariantNameChange: (field: keyof ProductVariantFormData, value: any) => void;
}

const VariantNameEdit: React.FC<VariantNameEditProps> = ({ variantName, onVariantNameChange }) => {
  return (
    <Box className={styles.formSection}>
      <p className={styles.formTitle}>Variant</p>
      <InputX value={variantName} placeholder="Type" label="Name"
        onChange={(e) => {
          onVariantNameChange("variantName", e.target.value)
        }} />
    </Box>
  );
}

export default VariantNameEdit;
