import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from '@mui/material/Button';

interface ColorButtonPropType extends ButtonProps{
  bgcolor : string;
  bghovercolor?: string;
}

const ColorButton = styled(Button)<ColorButtonPropType>(({ theme, bgcolor, bghovercolor}) => ({
  color: theme.palette.getContrastText(bgcolor),
  backgroundColor: bgcolor,
  textTransform: 'none',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: bghovercolor || bgcolor,
  },
}));

export default ColorButton;