import { Box } from "@mui/material";
import styles from "./SyncStatus.module.scss";
import { csx } from "helpers/utils";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ClickTooltip from "../ClickTooltip/ClickTooltip";
import { useState } from "react";

export type SyncStatus = 'pending' | 'synced' | 'failed';

export type PropType = {
  lastSyncedAt?: string | null;
  syncStatus: SyncStatus | null
  syncError?: string | null;
  className?: string;
}

export const STATUS_DESCRIPTION_MAP: Record<SyncStatus, string> = {
  pending : 'In Progress',
  synced: 'Synced Successfully',
  failed: 'Sync Failed',
}

const SyncStatus:React.FC<PropType> = (props) => {
  const {lastSyncedAt, syncError, syncStatus, className} = props;
  const [showError, setShowError] = useState(false);
  
  if(!syncStatus)
    return null;

  return (
    <Box className={csx(styles.container, className)}>
      <p className={csx(styles.status, styles[syncStatus])}>
        {STATUS_DESCRIPTION_MAP[syncStatus]}
        {syncStatus === 'failed' && syncError && (
          <ClickTooltip
          title={ <p className={styles.errorMsg}>{syncError}</p>}
          show={showError}
          className={styles.popupStyle}
          showPopper={setShowError}
          placement={'bottom'}
          arrow
        >
          <ErrorOutlineOutlinedIcon className={styles.errorIcon} onClick={() => setShowError(true)} />
          </ClickTooltip>
        )}
      </p>
      {lastSyncedAt && (
        <p className={styles.syncAt}>
          {lastSyncedAt}
        </p>
      )}
    </Box>
  )
}

export default SyncStatus