import { Accordion, AccordionDetails, AccordionSummary, Box, } from "@mui/material";
import { ColumnPropType } from "../../components/organisms/TableManager/TableManager";
import styles from "./ProductCatalogue.module.scss";
import { ProductDataHash } from "../../slices/productCatalogueSlice";
import { checkCurrencyNA, checkNA, csx } from "../../helpers/utils";
import FbvIcon from "../../images/fbv-icon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Img from "../../components/atoms/Img/Img";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../src/constants"

type LabelValueBlockPropType = {
  label: string;
  value: string | null | number | undefined;
  statusLabel?: boolean;
};


const LabelValueBlock: React.FC<LabelValueBlockPropType> = (props) => {
  return (
    <p className={styles.label}>
      {`${props.label}:`}{" "}
      <span
        className={csx(
          styles.value,
          props.statusLabel ? styles.statusLabel : null
        )}
      >
        {checkNA(props.value)}
      </span>
    </p>
  );
};

type CardColumnPropType = {
  data: LabelValueBlockPropType[];
  className?: string;
};

const CardColumn: React.FC<CardColumnPropType> = (props) => {
  const { data, className = undefined } = props;
  return (
    <Box className={csx(styles.cardColumn, className)}>
      {data.map((item, idx) => {
        return (
          <LabelValueBlock
            key={idx}
            label={item.label}
            value={item.value}
            statusLabel={item.statusLabel}
          />
        );
      })}
    </Box>
  );
};

const CardView: React.FC<ProductDataHash> = (props) => {
  const navigate = useNavigate(); // Hook for navigation
  const { image_src, title, sku, fbv_enabled, variant_title, created_at, updated_at, category, sub_category, vin, price, compare_at_price, inventory_quantity, status, vendor, updated_by, id, product_id } = props;

  const handleViewDetails = () => {
    navigate(ROUTES.EDIT_PRODUCT.replace(':id', String(product_id)));
  };

  return (
    <Box className={styles.productCard}>
      <Box className={styles.thumbnailSection}>
        <Img className={styles.thumbnail} src={image_src || undefined} alt={title || 'Product Default Title'} />
        <Box className={styles.thumbnailData}>
          <p className={styles.skuCode}>
            {checkNA(sku)} {fbv_enabled && <img className={styles.fbvIcon} src={FbvIcon} alt="FBV Icon" />}
          </p>
          <p className={styles.label}>{checkNA(title)}</p>
          <LabelValueBlock label="Variant" value={variant_title} />
        </Box>
      </Box>
      <CardColumn
        data={[
          { label: "Category", value: category },
          { label: "Sub-Category", value: sub_category },
          { label: "Vendor Name", value: vendor },
          { label: "VIN", value: vin },
        ]}
      />
      <CardColumn
        data={[
          { label: "Listing Price", value: checkCurrencyNA(price) },
          {
            label: "Maximum Retail Price",
            value: checkCurrencyNA(compare_at_price),
          },
          { label: "Stock on Hand", value: inventory_quantity },
        ]}
      />
      <CardColumn
        data={[
          { label: "Status", value: status },
          { label: "Product Created", value: created_at },
          { label: "Last Updated at", value: updated_at },
          { label: "Last Updated by", value: updated_by },
        ]}
      />
      <Box className={styles.buttonColumn}>
        <ButtonX variant="outlined" color="#000" onClick={handleViewDetails}>
          View Details
        </ButtonX>
      </Box>
    </Box>
  );
};
type CardGridPropType = {
  data: LabelValueBlockPropType[];
  className?: string;
};

const CardGrid: React.FC<CardGridPropType> = (props) => {
  const { data, className = undefined } = props;
  return (
    <Box className={csx(styles.cardGrid, className)}>
      {data.map((item) => {
        return (
          <>
            <p className={styles.label}>{`${item.label}`}</p>
            <p
              className={csx(
                styles.value,
                item.statusLabel ? styles.statusLabel : null
              )}
            >
              {checkNA(item.value)}
            </p>
          </>
        );
      })}
    </Box>
  );
};

const ExpandCardView: React.FC<ProductDataHash> = (props) => {
  const {
    id,
    image_src,
    sku,
    fbv_enabled,
    title,
    variant_title,
    status,
    category,
    sub_category,
    vin,
    inventory_quantity,
    price,
    compare_at_price,
  } = props;
  return (
    <Box className={styles.expandProductCard} key={`${id}`}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
          <Box className={csx(styles.thumbnailSection, styles.mobile)}>
            <Img
              className={styles.thumbnail}
              src={image_src || undefined}
              alt={title || "Product Default Title"}
            />
            <Box className={styles.thumbnailData}>
              <p className={styles.skuCode}>
                {checkNA(sku)}{" "}
                {fbv_enabled && (
                  <img
                    className={styles.fbvIcon}
                    src={FbvIcon}
                    alt="FBV Icon"
                  />
                )}
              </p>
              <p className={styles.label}>{checkNA(title)}</p>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CardGrid
            data={[
              { label: "Status", value: status },
              { label: "Variant", value: variant_title },
              { label: "Category", value: category },
              { label: "Sub-Category", value: sub_category },
              { label: "VIN", value: vin },
              { label: "Listing Price", value: checkCurrencyNA(price) },
              {
                label: "Maximum Retail Price",
                value: checkCurrencyNA(compare_at_price),
              },
              { label: "Stock on Hand", value: inventory_quantity },
            ]}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const productCatalogueColumns = (
  isMobile: boolean
): ColumnPropType<ProductDataHash>[] => [
    {
      label: "Product ID",
      accessor: "id",
      minWidth: isMobile ? undefined : 1200,
      cell: ({ row }) =>
        isMobile ? <ExpandCardView {...row} /> : <CardView {...row} />,
    },
  ];


export const BULK_PRODUCT_UPLOAD_HEADER = ['Variant sku', 'Variant name', 'Variant type', 'Variant cost price', 'Variant listing price',
  'Variant mrp', 'Product title', 'Product description', 'Set of', 'Category', 'Sub category','Product type', 'Tags', 'Vendor facility code',
  'GSM', 'Thread Count', 'Finish Type', 'Color', 'Return Policy 1 Liner', 'Material'
]