import React from "react";
import { Box, Button } from "@mui/material";
import styles from "./CollectionFormHeader.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import SyncStatus from "components/atoms/SyncStatus/SyncStatus";
import { useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";

type CollectionFormHeaderProps = {
  label: string;
  onDiscard: () => void;
  onSave: () => void;
  stateUpdated: boolean;
  action: string;
};

const CollectionFormHeader: React.FC<CollectionFormHeaderProps> = ({
  label,
  onDiscard,
  onSave,
  stateUpdated,
  action
}) => {

  const collectionFormData = useAppSelector((state: RootState) => state.collectionForm.collectionForm);

  
  return (
    <Box className={styles.collectionHeader}>
      <MainHeader label={label} allowBack={true}>
        <Box sx={{display: 'flex', gap: '8px'}}>
          {action === 'edit' && (
            <SyncStatus 
              syncStatus={collectionFormData?.shopify_sync_status}
              syncError={collectionFormData?.shopify_sync_error}
              lastSyncedAt={collectionFormData?.last_shopify_synced_at}
            />
          )}
          <ButtonX
            color="#000"
            variant="outlined"
            className={styles.outlinedBtn}
            onClick={onDiscard}
            disabled={!stateUpdated}
          >
            Discard
          </ButtonX>

          <ButtonX
            variant="contained"
            className={styles.containedBtn}
            onClick={onSave}
          >
            Save
          </ButtonX>
        </Box>
      </MainHeader>
    </Box>
  );
};

export default CollectionFormHeader;
