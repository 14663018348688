import { Box } from "@mui/material";
import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import { CollectionDataHash } from "../../slices/collectionCatalogueSlice";
import styles from "./CollectionCatalogue.module.scss";
import Img from "components/atoms/Img/Img";


export const collectionCatalogueColumns = ():ColumnPropType<CollectionDataHash>[] => [
  {
    label: 'Collection Name',
    accessor: 'name',
    minWidth: 300,
    cell: ({row}) => {
      const {image, name} = row;
      return (
        <Box className={styles.collectionThumbnail}>
          <Img src={image || undefined } alt={'Collection Image'} /> 
          <p>{name}</p>
        </Box>
      );
    }
  },
  {
    label: 'Collection Type',
    accessor: 'type',
    minWidth: 150,
  },
  {
    label: 'No of Products',
    accessor: 'collection_products',
    minWidth: 150,
    sortable: true,
  },
  {
    label: 'Rules',
    accessor: 'unique_key',
    minWidth: 250,
    cell: ({row: {rules}}) => {
      return (
        <Box className={styles.ruleList}>
          {rules.slice(0, 3).map((rule) => {
            return <p>{rule}</p>;
          })}
          {!!rules.slice(3).length && <p className={styles.extraRule}>... and {rules.slice(3).length} more</p>}
        </Box>
      );
    }
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 250,
    sortable: true,
  },
  {
    label: 'Updated At',
    accessor: 'updated_at',
    minWidth: 250,
    sortable: true,
  },
  {
    label: 'Updated By',
    accessor: 'updated_by',
    minWidth: 250,
  }
]