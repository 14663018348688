import { Box, Modal } from "@mui/material";
import styles from "./ModalBox.module.scss";
import { csx } from "../../../helpers/utils";

type PropType = {
  open: boolean;
  onClose: () => void;
  children?: JSX.Element | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ModalBox:React.FC<PropType> = ({children, open, onClose, className, style}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={csx(styles["modal-box"], className)} sx={{...style}}>
        {children}
      </Box>
    </Modal>
  )
}

export default ModalBox;