import { Box } from "@mui/material";
import styles from "./ImageSelector.module.scss";
import { useCallback, useRef } from "react";
import default_thumbnail from "images/default_thumbnail.png"
import upload_more from "images/upload_more.png"
import update from "immutability-helper";
import DragCard from "../DragCard/DragCard";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export type ProductImageType = {
  id: number,
  src: string
}

type ImageSelectorPropType = {
  selectedImages: (File | ProductImageType)[];
  onUpdateImage: (images: (File | ProductImageType)[]) => void;
  onRemoveSavedImage: (id: number) => void;
  onRemoveSaveAdditionalImage: (id: number) => void;
  additionalImage: (File | ProductImageType | undefined);
  onUpdateAdditionalImage: (image: (File | ProductImageType | undefined)) => void;
}

const ImageSelector: React.FC<ImageSelectorPropType> = (props) => {
  const { selectedImages, onUpdateImage, additionalImage, onUpdateAdditionalImage, onRemoveSavedImage, onRemoveSaveAdditionalImage} = props;
  const mainImage = selectedImages[0] || undefined;
  const otherImages = selectedImages.slice(1);
  const imgInputRef = useRef<HTMLInputElement>(null);
  const additionalImgInputRef = useRef<HTMLInputElement>(null);

  const removeImage = (ith: number) => {
    const updatedImages = selectedImages.filter((_, idx) => idx !== ith);
    if(isSavedImage(selectedImages[ith])){
      onRemoveSavedImage((selectedImages[ith] as ProductImageType).id )
    }
    onUpdateImage(updatedImages);
  }

  const removeAdditionalImg = () => {
    if(isSavedImage(additionalImage as (File | ProductImageType))){
      onRemoveSaveAdditionalImage((additionalImage as ProductImageType).id)
    }
    onUpdateAdditionalImage(undefined);
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onUpdateImage([...selectedImages, ...e.target.files]);
    }
    e.target.value = "";
  }

  const handleAdditionalImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      onUpdateAdditionalImage(e.target.files?.[0]);
    }
    e.target.value = "";
  }

  const isSavedImage = (image: File | ProductImageType | string) => {
    return !(image instanceof File) && (typeof image !== 'string')
  }


  const moveCard = useCallback(
    (dragIndex:number, hoverIndex:number) => {
      const dragCard = selectedImages[dragIndex];
      onUpdateImage(
        update(selectedImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [selectedImages]
  );

  return (
    <Box className={styles.wrapper}>
      <ImageBox
        className={styles.mainImgBox}
        image={mainImage ? mainImage : default_thumbnail}
        label={'Main Image'}
        onUpload={!mainImage ? () => {
          imgInputRef?.current?.click();
        } : undefined}
        onRemove={mainImage ? () => {
          removeImage(0);
        } : undefined}
        index={0}
        moveCard={moveCard}
      />
      {otherImages.map((img, idx) =>
        <ImageBox
          index={idx + 1}
          className={styles.otherImgBox}
          image={img}
          label={`${idx + 2}`}
          onRemove={() => { removeImage(idx + 1) }}
          moveCard={moveCard}
        />
      )}
      <ImageBox
        className={styles.otherImgBox}
        image={additionalImage ? additionalImage : default_thumbnail}
        label={'Size Chart'}
        onUpload={!additionalImage ? () => {
          additionalImgInputRef?.current?.click();
        } : undefined}
        onRemove={additionalImage ? () => {
          removeAdditionalImg();
        } : undefined}
        index={-1}
      />
      {!!selectedImages.length && (
        <ImageBox
          className={styles.otherImgBox}
          image={upload_more}
          onUpload={() => {
            imgInputRef?.current?.click();
          }}
          index={-1}
        />
      )}
      <input ref={imgInputRef}
        type="file"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        multiple
        accept={"image/png, image/jpeg, image/jpg"}
      />
      <input ref={additionalImgInputRef}
        type="file"
        onChange={handleAdditionalImageUpload}
        style={{ display: 'none' }}
        accept={"image/png, image/jpeg, image/jpg"}
      />
    </Box>
  )
}

export default ImageSelector;

type ImageBoxPropType = {
  className: string;
  label?: string;
  image: ProductImageType | File | string;
  onRemove?: () => void;
  onUpload?: () => void;
  index: number;
  moveCard?: (dragIndex:number, hoverIndex:number) => void;
}

const ImageBox: React.FC<ImageBoxPropType> = (props) => {
  const { className, label, image, onRemove, onUpload, index, moveCard} = props;
  const imgSrc = image instanceof File ? URL.createObjectURL(image) : (typeof image === 'string' ? image : image.src);
  const draggerRef = useRef<HTMLDivElement>(null);

  if(index === -1){
    return (
      <Box className={className}>
        <img src={imgSrc} alt={label} className={onUpload && styles.uploadBtn} onClick={onUpload} />
        {label && <p className={styles.label}>{label}</p>}
        {onRemove && (
          <Box className={styles.removeBtn} onClick={onRemove}>Remove</Box>
        )}
      </Box>
    );
  }

  return (
    <DragCard 
      index={index}
      key={index}
      id={index}
      moveCard={moveCard!}
      cardItemType={'PRODUCT_IMG_CARD'}
      className={index === 0 ? styles.mainImgBox: undefined}
      dragRef={draggerRef}
    >
      <Box className={className}>
        <img src={imgSrc} alt={label} className={onUpload && styles.uploadBtn} onClick={onUpload} />
        {label && <p className={styles.label}>{label}</p>}
        {onRemove && (
          <Box className={styles.removeBtn} onClick={onRemove}>Remove</Box>
        )}
        <Box ref={draggerRef} className={styles.dragIcon}>
          <DragIndicatorIcon fontSize="small" />
        </Box>
      </Box>
    </DragCard>
  );
}