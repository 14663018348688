import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Button, TextField,  Tab,  Tabs,  useMediaQuery, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import { RootState } from '../../store';
import styles from './CollectionForm.module.scss';
import { resetAuth } from "../../slices/authSlice";
import CollectionFormHeader from "./components/CollectionFormHeader/CollectionFormHeader";
import WithLabel from "components/atoms/WithLabel/WithLabel";
import BasicDetails from "./components/BasicDetails/BasicDetails";
import CollectionType from "./components/CollectionType/CollectionType";
import CollectionTab from "./components/CollectionTab/CollectionTab";
import {
  CollectionFormApi, updateThumbnail, updateThumbnailAltText, CollectionSaveApi,
  updateBanners,
  getCollectionBannerMetaobjects,
  getLeadGenBannerMetaobjects,
  getMediaImages,
  addBannerOptions,
  updateEventBanner
} from "../../slices/collectionFormSlice";
import {
  resetFilter
} from "../../slices/filtersSlice";
import CollectionThumbnail from "./components/CollectionThumbnail/CollectionThumbnail";
import BannerDetails from "./components/BannerDetails/BannerDetails";
import { updateCollectionProducts } from "slices/collectionProductsSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/atoms/Loader/Loader";
import { ROUTES } from "../../constants";
import { NotificationType, showNotification } from "slices/notificationSlice";
import ImageSelector from "components/organisms/ImageSelector/ImageSelector";
import ImgSelector from "components/molecules/ImgSelector/ImgSelector";
import SingleBannerDetails from "./components/BannerDetails/SingleBannerDetails";

const CollectionForm: React.FC = () => {

  const navigate = useNavigate(); // Hook for navigation

  const { id } = useParams<{ id?: string }>();
  const action = (id == null ? 'create' : 'edit')
  const label = (action == 'create' ? 'Create New Collection' : 'Edit Collection')

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [refreshData, setRefreshData] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  // Get the collection data from store.
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  const collectionForm = useAppSelector((state: RootState) => state.collectionForm);
  const collectionFormData = useAppSelector((state: RootState) => state.collectionForm.collectionForm);
  const prevCollectionFormDateState = useAppSelector((state: RootState) => state.collectionForm.initialCollectionFormState);

  const stateUpdated = useMemo(() => {
    return JSON.stringify(collectionFormData) !== JSON.stringify(prevCollectionFormDateState);
  }, [collectionFormData, prevCollectionFormDateState]);

  const [mobileBannerImages, setMobileBannerImages] = useState<(File|string)[]>([]);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [webBannerImages, setWebBannerImages] = useState<(File|string)[]>([]);

  const collectionProducts = useAppSelector((state: RootState) => state.collectionProducts.productsData?.data) || [];

  const handleDiscard = () => {
    window.location.reload();
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!collectionFormData.title) {
      errors.title = 'Title is required';
    }

    if(collectionForm.collectionForm.collection_type === 'smart_collection'){
      let invalidRule = collectionFormData.rules.find((rule) => rule.condition === '')
      if(invalidRule){
        dispatch(showNotification({
          message: 'Smart collection should have conditions with valid value',
          type: NotificationType.Error
        }))
        return false;
      }
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const addBannerToFormData = (formData: FormData) => {
    const selectedCollectionBanners = collectionForm.collectionBannerMetaobjectsData.filter((banner) => collectionFormData.collectionBanners.includes(banner.id));
    selectedCollectionBanners.forEach((banner , idx) => {
      formData.append(`collectionBanners[${idx}]`, banner.image_url);
    });
    const selectedLeadGenBanners = collectionForm.leadGenBannerMetaobjectsData.filter((banner) => collectionFormData.leadGenerationBanners.includes(banner.id));
    selectedLeadGenBanners.forEach((banner , idx) => {
      formData.append(`leadGenBanners[${idx}]`, banner.image_url);
    });
    const selectedEventBannerImage = collectionFormData.eventBanner ? collectionFormData.eventBanner.image_url : null;
    formData.append(`eventBanner`, selectedEventBannerImage || '');
  }

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }
    setLoaderActive(true);
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;

      const requestData = {
        id: id,
        title : collectionFormData.title,
        descriptionHtml: collectionFormData.description,
        handle : collectionFormData.handle || collectionFormData.title,
        src: collectionFormData?.thumbnail,
        altText: collectionFormData?.alt_text,
        metafields: [
          collectionFormData?.metafields?.collection_filters,
          collectionFormData?.metafields?.counter_enabled,
          collectionFormData?.metafields?.sub_collection_title_and_link,
          collectionFormData?.metafields?.mid_collection_filters,
          collectionFormData?.metafields?.meta_keywords,
          collectionFormData?.metafields?.event_collection_gradient,
          collectionFormData?.metafields?.collection_description
        ],
        ...(collectionForm.collectionForm.collection_type === 'smart_collection' ? {
          ruleSet: {
            appliedDisjunctively: collectionFormData.disjunctive,
            rules: collectionFormData.rules.map((rule) => {
              if(rule.column.includes('PRODUCT_METAFIELD_DEFINITION')){
                return {
                  ...rule,
                  column: 'PRODUCT_METAFIELD_DEFINITION',
                  condition_object_id: rule.column.replace('PRODUCT_METAFIELD_DEFINITION', ''),
                }
              }
              return {...rule};
            })
          },
        }: {
          add_collection_products: collectionProducts.map((product) => product.id)
        })
      };

      const formData = new FormData();
      if (id) formData.append('id',  id);
      formData.append('requestData',  JSON.stringify(requestData));
      if (collectionFormData?.thumbnail){
        formData.append('thumbnail',  collectionFormData.thumbnail);
      }
      addBannerToFormData(formData);

      dispatch(CollectionSaveApi({ headers, formData, id }))
      .unwrap()
      .then((response) => {
        console.log('Collection saved successfully:', response);
        setRefreshData(true);
        setLoaderActive(true);
        navigate(ROUTES.EDIT_COLLECTION.replace(':id', String(response["collection_id"])));
      })
      .catch((error) => {
        setLoaderActive(false);
        console.error('Error saving collection:', error);
      });
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const fetchBanners = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(
        getCollectionBannerMetaobjects({headers})
      ).unwrap();
      await dispatch(
        getLeadGenBannerMetaobjects({headers})
      ).unwrap();
      await dispatch(
        getMediaImages({headers})
      ).unwrap();
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const fetchData = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(
        CollectionFormApi({
          headers,
          id
        })
      ).unwrap();
      setRefreshData(false);
      setLoaderActive(false);
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };
  
  useEffect(() => {
    if (action == 'edit' && refreshData){
      setLoaderActive(true);
      fetchData();
    }else{
      setLoaderActive(false);
    }
  }, [action, refreshData]);

  useEffect(() => {
    dispatch(updateCollectionProducts([]));
    fetchBanners();
  }, []);

  return (
    <Box className={styles.formWrapper}>
      <Box className={styles.collectionFormError}> {collectionForm.CollectionFormApiError} </Box>
      <CollectionFormHeader
        label={label}
        onDiscard={handleDiscard} 
        onSave={handleSave}
        stateUpdated={stateUpdated}
        action={action}
      />

      {<Loader show={loaderActive} />}

      {!loaderActive && (
        <Box sx={{ flexGrow: 1 }}>
        <Grid className={styles.layout} container spacing={2}>
        
          {/* leftSection */}
          <Grid className={styles.leftSection} xs={9}>
            <BasicDetails
              validationErrors={validationErrors}
              action={action as 'create' | 'edit'}
            />
            <CollectionType
              action={action}
            />
            <CollectionTab/>
          </Grid>

          {/* rightSection */}
          <Grid className={styles.rightSection} xs={3}>
            <CollectionThumbnail
              thumbnail={collectionFormData?.thumbnail}
              thumbnailAltTxt={collectionFormData?.alt_text}
              onChange={(image, altText) => {
                dispatch(updateThumbnail(image))
                dispatch(updateThumbnailAltText(altText))
              }}
            />

            <BannerDetails
              label={'Collection Banner'}
              allowMobileBanner
              allowWebBanner={false}
              mobileBannerImages={collectionFormData.collectionBanners}
              webBannerImages={[]}
              mobileBannerOptions={collectionForm.collectionBannerMetaobjectsData}
              webBannerOptions={[]}
              onChange={(type, images) => {
                dispatch(updateBanners({type: 'collection',  banners: images}));
              }}
              onAddBannerOptions={(type, options) => {
                dispatch(addBannerOptions({ type: 'collection',  banners: options }));
              }}
            />

            <BannerDetails
              label={'Lead Generation Banner'}
              allowMobileBanner
              allowWebBanner={false}
              mobileBannerImages={collectionFormData.leadGenerationBanners}
              webBannerImages={[]}
              mobileBannerOptions={collectionForm.leadGenBannerMetaobjectsData}
              webBannerOptions={[]}
              onChange={(type, images) => {
                dispatch(updateBanners({type: 'lead_generation',  banners: images}));
              }}
              onAddBannerOptions={(type, options) => {
                dispatch(addBannerOptions({ type: 'lead_generation',  banners: options }));
              }}
            />

            <SingleBannerDetails
              label={'Event Banner'}
              allowMobileBanner
              allowWebBanner={false}
              mobileBannerImage={collectionFormData.eventBanner}
              webBannerImage={null}
              onChange={(type, image) => {
                dispatch(updateEventBanner(image));
              }}
            />
          </Grid>
        </Grid>
      </Box>
      )}

      {/* Display title and description */}
      <Box>
            
      </Box>
    </Box>
  );
};
  
export default CollectionForm;