import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./BulkImageUpload.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import { Box } from "@mui/material";
import { DragArea, DragAreaPropType, FileItem } from "components/molecules/FileUpload/FileUpload";
import { DEFAULT_BLACK_COLOR } from "../../constants";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { bulkImageUploadRequestTypes } from "./BulkImageUpload";

type PropType = {
  requestType: string;
  handleUpload: (formData: FormData) => void;
  open: boolean;
  onClose: () => void;
  uploading?: boolean;
}

const UploadModal:React.FC<PropType> = (props) => {
  const {handleUpload, requestType, open, onClose, uploading} = props;
  const [zipFile, setZipFile] = useState<File | undefined>(undefined);
  const [csvFile, setCSVFile] = useState<File | undefined>(undefined);

  const zipUploadRef = useRef<HTMLInputElement|null>(null);
  const csvUploadRef = useRef<HTMLInputElement|null>(null);
  
  const heading = useMemo(() => {
    if(requestType === bulkImageUploadRequestTypes.bulk_product_image_upload){
      return "Product Image Upload"
    }else if(requestType === bulkImageUploadRequestTypes.bulk_product_last_image_upload){
      return "Upload Last Image"
    }
    return "Upload Size Chart"
  }, [requestType]);

  useEffect(() => {
    if(open){
      setZipFile(undefined)
      setCSVFile(undefined)
    }
  }, [open]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, type: 'csv' | 'zip') => {
    if(e.target.files?.length){
      if(type === 'csv'){
        setCSVFile(e.target.files[0]);
      }else{
        setZipFile(e.target.files[0]);
      }
    }
    e.target.value = "";
  }

  const handleSumit = () => {
    const formData = new FormData();
    formData.append('request_type' , requestType);
    if(zipFile) formData.append('zip_file' , zipFile);
    if(csvFile) formData.append('csv_file' , csvFile);
    handleUpload(formData);
  }


  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, type: 'csv' | 'zip') => {
    e.preventDefault();
    e.stopPropagation();
    const {classList} = e.target as HTMLDivElement;
    classList.add(styles.withDrag);
  }, [styles]);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>, type: 'csv' | 'zip') => {
    e.preventDefault();
    e.stopPropagation();
    const {classList} = e.target as HTMLDivElement;
    classList.remove(styles.withDrag);
  }, [styles]);

  const onDrop = (e: React.DragEvent<HTMLDivElement>, type: 'csv' | 'zip') => {
    e.preventDefault();
    e.stopPropagation();
    const {classList} = e.target as HTMLDivElement;
    classList.remove(styles.withDrag);
    if(e.dataTransfer?.files?.length){
      if(type === 'csv' && e.dataTransfer.files[0].type === 'text/csv'){
        setCSVFile(e.dataTransfer.files[0]);
      }else if (type === 'zip' && e.dataTransfer.files[0].type === 'application/zip'){
        setZipFile(e.dataTransfer.files[0]);
      }
    }
  };

  const isSubmitDisabled = useCallback(() => {
    if(requestType === bulkImageUploadRequestTypes.bulk_product_image_upload && !zipFile){
      return true;
    }
    if(requestType !== bulkImageUploadRequestTypes.bulk_product_image_upload && (!zipFile || !csvFile)){
      return true;
    }
    return false;
  }, [requestType, zipFile, csvFile]);

  return (
    <ModalBox open={open} onClose={onClose} className={styles.fileUploadModal}>
      <p className={styles.label}>{heading}</p>
      {!zipFile && (
        <InputSection 
          heading={'Upload image zip file'}
          subHeading={'Use filename 1 for first image, 0 for last image'}
          handleUpload={() => {zipUploadRef?.current?.click();}}
          onDragLeave={(e) => {onDragLeave(e, 'zip')}}
          onDragOver={(e) => {onDragOver(e, 'zip')}}
          onDrop={(e) => {onDrop(e, 'zip')}}
        />
      )}
      {!csvFile && requestType !== bulkImageUploadRequestTypes.bulk_product_image_upload && (
        <InputSection 
          heading={'Upload csv file'}
          subHeading={'Use column name: (ID, File Name)'}
          handleUpload={() => {csvUploadRef?.current?.click();}}
          onDragLeave={(e) => {onDragLeave(e, 'csv')}}
          onDragOver={(e) => {onDragOver(e, 'csv')}}
          onDrop={(e) => {onDrop(e, 'csv')}}
        />
      )}
      {(zipFile || csvFile) && (
        <Box className={styles.uploadedFiles}>
          {zipFile && <FileItem file={zipFile} onDelete={() => setZipFile(undefined)}/>}
          {csvFile && <FileItem file={csvFile} onDelete={() => setCSVFile(undefined)}/>}
        </Box>
      )}
      <Box className={styles.actionBtns}>
        <ButtonX color={DEFAULT_BLACK_COLOR} variant="outlined" size='large' onClick={onClose}>Discard</ButtonX>
        <ButtonX size='large' onClick={handleSumit} disabled={isSubmitDisabled() || uploading} loading={uploading}>
          {uploading ? 'Uploading...' : 'Upload'}
        </ButtonX>
      </Box>
      
      <input ref={zipUploadRef}
        type="file"
        onChange={(e) => {handleFileUpload(e, 'zip')}}
        style={{ display: 'none' }}
        accept={'.zip'}
      />
      <input ref={csvUploadRef}
        type="file"
        onChange={(e) => {handleFileUpload(e, 'csv')}}
        style={{ display: 'none' }}
        accept={'.csv'}
      />
    </ModalBox>
  );
}

export default UploadModal;

type InputSectionPropType = {
  heading: string;
  subHeading: string;
} & DragAreaPropType

const InputSection:React.FC<InputSectionPropType> = (props) => {
  const {heading, subHeading, onDragLeave, onDragOver, onDrop, handleUpload} = props;
  return (
    <Box className={styles.inputSection}>
      <p className={styles.heading}>{heading}</p>
      <p className={styles.subHeading}>{subHeading}</p>
      <DragArea 
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        handleUpload={handleUpload}
      />
    </Box>
  )
}