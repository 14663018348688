import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './MasterCatalogue.module.scss';
import { setSort, SetPieceMasterCatalogueApi, SetPieceMasterDataHash } from "../../slices/setPieceMasterCatalogueSlice";
import Loader from "../../components/atoms/Loader/Loader";
import { useNavigate} from "react-router-dom";

import { 
  resetFilter,
  setSetPiecesMasterCatalogueNameFilter,
  setSetPiecesMasterCatalogueCreatedAtStartDateFilter,
  setSetPiecesMasterCatalogueCreatedAtEndDateFilter,
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import { setPieceMasterCatalogueColumns } from "./SetPieceMasterCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "../../components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "../../components/molecules/SearchByFilter/SearchByFilter";

import { DateRangeType, ROUTES } from "../../constants";
import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";

const searchByOptions = [
  {id: 'value', label: 'Value'},
]

const SetPieceMasterCatalogue = () => {

  const dispatch = useAppDispatch();

  // Using theme to check the device is mobile or not
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Creating state to store loader status, filter applied and filter open
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);

  // Check whether the logged user is fbv user or not
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  
  // Get the collection data from store.
  const setPiecesMaster = useAppSelector((state: RootState) => state.setPiecesMaster);
  const setPiecesMasterCatalogueNameFilter = useAppSelector((state: RootState) => state.filters.setPiecesMasterCatalogueNameFilter);
  const setPiecesMasterCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.setPiecesMasterCatalogueCreatedAtStartDateFilter);
  const setPiecesMasterCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.setPiecesMasterCatalogueCreatedAtEndDateFilter);

  const handleSort = (column: keyof SetPieceMasterDataHash) => {
    const direction = column === setPiecesMaster.sortedColumn && setPiecesMaster.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };


  const fetchData = async () => {
    try {
      await dispatch(SetPieceMasterCatalogueApi({
        setPiecesMasterCatalogueNameFilter,
        setPiecesMasterCatalogueCreatedAtStartDateFilter,
        setPiecesMasterCatalogueCreatedAtEndDateFilter,
        page,
        rowsPerPage
      })).unwrap();
    }catch(e){
      console.error(e);
    }finally{
      setFilterApplied(false);
      setLoaderActive(false);
    }
  };

  useEffect(() => {
    if(filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setFilterApplied(true);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!setPiecesMasterCatalogueNameFilter
      ? [
        {
          id: "value",
          label: "Value",
          value: setPiecesMasterCatalogueNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
  ];


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'value': {
        dispatch(setSetPiecesMasterCatalogueNameFilter(value as string)); break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setSetPiecesMasterCatalogueNameFilter(''));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setSetPiecesMasterCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setSetPiecesMasterCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Set & Piece Master">   
        <button
          className={styles.primaryBtn}
          onClick={() => {
            window.location.href = '/masters/set_pieces/new';
          }}
        >
          Create Set & Pieces
        </button>
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <DateRangeLabel
            noDateLabel={'Created At'}
            startDate={setPiecesMasterCatalogueCreatedAtStartDateFilter}
            endDate={setPiecesMasterCatalogueCreatedAtEndDateFilter}
            onClick={() => {setShowDateRangeFilter(true)}}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />


      {!loaderActive && (
        <TableManager<SetPieceMasterDataHash>
          data={setPiecesMaster?.setPiecesMasterCatalogueData?.set_pieces || []}
          columns={setPieceMasterCatalogueColumns()}
          sortedColumn={setPiecesMaster.sortedColumn}
          handleSort={handleSort}
          sortDirection={setPiecesMaster.sortDirection}
          showPagination
          totalCount={setPiecesMaster?.setPiecesMasterCatalogueData?.total_count || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={setPiecesMasterCatalogueCreatedAtStartDateFilter}
        endDate={setPiecesMasterCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default SetPieceMasterCatalogue;
