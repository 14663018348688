import { Box, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import styles from "./TreeDropDownX.module.scss";
import { useState } from "react";
import { csx } from "helpers/utils";

export type TreeDropDownPropType = {
  children: JSX.Element | React.ReactNode;
  id: string;
  direction?: 'right' | 'bottom' | 'top' | 'left' | 'bottomRight';
  options?: Omit<TreeDropDownPropType, 'onClick'>[]
  onClick: (optionId: string) => void;
}

const TreeDropDownX:React.FC<TreeDropDownPropType> = (props) => {
  const {children, id, direction = 'right', options = [], onClick} = props;
  const [showContent, setShowContent] = useState(false);

  const handleOptionClick = (e: React.MouseEvent<HTMLElement>, optionId: string) => {
    e.stopPropagation();
    onClick(optionId);
    setShowContent(false);
  }
  
  return (
    <Box className={styles.treeDropDown}
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      onClick={(e) => handleOptionClick(e, id)}
    >
      {children}
      {!!options?.length && (
        <List className={csx(styles.list , styles[direction])} style={!showContent ? {display: 'none'} : {}}>
          {options.map((option) => {
            const {children, ...restProps} = option
            return (
              <TreeDropDownX key={option.id} {...restProps} onClick={(optionId) => onClick(optionId)}>
                <ListItem className={styles.listItem} onClick={(e) => handleOptionClick(e, option.id)}>
                  <Box className={styles.listItemText}>{option.children}</Box>
                </ListItem>
              </TreeDropDownX>
            )
          })}
        </List>
      )}
    </Box>
  )
}

export default TreeDropDownX;