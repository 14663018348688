import { ProductType } from "slices/productSearchSlice";
import styles from "./SmartCollectionProductList.module.scss";
import { Box } from "@mui/material";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useCallback, useEffect, useState } from "react";
import Img from "components/atoms/Img/Img";
import { resetAuth } from "slices/authSlice";
import {fetchCollectionProductsApi, syncCollectionProductsApi } from "slices/collectionProductsSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import Loader from "components/atoms/Loader/Loader";
import LoaderX from "components/atoms/LoaderX/LoaderX";
import SyncStatus from "components/atoms/SyncStatus/SyncStatus";

type ProductListPropType = {
  collectionId: string;
}

type ProductItemPropType = ProductType;

const ProductItem:React.FC<ProductItemPropType> = (props) => {
  const {id, image, title} = props;

  return (
    <Box key={id} className={styles.productItem}>
      <Img src={image || undefined} alt={title} className={styles.productImg} />
      <p className={styles.productTitle}>{title}</p>
    </Box>
  );
}


const SmartCollectionProductList:React.FC<ProductListPropType> = (props) => {
  const dispatch = useAppDispatch();
  const {collectionId} = props;
  const [changesApplied, setChangesApplied] = useState<boolean>(true);
  const [loaderActive, setLoaderActive] = useState<boolean>(false);
  const rows = 20;

  const collectionProducts = useAppSelector((state: RootState) => state.collectionProducts.productsData?.data) || [];
  const collectionProductsCount = useAppSelector((state: RootState) => state.collectionProducts.productsData?.totalCount) || 0;
  const collectionProductsData = useAppSelector((state: RootState) => state.collectionProducts.productsData);
  const collectionSyncStatus = useAppSelector((state: RootState) => state.collectionForm?.collectionForm?.shopify_sync_status);


  const fetchData = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      try {
        await dispatch(fetchCollectionProductsApi({rows: rows, collectionId, headers})).unwrap();
      } finally {
        setChangesApplied(false);
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
    }
  }

  const syncProducts = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      setLoaderActive(true);
      try {
        await dispatch(syncCollectionProductsApi({collectionId, headers})).unwrap();
      } catch(e){
        console.error(e);
      }finally {
        setLoaderActive(false);
        setChangesApplied(true);
      }
    } else {
      dispatch(resetAuth());
    }
  }


  useEffect(() => {
    if(changesApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [changesApplied]);

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <p className={styles.heading}>Products in this collection</p>
        <p className={styles.productsCount}>{`${collectionProductsCount} Products`}</p>
        <SyncStatus
          className={styles.syncStatus}
          syncStatus={collectionProductsData?.shopify_products_sync_status || null}
          syncError={collectionProductsData?.shopify_products_sync_error || null}
          lastSyncedAt={collectionProductsData?.last_shopify_products_synced_at || null}
        />
        <ButtonX 
          size="small"
          className={styles.actionBtn} 
          onClick={() => syncProducts()}
          disabled={(collectionSyncStatus !== 'synced') || (collectionProductsData?.shopify_products_sync_status === 'pending')}
        >
          Sync Products
        </ButtonX>
      </Box>
      <LoaderX show={loaderActive} />
      {!loaderActive && (
        <>
          <Box className={styles.productList}>
            {collectionProducts.map((product) => (
              <ProductItem
                {...product}
              />
            ))}
          </Box>
          {collectionProductsCount > collectionProducts.length && (
            <p className={styles.extraTxt}>{`+ ${collectionProductsCount - collectionProducts.length} Products`}</p>
          )}
        </>
      )}
    </Box>
  )
}

export default SmartCollectionProductList;