import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Button, { ButtonProps } from '@mui/material/Button';
import { csx } from "helpers/utils";
import styles from "./ButtonX.module.scss";
import { useMemo } from "react";
import { CircularProgress } from "@mui/material";

const DEFAULT_BUTTON_COLOR = '#3361FF';


type ButtonXProps = Omit<ButtonProps, 'color'> & {
  color?: string;
  loading?: boolean
}

const customTheme = (color: string) => createTheme({
  palette: {
    primary: {
      main: color,
    }
  },
});

const ButtonX:React.FC<ButtonXProps> = (props) => {
  const {color = DEFAULT_BUTTON_COLOR, className, variant = 'contained', size = 'medium', loading, children, ...rest} = props;
  const theme = useMemo(() => customTheme(color), [color]);

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={csx(className, styles.buttonX)}
        color="primary"
        variant={variant}
        size={size}
        {...rest}
      >
        {children} {loading && <CircularProgress color="inherit" className={csx(styles.loader, styles[size])} />}
      </Button>
    </ThemeProvider>
  );
}

export default ButtonX;