import styles from "./EditProductVariant.module.scss";
import { Box } from "@mui/material";
import { ProductVariantFormData } from "slices/productFormSlice";
import VariantNameEdit from "./VariantNameEdit";
import VariantImageEdit from "./VariantImageEdit";
import VariantPriceEdit from "./VariantPriceEdit";
import VariantInventoryEdit from "./VariantInventoryEdit";
import VariantMetafieldEdit from "./VariantMetafieldEdit";

type VariantDetailsProps = {
  variantDetail: ProductVariantFormData,
  onChange: (field: keyof ProductVariantFormData, value: any) => void;
};

const VariantDetails: React.FC<VariantDetailsProps> = ({ variantDetail, onChange }) => {
  return (
    <Box className={styles.variantDetails}>
      <Box className={styles.form}>
        <VariantNameEdit
          variantName={variantDetail.variantName}
          onVariantNameChange={onChange}
        />
        <VariantPriceEdit
          pricingProps={variantDetail.variantPricing}
          onVariantPricingChange={onChange}
        />
        <VariantInventoryEdit
          inventoryProps={variantDetail.variantInventory}
          onInventoryChange={onChange}
        />
        <VariantMetafieldEdit
          metafieldProps={variantDetail.metafields}
          onMetafieldChange={onChange}
        />
      </Box>
    </Box>
  );
}

export default VariantDetails;