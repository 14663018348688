import { Paper } from "@mui/material";
import styles from "./Loader.module.scss";
import LoaderImg from '../../../images/loader.svg';

type LoaderPropType = {
  show: Boolean;
}

const Loader = (props: LoaderPropType) => {
  const {show} = props;
  return <>
    {show ? (
      <Paper className={styles.loaderPaper} data-testid='loader'>
        <img src={LoaderImg} alt="loader" />
      </Paper>
    ): null}
  </>
}

export default Loader;