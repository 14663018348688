import { Box, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import styles from "./Header.module.scss";
import SolomonDesktopIcon from "../../../images/solomon_desktop.png";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import LogoutModal from "../LogoutModal/LogoutModal";

import { useState } from "react";
import LightTooltip from "../LightTooltip/LightTooltip";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../../slices/authSlice";
import Navbar from "components/molecules/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "./../../../constants";
import { csx } from "helpers/utils";

const Header = () => {

  const theme = useTheme();
  const dispatch = useDispatch();

  const [showUserOp, setShowUserOp] = useState<boolean>(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    dispatch(resetAuth());
  }

  const navigate = useNavigate();
  const user_logo = useAppSelector((state) => state.auth.basicUserInfo?.logo_url) || null;

  return (
    <>
      <Box className={styles.wrapper} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', backgroundColor: '#fff', borderBottom: '1px solid #e5e5e5' }}>
        {/* Brand Info */}
        <div
          className={styles.brandInfo}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          <img className={styles.forgeIcon} src={SolomonDesktopIcon} alt="Solomon Icon" />
        </div>

        {/* Navbar */}
        <div className={styles.navContainer} style={{ flexGrow: 1 }}>
          <Navbar />
        </div>

        {/* User Info and Status */}
        <div className={styles.userInfo} style={{ display: 'flex', alignItems: 'center' }}>
          {user_logo && <img src={user_logo} className={styles.supplierLogo} alt={'Supplier Logo'} style={{ marginRight: '10px', borderRadius: '50%', width: '30px', height: '30px' }} />}
          <ClickAwayListener onClickAway={() => setShowUserOp(false)}>
            <div>
              <LightTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                placement="bottom-end"
                onClose={() => { setShowUserOp(false); }}
                open={showUserOp}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                <>
                <p className={styles.actionButton} onClick={() => {
                      navigate(ROUTES.REQUESTS);
                      setShowUserOp(false);
                    }}>Requests</p>
                  <p className={csx(styles.actionButton, styles.red)} onClick={() => {
                    setShowLogoutPopup(true);
                    setShowUserOp(false);
                  }}>Logout</p>
                </>
                }
              >
                <div className={styles.userIcon} onClick={() => setShowUserOp((prevVal) => !prevVal)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  {showUserOp ? <ExpandLessOutlinedIcon fontSize="small" /> : <ExpandMoreOutlinedIcon fontSize="small" />}
                </div>
              </LightTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </Box>
      <LogoutModal
        open={showLogoutPopup}
        onClose={() => setShowLogoutPopup(false)}
        onLogout={handleLogout}
      />
    </>
  )
}

export default Header;